import React, { useEffect } from 'react';
import BlockingLoader from 'app/blocks/BlockingLoader';
import { ID, l, withCodes2 } from 'app/blocks/common/codes';
import PageEnum from 'app/blocks/common/PageEnum';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import StatusPopup, { StatusPopupTypeEnum } from 'app/blocks/StatusPopup/StatusPopup';
import { MainTitle } from 'app/blocks/titles/titles';
import { BackButton, ForwardButton } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import { isWaiver } from 'app/pages/license-signing-process/context/utils';
import BillingDetails from 'app/pages/license-signing-process/DiscountAndBillingDetails/GOA/BillingDetails';
import DiscountAndBillingContext from 'app/pages/license-signing-process/DiscountAndBillingDetails/GOA/Context';
import ArticlePublicationCharge from './ArticlePublicationCharge/ArticlePublicationCharge';
import Discounts from './Discounts';
import VatTax from './VatTax';

type DiscountAndBillingDetailsProps = {
    order: any;
    onInitiateConfirm: () => void;
    isConfirming: boolean;
    isSaveAndPreviewAllowed: boolean;
    onCloseStatusPopup: () => void;
    statusPopupError: {
        message: string;
    };
};

const getMainTitle = (order: any) => {
    return isWaiver(order)
        ? l('LICENSE_SIGNING.STEP.DISCOUNT_AND_WAIVER')
        : l('LICENSE_SIGNING.STEP.DISCOUNT_AND_BILLING');
};

const InitiateStep: React.FC<DiscountAndBillingDetailsProps> = props => {
    const { isConfirming, isSaveAndPreviewAllowed, onCloseStatusPopup, onInitiateConfirm, order, statusPopupError } =
        props;
    const { getPrevStep, goBack, isUpdatingInfo, onLoadSubstep } = useLicenseFlow();

    useEffect(() => {
        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            onLoadSubstep(null, PageEnum.GOA_ORDER_INITIATE);
        }
    }, [onLoadSubstep]);
    const errorRef = React.useRef(null);
    return (
        <>
            {isUpdatingInfo && <BlockingLoader />}
            {statusPopupError && (
                <StatusPopup
                    ref={errorRef}
                    onCloseStatusPopup={onCloseStatusPopup}
                    seleniumid="paymentInitiateError"
                    type={StatusPopupTypeEnum.ERROR}
                >
                    {statusPopupError.message}
                </StatusPopup>
            )}
            <MainTitle>{getMainTitle(order)}</MainTitle>

            <section className="payment">
                <ArticlePublicationCharge />
            </section>

            <Discounts />

            <BillingDetails />

            <VatTax />

            <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--payment">
                <div className="page-controls">
                    <BackButton onClick={goBack}>{getPrevStep().name}</BackButton>

                    <ForwardButton
                        data-seleniumid="saveAndPreviewButton"
                        disabled={!isSaveAndPreviewAllowed}
                        isProcessing={isConfirming}
                        onClick={onInitiateConfirm}
                        processingLabel={l('BUTTONS.CONFIRMING')}
                    >
                        {l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                    </ForwardButton>
                </div>
            </div>
        </>
    );
};

export default compose(
    withScrollToTop,
    DiscountAndBillingContext.withContext(state => {
        return {
            isConfirming: state.initiate?.isConfirming,
            isSaveAndPreviewAllowed: state.initiate?.isSaveAndPreviewAllowed,
            onCloseStatusPopup: state.closeStatusPopup,
            onInitiateConfirm: state.onInitiateConfirm,
            order: state.order,
            statusPopupError: state.initiate?.statusPopupError,
        };
    }),
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(InitiateStep);
