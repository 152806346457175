import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { ArticlePayload, Citation } from 'app/blocks/middleware/dashboard';
import { ActionButton } from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';

type ContextInterface = ArticlePayload & {
    id: string;
    errors: string[];
    banner: Record<string, any>;

    helpers: {
        getActions: (section: string) => Record<string, ActionButton>;
        getAllActions: (
            section: string,
            labels?: Record<string, string>,
            additionalInfo?: { article: {}; journal: {} },
        ) => ActionButton[];
        getAllRequiredActions: () => Record<string, { action: string; status: string }>;
        getPanel: (section: string) => Object;
        getParameters: (section: string) => Record<string, any>;
        getStatus: (section: string) => string;
        getTitle: (section: string, defaultTitle?: string) => string;
        hasMultipleAuthors: () => boolean;
        getIcon: (section: string, defaultIcon?: string) => keyof typeof SvgIcon;

        isEditable: () => boolean;

        hasAction: (section: string, action: string, onlyRequired?: boolean) => boolean;
        hasCitations: () => boolean;
        hasOption: (option: string) => boolean;
        hasPanel: (section: string) => boolean;
        hasRequired: (section: string) => boolean;
        hasImportant: (section: string) => boolean;
    };
};

const defaultValue = {
    article: {},
    flow: {},
    journal: {},
    options: {},
    publication: {},
    status: {},
};

// @ts-ignore
const Context = React.createContext<ContextInterface>(defaultValue);

const useArticlePage = () => React.useContext(Context);

export { ArticlePayload, ContextInterface, Citation, useArticlePage };
export default Context;
