import React from 'react';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

import './AdvanceSearchButtons.scss';

function AdvanceSearchButtons(props) {
    const {
        disableOnNext,
        disableOnPrevious,
        hideNext,
        hidePrevious,
        nextButtonText,
        onNext,
        onPrevious,
        previousBtnText,
    } = props;
    return (
        <>
            <div className="clearfix" />
            <hr className="AdvanceSearchButtonsHr" />
            {!hidePrevious && (
                <div className="pull-left">
                    <Button
                        className="btn-white"
                        data-seleniumid="no-results-back-article-search"
                        disabled={disableOnPrevious}
                        onClick={onPrevious}
                    >
                        <SvgIcon.previous iconTitle="Back Icon" style={{ marginLeft: '-4px', marginRight: '8px' }} />
                        {previousBtnText}
                    </Button>
                </div>
            )}
            {!hideNext && (
                <div className="pull-right">
                    <PrimaryButton data-seleniumid="advance-search-next" disabled={disableOnNext} onClick={onNext}>
                        {nextButtonText}
                        <SvgIcon.next iconTitle="Back Icon" style={{ marginLeft: '8px' }} />
                    </PrimaryButton>
                </div>
            )}
        </>
    );
}

export default AdvanceSearchButtons;
