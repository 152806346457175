import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import { ArticlePayload } from 'app/blocks/middleware/dashboard';

import './DashboardEligibilityMessage.scss';

type Props = {
    eligibility?: ArticlePayload['eligibility'];
    isTransparent?: boolean;
    l: l;
};

function DashboardEligibilityMessage({ eligibility, isTransparent = false, l }: Props): React.ReactElement {
    return (
        !!eligibility?.message && (
            <EligibilityMessage
                className="DashboardEligibilityMessage"
                isGray={!isTransparent}
                message={
                    <>
                        <div
                            dangerouslySetInnerHTML={{ __html: eligibility.message }}
                            data-seleniumid="eligibility-message-text"
                        />
                        {!!eligibility.otherFunders && !!eligibility.otherFunders.length && (
                            <div className="EligibilityRow" data-seleniumid="eligibility-otherFunders">
                                <div data-seleniumid="eligibility-otherFunders-text">
                                    {l('DASHBOARD.ELIGIBILITY.OTHER_FUNDERS')}
                                </div>
                                <ul data-seleniumid="eligibility-otherFunders-list">
                                    {eligibility.otherFunders.map(i => (
                                        <li key={i}>{i}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                }
                title={
                    eligibility.isInstitutionalDiscount
                        ? l('DASHBOARD.DISCOUNT.TITLE')
                        : l('DASHBOARD.ELIGIBILITY.TITLE')
                }
                withHeader
            />
        )
    );
}

export default withCodes(DashboardEligibilityMessage, ID.DASHBOARD);
