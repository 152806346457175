import React, { useCallback } from 'react';
import ComboboxWithChevron, { Props as ComboboxProps } from 'app/blocks/ComboboxWithChevron/ComboboxWithChevron';
import { ID, withCodes } from 'app/blocks/common/codes';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import FunderPickerListItem from 'app/blocks/funder-picker/__list-item/funder-picker__list-item';

type T = ComboboxProps<Funder>;

type Props = {
    disabled: T['disabled'];
    error?: string;
    funder: T['selectedItem'];
    fundersPromise: (params: { q?: string; size?: number; offset?: number }) => Promise<Funder[]>;
    l: l;
    onFocus: T['onFocus'];
    onFunderChange: T['changeHandler'];
    seleniumid: T['seleniumid'];
};

function FunderPicker({
    disabled,
    error,
    funder,
    fundersPromise,
    l,
    onFocus,
    onFunderChange,
    seleniumid = 'funder',
}: Props): React.ReactElement {
    const getFilteredFundersPromise = useCallback<T['getListDataPromise']>(
        (search, size, offset) => fundersPromise({ offset, q: search, size }),
        [fundersPromise],
    );

    const renderFunderItem = useCallback<T['renderListItem']>(
        (item, index) => <FunderPickerListItem key={index} item={item} />,
        [],
    );

    return (
        <div>
            <ComboboxWithChevron
                changeHandler={onFunderChange}
                customFeatureName="funder"
                disabled={disabled}
                displayUseCustomFeature
                getListDataPromise={getFilteredFundersPromise}
                hideNoneValue
                isError={!!error}
                onFocus={onFocus}
                placeholder={l('FUNDER.FUNDER_PICKER_PLACEHOLDER')}
                renderListItem={renderFunderItem}
                selectedItem={disabled ? null : funder}
                seleniumid={seleniumid}
            />
            {error && <ErrorLabel text={error} />}
        </div>
    );
}

export default withCodes(FunderPicker, ID.FUNDER);
