import cn from 'classnames';
import { autobind } from 'core-decorators';
import get from 'lodash.get';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import { Props as ComboboxProps } from 'app/ui/form/Combobox';
import Select from 'app/ui/form/Select';
import './MobileCountryPicker.scss';

type Props = { l: l; name?: string } & Pick<
    ComboboxProps<Country>,
    | 'changeHandler'
    | 'className'
    | 'disabled'
    | 'getListDataPromise'
    | 'hideNoneValue'
    | 'isError'
    | 'onFocus'
    | 'selectedItem'
    | 'seleniumid'
>;

type State = {
    countries: Country[];
    isLoading: boolean;
};

@autobind
class MobileCountryPicker extends React.PureComponent<Props, State> {
    state: State = {
        countries: [],
        isLoading: true,
    };

    @tryCatch
    @processing('isLoading')
    async componentDidMount() {
        // @ts-ignore
        this.setState({ countries: await this.props.getListDataPromise() });
    }

    onChange = e => {
        const country = this.state.countries.find(x => x.id === e.target.value);

        this.props.changeHandler(country);
    };

    render() {
        const { className, disabled, hideNoneValue, isError, l, name, onFocus, selectedItem, seleniumid } = this.props;
        const { countries, isLoading } = this.state;

        return (
            <Select
                className={cn('mobileCountryPicker', className, {
                    'mobileCountryPicker--noCountrySelected': !get(selectedItem, 'id'),
                })}
                data-seleniumid={seleniumid}
                disabled={disabled}
                isError={isError}
                name={name}
                onChange={this.onChange}
                onFocus={onFocus}
                value={get(selectedItem, 'id', '')}
            >
                <option className="mobileCountryPicker-option mobileCountryPicker-option--placeholder">
                    {isLoading
                        ? l('BUTTONS.LOADING')
                        : l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_COUNTRY_PLACEHOLDER_MOBILE')}
                </option>
                {!hideNoneValue && (
                    <option className="mobileCountryPicker-option mobileCountryPicker-option--noneValue">
                        {l('BUTTONS.NONE')}
                    </option>
                )}
                {countries.map(x => (
                    <option key={x.id} className="mobileCountryPicker-option" value={x.id}>
                        {x.name}
                    </option>
                ))}
            </Select>
        );
    }
}

export default withCodes(MobileCountryPicker, ID.BUTTONS, ID.PROFILE_ACCOUNT);
