import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { ID, lAsync, withCodes2 } from 'app/blocks/common/codes';
import { markAsChanged, markAsUnchanged, processing, tryCatch } from 'app/blocks/common/decorators';
import Alerts from 'app/blocks/common/spinner';
import { compose, template } from 'app/blocks/common/utils';
import withAuth from 'app/blocks/common/withAuth';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import * as FundersMiddleware from 'app/blocks/middleware/article-funders';
import * as middlewareLicense from 'app/blocks/middleware/license';
import * as middleware from 'app/blocks/middleware/middleware';
import { confirm, confirmType } from 'app/blocks/Modal/utils';
import { validateResearchFunder } from 'app/blocks/Panel/funder/panel_funder.validation';
import { StatusPopupTypeEnum } from 'app/blocks/StatusPopup/StatusPopup';
import LicenseDialogChangeFunders from 'app/pages/license/__dialog/_change-funders/license__dialog_change-funders';
import LicenseSigningProcessContext from 'app/pages/license-signing-process/context/Context';
import withContext from 'app/pages/license-signing-process/context/withContext';

const Context = React.createContext({});

function enrichResearchProgramsWithId(researchPrograms) {
    researchPrograms.forEach(program => {
        // eslint-disable-next-line no-param-reassign
        program._id = _.uniqueId();
    });

    return researchPrograms;
}

async function checkResearchFunderDeletionEligibility(researchFunder, article) {
    if (researchFunder.custom) {
        return true;
    }

    try {
        Alerts.renderSpinner();

        await middlewareLicense.checkResearchFunderDeletionEligibility(article.id, researchFunder.id);

        return true;
    } catch (error) {
        if (error.code === 'LICENSE_SIGNING_REMOVING_RESTRICTED_RESEARCH_FUNDER') {
            Alerts.removeSpinner();
            return await confirmType(LicenseDialogChangeFunders, { funderName: researchFunder.name });
        }

        throw error;
    } finally {
        Alerts.removeSpinner();
    }
}

async function checkForLicenseReset(researchFunder, article, licenseSigned) {
    if (licenseSigned && !researchFunder.custom) {
        try {
            Alerts.renderSpinner();

            const { resetLicense } = await FundersMiddleware.checkForLicenseReset(article.id, researchFunder.id);

            if (resetLicense) {
                Alerts.removeSpinner();
                return await confirm(
                    template(await lAsync('LICENSE_SIGNING.FUNDERS.FUNDERS.PROMPT.CONFIRM_FUNDER_WILL_RESET_LICENSE'), {
                        funderName: researchFunder.name,
                    }),
                );
            }

            return true;
        } finally {
            Alerts.removeSpinner();
        }
    }

    return true;
}

function getResearchProgramTemplate() {
    return {
        _id: _.uniqueId(),
        grants: [
            {
                authors: [],
                grantNumber: '',
            },
        ],
        researchFunder: undefined,
    };
}

function getSanitizedResearchPrograms(data) {
    return (
        Object.values(data)
            .map(x => x.program)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(({ _id, grants, ...others }) => ({
                ...others,
                grants: grants.filter(x => !!x.grantNumber),
            }))
    );
}

function withProvider(WrappedComponent) {
    @autobind
    class ContextWrapper extends React.Component {
        static propTypes = {
            article: PropTypes.shape({
                authors: PropTypes.arrayOf(
                    PropTypes.shape({
                        email: PropTypes.string,
                        firstName: PropTypes.string,
                        lastName: PropTypes.string,
                        role: PropTypes.shape({}),
                    }),
                ),
                id: PropTypes.string,
            }).isRequired,
            confirmResearchFunders: PropTypes.func.isRequired,
            latestChangeByUser: PropTypes.bool.isRequired,
            licenseSigned: PropTypes.bool.isRequired,
            researchPrograms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
            setChangesChecker: PropTypes.func.isRequired,
        };

        state = {
            countries: undefined,
            data: {},
            isLoading: true,
            noResearchPrograms: false,
            participantId: undefined,
            researchPrograms: [],
            validationResults: undefined,
        };

        async setStateAsync(patch) {
            await new Promise(resolve => this.setState(patch, resolve));
        }

        componentDidMount() {
            this.fetchData();
        }

        componentDidUpdate(prevProps) {
            if (this.props.article !== prevProps.article) {
                this.fetchData();
            }
        }

        @processing('isLoading')
        @tryCatch.showPopUpAndGoToDashboard
        async fetchData() {
            const { content } = await FundersMiddleware.getCountriesForArticle(this.props.article.id);

            await this.setStateAsync({
                countries: content.join(','),
                noResearchPrograms: this.props.latestChangeByUser && this.props.researchPrograms.length === 0,
                participantId: this.props.user?.participantId,
                researchPrograms: enrichResearchProgramsWithId(this.props.researchPrograms),
            });

            const data = {};

            this.props.researchPrograms.forEach(x => {
                data[x._id] = {
                    copy: x,
                    isEditing: false,
                    isNew: false,
                    noGrants: this.props.latestChangeByUser && x.grants.length === 0,
                    original: x,
                    program: x,
                    validation: {},
                };
            });

            await this.setStateAsync({ data });

            this.props.setChangesChecker(() => this.isChanged);
        }

        @markAsUnchanged
        async confirmConfirm() {
            await this.props.confirmResearchFunders(getSanitizedResearchPrograms(this.state.data));
        }

        @markAsChanged
        onResearchFunderInputFocus(_id) {
            this.setState(state => ({ data: update(state.data, { [_id]: { validation: { $set: {} } } }) }));
        }

        @markAsChanged
        async toggleNoFunding() {
            await this.setStateAsync({ noResearchPrograms: !this.state.noResearchPrograms });
        }

        @markAsChanged
        toggleNoGrants(_id) {
            const newNoGrants = !this.state.data[_id].noGrants;

            if (newNoGrants) {
                this.setState(state => ({
                    data: update(state.data, {
                        [_id]: {
                            noGrants: { $set: !state.data[_id].noGrants },
                            program: { grants: { $set: [] } },
                        },
                    }),
                }));
            } else {
                this.setState(state => ({
                    data: update(state.data, {
                        [_id]: {
                            noGrants: { $set: !state.data[_id].noGrants },
                        },
                    }),
                }));
            }
        }

        @markAsChanged
        removeGrant(_id, grantIndex) {
            this.setState(state => ({
                data: update(state.data, { [_id]: { program: { grants: { $splice: [[grantIndex, 1]] } } } }),
            }));
        }

        @markAsChanged
        async removeResearchFunder(_id) {
            const { program } = this.state.data[_id];

            if (
                !program.researchFunder ||
                (await checkResearchFunderDeletionEligibility(program.researchFunder, this.props.article))
            ) {
                delete this.state.data[_id];

                this.setState(
                    state => ({ data: { ...state.data } }),
                    () => {
                        this.showPopup(
                            StatusPopupTypeEnum.WARN,
                            this.props.l('LICENSE_SIGNING.FUNDERS.POPUP_DELETE_FUNDER', {
                                name: program.researchFunder.name,
                            }),
                        );
                    },
                );
            }
        }

        @markAsChanged
        async addGrant(_id) {
            await this.setStateAsync({
                data: update(this.state.data, {
                    [_id]: { program: { grants: { $push: [{ authors: [], grantNumber: '' }] } } },
                }),
            });
        }

        @markAsChanged
        addResearchFunder() {
            const program = getResearchProgramTemplate();

            this.setState(state => ({
                data: {
                    ...state.data,
                    [program._id]: {
                        copy: program,
                        isEditing: true,
                        isNew: true,
                        noGrants: false,
                        original: program,
                        program,
                        validation: {},
                    },
                },
            }));
        }

        @markAsChanged
        async updateGrantNumber(_id, grantIndex, grantNumber) {
            if (grantNumber) {
                await this.setStateAsync({
                    data: update(this.state.data, {
                        [_id]: { program: { grants: { [grantIndex]: { grantNumber: { $set: grantNumber } } } } },
                    }),
                });
            } else {
                await this.setStateAsync({
                    data: update(this.state.data, {
                        [_id]: {
                            program: {
                                grants: {
                                    [grantIndex]: {
                                        authors: { $set: [] },
                                        grantNumber: { $set: grantNumber },
                                    },
                                },
                            },
                        },
                    }),
                });
            }
        }

        @markAsChanged
        async updateRecipients(_id, grantIndex, recipients) {
            const authors = recipients.map(participantId =>
                // fixme - AS-22227 - .authors from getArticleAndJournal
                this.props.article.authors.find(author => author.participantId === participantId),
            );

            await this.setStateAsync({
                data: update(this.state.data, {
                    [_id]: { program: { grants: { [grantIndex]: { authors: { $set: authors } } } } },
                }),
            });
        }

        @markAsChanged
        async updateResearchFunder(_id, researchFunder) {
            const { article, licenseSigned } = this.props;
            const datum = this.state.data[_id];

            const errors = await validateResearchFunder(
                researchFunder,
                Object.keys(this.state.data)
                    .filter(x => x !== _id)
                    .map(x => this.state.data[x].program.researchFunder)
                    .filter(x => !!x),
            );

            if (errors.length > 0) {
                await this.setStateAsync({
                    data: update(this.state.data, {
                        [_id]: { validation: { $set: errors[0] } },
                    }),
                });

                throw Object.assign(new Error('Update is skipped'), { code: 'UPDATE_SKIPPED' });
            }

            if (
                datum.original.researchFunder &&
                !datum.original.researchFunder.custom &&
                datum.original.researchFunder.id !== researchFunder.id &&
                !(await checkResearchFunderDeletionEligibility(datum.original.researchFunder, article))
            ) {
                throw Object.assign(new Error('Update is skipped'), { code: 'UPDATE_SKIPPED' });
            }

            if (!(await checkForLicenseReset(researchFunder, article, licenseSigned))) {
                throw Object.assign(new Error("License's reset is canceled"), { code: 'UPDATE_CANCELED' });
            }

            await this.setStateAsync({
                data: update(this.state.data, {
                    [_id]: { program: { researchFunder: { $set: researchFunder } } },
                }),
            });
        }

        async cancelUpdate(_id) {
            if (this.state.data[_id].isNew) {
                delete this.state.data[_id];
                await this.setStateAsync({ data: { ...this.state.data } });
            } else {
                await this.setStateAsync({
                    data: update(this.state.data, {
                        [_id]: {
                            isEditing: { $set: false },
                            program: { $set: this.state.data[_id].original },
                        },
                    }),
                });
            }
        }

        async confirmUpdate(_id) {
            const oldResearchFunder = this.state.data[_id].original.researchFunder;
            const newResearchFunder = this.state.data[_id].program.researchFunder;

            await this.setStateAsync({
                data: {
                    ...this.state.data,
                    [_id]: {
                        copy: this.state.data[_id].program,
                        isEditing: false,
                        isNew: false,
                        original: this.state.data[_id].program,
                        program: this.state.data[_id].program,
                        validation: {},
                    },
                },
            });

            if (oldResearchFunder) {
                this.showPopup(
                    StatusPopupTypeEnum.INFO,
                    this.props.l('LICENSE_SIGNING.FUNDERS.POPUP_UPDATE_FUNDER', {
                        from: oldResearchFunder.name,
                        name: newResearchFunder.name,
                    }),
                );
            }
        }

        async editResearchFunder(_id) {
            await this.setStateAsync({ data: update(this.state.data, { [_id]: { isEditing: { $set: true } } }) });
        }

        fundersPromise(params) {
            const { countries, participantId } = this.state;
            const newParams = { ...params };

            if (participantId) {
                newParams.authorId = participantId;
            }

            if (countries) {
                newParams.countryCode = countries;
            }

            return middleware.funders(newParams);
        }

        showPopup(type, text) {
            this.setState({ statusPopup: { text, type } });

            clearTimeout(this.popupTimer);
            this.popupTimer = setTimeout(() => this.onCloseStatusPopup(), 10000);
        }

        onCloseStatusPopup() {
            clearTimeout(this.popupTimer);
            this.setState({ statusPopup: undefined });
        }

        render() {
            return (
                <Context.Provider
                    value={{
                        ...this.state,
                        addGrant: this.addGrant,
                        addResearchFunder: this.addResearchFunder,
                        cancelUpdate: this.cancelUpdate,
                        confirmConfirm: this.confirmConfirm,
                        confirmUpdate: this.confirmUpdate,
                        editResearchFunder: this.editResearchFunder,
                        fundersPromise: this.fundersPromise,
                        onCloseStatusPopup: this.onCloseStatusPopup,
                        onResearchFunderInputFocus: this.onResearchFunderInputFocus,
                        removeGrant: this.removeGrant,
                        removeResearchFunder: this.removeResearchFunder,
                        toggleNoFundingFlag: this.toggleNoFunding,
                        toggleNoGrants: this.toggleNoGrants,
                        updateGrantNumber: this.updateGrantNumber,
                        updateRecipients: this.updateRecipients,
                        updateResearchFunder: this.updateResearchFunder,
                    }}
                >
                    <WrappedComponent {...this.props} />
                </Context.Provider>
            );
        }
    }

    return ContextWrapper;
}

export { checkForLicenseReset, withProvider };
export default {
    withContext: withContext(Context),
    withProvider: Component =>
        compose(
            LicenseSigningProcessContext.withContext(state => ({
                confirmResearchFunders: state.confirmResearchFunders,
                latestChangeByUser: state.all.researchFunders.latestChangeByUser,
                licenseSigned: !!state.all.researchFunders.licenseSigned,
                researchPrograms: state.all.researchFunders.items,
            })),
            withConfirmLeaving,
            withCodes2(ID.LICENSE_SIGNING),
            withAuth,
            withProvider,
        )(Component),
};
