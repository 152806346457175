import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CountryPicker from 'app/blocks/country-picker/country-picker';
import RowDropdown from 'app/blocks/Row/_Dropdown/Row_Dropdown';

function CountryPickerWithTooltip(props) {
    // A kind of hack here - we swicth of disbaling, but disable label and control instead
    // Such way we can make tooltip clickable AND visible (it's impossible to just make z-index to remove opacity)
    const disabled = { disabledBlock: props.disabled };

    const comboboxRenderer = p => {
        return (
            <div className={cn(disabled)} style={{ flexGrow: 1 }}>
                <CountryPicker
                    {...p}
                    className="lName"
                    disabled={props.disabled}
                    isError={!!p.error}
                    seleniumid={`${p.formSeleniumid}-${p.name}`}
                />
            </div>
        );
    };

    return (
        <RowDropdown
            {...props}
            comboboxRenderer={comboboxRenderer}
            disabled={false}
            label={<span className={cn(disabled)}>{props.label}</span>}
            prompt={props.isShowTooltip && { nodeId: props.tooltipNodeId }}
        />
    );
}

CountryPickerWithTooltip.propTypes = {
    error: PropTypes.shape({}),
    formSeleniumid: PropTypes.string.isRequired,
    isShowTooltip: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tooltipNodeId: PropTypes.string.isRequired,
};

CountryPickerWithTooltip.defaultProps = {
    error: undefined,
    isShowTooltip: false,
};

export default CountryPickerWithTooltip;
