import React from 'react';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';

function describeAddress(address: AddressLocation): string {
    const serial = [
        address.addressLine1,
        address.addressLine2,
        address.city,
        address.stateName,
        address.zipCode,
        address.countryName,
    ];

    return serial.filter(truthy => !!truthy).join(', ');
}

function AddressDoctorNoSuggestions({
    l,
    onCancel,
    onContinue,
    userEntered,
}: {
    onCancel: () => void;
    onContinue: () => void;
    l: l;
    userEntered: AddressLocation;
}): React.ReactElement {
    return (
        <Modal className="Dialog" ignoreBackdropClicks onClose={onCancel} type="address-doctor_no-suggestions">
            <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                <DialogMessage html={l('ADDRESS_DOCTOR.NO_SUGGESTIONS_FOUND')} type="address-doctor_no-suggestions" />

                <div className="DialogAddressDoctor-UserEnteredAddressLabel">
                    {l('ADDRESS_DOCTOR.USER_SUGGESTION_LABEL')}
                </div>
                <div className="DialogAddressDoctor-UserEnteredAddressText">{describeAddress(userEntered)}</div>

                <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                    <PrimaryButton data-seleniumid="suggestions_continue_button" onClick={onContinue}>
                        {l('ADDRESS_DOCTOR.CONTINUE')}
                    </PrimaryButton>
                    <Button data-seleniumid="suggestions_cancel_button" onClick={onCancel}>
                        {l('ADDRESS_DOCTOR.CANCEL')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default withCodes(AddressDoctorNoSuggestions, ID.ADDRESS_DOCTOR);
