import React from 'react';
// import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
// import showDialog from 'app/blocks/common/jsx/dialogModal';
import withUrlParams from 'app/blocks/middleware/url-params';

import './services-message.scss';

type Props = {
    codes: Record<string, Record<string, string>>;
    l: l;
    returnUrl?: string;
};

function ServicesMessage({ codes, l, returnUrl }: Props) {
    if (!returnUrl) return null;

    const service = Object.entries(codes.SERVICE_NAMES).find(([, regexp]) => new RegExp(regexp).test(returnUrl));

    return (
        <div className="Services-Messages">
            <div>
                {l('SERVICE_NAMES.REDIRECTING_TO')}
                {service ? service[0] : returnUrl}
            </div>
        </div>
    );
}

export default withUrlParams(withCodes(ServicesMessage, ID.SERVICE_NAMES), true);
