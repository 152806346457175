import React from 'react';
import AffiliationsStepEnum from 'app/blocks/article-affiliations-editor/AffiliationsStepEnum';
import { ID, withCodes } from 'app/blocks/common/codes';
import CountryPickerWithTooltip from 'app/blocks/Row/_DropdownWithTooltip/Row__Dropdown_WithTooltip';
import { WarningView } from 'app/blocks/views/WidgetView';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import Header from '../Header/Header';
import './confirmCountryBlock.scss';

export type Props = {
    l: l;
    discountCountry: string;
    geoWaiverExists: boolean;
    step: Values<typeof AffiliationsStepEnum>;
    noGeoWaiver: boolean;
    onNoGeoWaiverChange: (value: boolean) => void;
    onPriceProposalCountryChange: (value: boolean) => void;
    onCountryChange: (countryName: string) => void;
};

type State = {
    initialCountry: string;
    selectedCountry:
        | {
              id: string;
              name: string;
          }
        | undefined;
    showWarning: boolean;
};

class ConfirmCountryBlock extends React.Component<Props, State> {
    state: State = {
        initialCountry: '',
        selectedCountry: undefined,
        showWarning: false,
    };

    componentDidMount() {
        const { discountCountry } = this.props;
        if (discountCountry) {
            const countryCode = discountCountry.toLowerCase();
            this.setState({ initialCountry: countryCode, selectedCountry: { id: countryCode, name: discountCountry } });
        }
    }

    handleCountryChange = (selectedCountry: { id: string; name: string }) => {
        const { onCountryChange, onPriceProposalCountryChange } = this.props;
        const { initialCountry } = this.state;

        const isWaiverCountryChanged = selectedCountry?.name?.toLowerCase() !== initialCountry?.toLowerCase();
        const shouldShowWarning = isWaiverCountryChanged && this.props.geoWaiverExists;
        onPriceProposalCountryChange(!shouldShowWarning);
        this.setState({ selectedCountry, showWarning: shouldShowWarning });
        if (selectedCountry) {
            onCountryChange(selectedCountry.name);
        }
    };

    render() {
        const { l, noGeoWaiver, onNoGeoWaiverChange, step } = this.props;
        const { selectedCountry, showWarning } = this.state;

        return (
            <div className="ConfirmCountry-Title">
                <Header step={step} />
                <div className="ConfirmCountry-DropDown">
                    <CountryPickerWithTooltip
                        changeHandler={this.handleCountryChange}
                        className="ConfirmCountry-Row"
                        disabled={false}
                        formSeleniumid="confirm"
                        hideNoneValue
                        isRequired
                        isShowTooltip={false}
                        label={l('ORDER_PANELS.PANEL_CONTACT.COUNTRY_LABEL')}
                        name="country"
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.COUNTRY_PLACEHOLDER')}
                        selectedItem={selectedCountry}
                        tooltipNodeId="country"
                    />
                </div>
                {showWarning && (
                    <>
                        <div className="ConfirmCountry-Warning">
                            <WarningView
                                seleniumid="warning"
                                title={l('ARTICLE_AFFILIATION_EDITOR.IGNORE_WAIVER_WARNING_TITLE')}
                            >
                                {l('ARTICLE_AFFILIATION_EDITOR.IGNORE_WAIVER_WARNING_TEXT')}
                            </WarningView>
                        </div>
                        <div>
                            <CheckboxButton
                                checked={noGeoWaiver}
                                className="ConfirmCountry-NoWaiver"
                                data-seleniumid="no-waiver-checkbox"
                                label={l('ARTICLE_AFFILIATION_EDITOR.IGNORE_WAIVER_TEXT')}
                                onChange={onNoGeoWaiverChange}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export { ConfirmCountryBlock };

export default withCodes(ConfirmCountryBlock, ID.ORDER_PANELS, ID.WIDGETS);
