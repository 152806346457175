import cn from 'classnames';
import React from 'react';
import { MainTitle, ItemTitle } from 'app/blocks/titles/titles';

export function SectionTitle({ className, ...props }) {
    return <MainTitle {...props} className={cn('licenseSigningProcessPage-sectionTitle', className)} />;
}

export function SectionDescription({ className, ...props }) {
    return <ItemTitle {...props} className={cn('licenseSigningProcessPage-sectionDescription', className)} />;
}

export function SectionPrompt({ children, className, ...props }) {
    return (
        <p
            {...props}
            className={cn('licenseSigningProcessPage-sectionText', className)}
            dangerouslySetInnerHTML={{
                __html: children,
            }}
        />
    );
}

export function BackAndForthContainer({ children, className }) {
    return <div className={cn('licenseSigningProcessPage-backAndForth', className)}>{children}</div>;
}
