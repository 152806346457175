import React from 'react';
import { MainTitle } from 'app/blocks/titles/titles';

type Props = {
    pageid?: string;
    seleniumid?: string;
    children: React.ReactNode;
    subtitle?: React.ReactNode;
};

export default function ({ children, pageid, seleniumid, subtitle }: Props) {
    return (
        <div className="page__title" data-seleniumid={`${seleniumid}-title`}>
            <MainTitle data-pageid={pageid} style={{ marginBottom: 0 }}>
                {children}
            </MainTitle>
            {subtitle && <h4 data-seleniumid={`${seleniumid}-subtitle`}>{subtitle}</h4>}
        </div>
    );
}
