import cn from 'classnames';
import { autobind } from 'core-decorators';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import { compose } from 'app/blocks/common/utils';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import VatOrTaxForm from 'app/pages/orders/panel/vat-tax';
import validationVatTax from './validation';
import DiscountAndBillingContextGOA from '../Context';

import './VatTax.scss';

type VatTaxPanelProps = {
    countryCode: string;
    journal: object;
    orderWithUpdates: { vatTaxDetails: object };
    setValidationHandler: (handler: Function) => void;
    setVatTaxState: (state: object) => void;
    vatTaxDetails: { vatIdNumber: number; taxExemptionNumber: number };
    revenueModel: string;
    isWaiverApplied: boolean;
};

@autobind
export class VatTaxPanel extends React.PureComponent<VatTaxPanelProps> {
    state = {
        isValidating: false,
        validationResults: undefined,
    };

    componentDidMount() {
        this.props.setValidationHandler(this.validate);
    }

    @processing('isValidating')
    async validate() {
        try {
            const { journal, orderWithUpdates } = this.props;
            await validationVatTax({ ...orderWithUpdates, journal });
            this.setState({ validationResults: undefined });
        } catch (error) {
            if (error.name === 'ValidationError') {
                this.setState({ validationResults: error });
            }

            throw error;
        }
    }

    async updateVatIdNumber(vatId) {
        const vatIdNumber = vatId ? vatId.trim() : '';
        this.props.setVatTaxState({ vatTaxDetails: { ...this.props.vatTaxDetails, vatIdNumber } });
    }

    updateTaxExemptionNumber(taxExemptionNumber) {
        this.props.setVatTaxState({ vatTaxDetails: { ...this.props.vatTaxDetails, taxExemptionNumber } });
    }

    render() {
        const {
            countryCode,
            isWaiverApplied,
            revenueModel,
            vatTaxDetails: { taxExemptionNumber, vatIdNumber },
        } = this.props;
        const { isValidating, validationResults } = this.state;

        return (
            <section
                className={cn('payment-panel VatTaxPanel', { 'payment-panel--error': validationResults })}
                data-seleniumid="VatTaxPanel"
            >
                {validationResults && <ErrorLabel text={validationResults.message} />}

                {!isWaiverApplied && (
                    <VatOrTaxForm
                        className="VatTaxPanel"
                        countryCode={countryCode}
                        ignoreRevenueModel
                        isValidating={isValidating}
                        revenueModel={revenueModel}
                        taxExemptionNumber={taxExemptionNumber}
                        updateTaxExemptionNumber={this.updateTaxExemptionNumber}
                        updateVatIdNumber={this.updateVatIdNumber}
                        vatIdNumber={vatIdNumber}
                    />
                )}
            </section>
        );
    }
}

export default compose(
    DiscountAndBillingContextGOA.withContext(state => ({
        ...state.initiate.vatTax,
        article: state.article,
        journal: state.journal,
        countryCode: state?.initiate?.addresses?.mainAddress?.countryCode,
        orderWithUpdates: state.orderWithUpdates,
        register: state.registerPanel,
        unregister: state.unregisterPanel,
        setVatTaxState: state.setVatTaxState,
        revenueModel: state.journal.revenueModel,
        isWaiverApplied: state.isWaiverApplied,
    })),
    withLightPanel('vatTax'),
    withCodes2(ID.ERROR),
)(VatTaxPanel);
