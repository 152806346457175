import { autobind } from 'core-decorators';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import {
    processSignLicenseClick as sendAnalyticsOnClick,
    processSignLicenseStepLoad as sendAnalyticsOnLoad,
    pageEvents,
} from 'app/blocks/analytics/analytics';
import { addPaymentCheckout } from 'app/blocks/analytics/storage';
import { infoUpdated } from 'app/blocks/common/as-info';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { compactObject, compose } from 'app/blocks/common/utils';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import { applyDiscounts } from 'app/blocks/middleware/discount';
import * as middlewareLicense from 'app/blocks/middleware/license';
import * as middleware from 'app/blocks/middleware/middleware';
import UploadMiddleware from 'app/blocks/middleware/upload';
import { Discount } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import ErrorEnum from 'app/pages/license-signing-process/ErrorEnum';
import {
    confirmChangeFromOnlineOpenToSubscription,
    confirmSubscriptionSelection,
} from 'app/pages/license-signing-process/OpenAccessOptions/utils';
import SigningMethodEnum from 'app/pages/license-signing-process/SigningMethodEnum';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import routes from 'app/pages/routes';
import {
    calculateSteps,
    getFilledSteps,
    getVisibleSteps,
    isLicenseChoiceSkipped,
    updateWithSelection,
    STEPS,
    LICENSE_STEPS,
} from './utils';
import withContext from './withContext';

type Props = {
    articleId: string;
    l: l;
    step?: string;
};

type Step = { id: string; name: string; show: boolean };

type State = {
    activeStep: string;
    all: middlewareLicense.CombinedFlowPayload;
    article: Product.Article;
    isProcessing: boolean;
    journal: Product.Journal;
    steps: Step[];
    l?: l;
    listCopyrightOwnerships: Object[];
    uploadsConfig: {
        maxFilesNumber: number;
        maxTotalFilesSize: number;
        maxTotalLicenseFilesSize: number;
        supportedMimeTypes: string[];
    };

    isRCAChangeConfirming: boolean;
    isAffiliationsConfirming: boolean;
    isFundersConfirming: boolean;
    isCopyrightConfirming: boolean;
    isSubscriptionOptionConfirming: boolean;
    isOnlineOpenOptionConfirming: boolean;
    isChosenOpenAccessOptionLeaving: boolean;
    isLicenseTypeConfirming: boolean;
    isDiscountAndBillingConfirming: boolean;
    isPaymentConfirming: boolean;
    isPaymentMethodConfirming: boolean;
    isLicenseSubmissionConfirming: boolean;
    isUpdatingInfo: boolean;
};

type ContextInterface = State & {
    confirmIncorrectAuthorList: (reasons: string[], message?: string) => Promise<boolean>;
    confirmRCAChange: (
        correspondingAuthors: Array<{ participantId: string }>,
        rca: { participantId: string },
    ) => Promise<void>;
    confirmAffiliations: (affiliations: Object[], countryName: string) => Promise<void>;
    confirmResearchFunders: (researchPrograms: Object[]) => Promise<void>;
    confirmCopyrightOwnership: (copyrightOwnerships: Object[]) => Promise<void>;
    confirmOnlineOpenOption: () => Promise<void>;
    confirmSubscriptionOption: () => Promise<void>;
    confirmLicenseType: (licenseType: string) => Promise<void>;
    confirmDiscountAndBilling: (order: Object, isGold: boolean) => Promise<void>;
    confirmOpenAccessCoverage: (grantFunding: Object) => Promise<void>;
    confirmPayment: (onlineOpenOrder: Object) => Promise<void>;
    confirmPaymentMethod: (order: Object) => Promise<void>;
    deleteOnlineOpenDraft: () => Promise<void>;
    confirmElectronicLicenseSubmission: (authorSignature: string) => Promise<void>;
    confirmPrintLicenseSubmission: (
        files: Array<Object>,
        fileNames: Object,
        markFilesAsCorruptedByNames: (fileNameList: string[]) => void,
    ) => Promise<void>;
    confirmLicenseMultiStep: (selectionType: string) => Promise<void>;
    getActiveStep: () => { id: string; name: string } | undefined;
    getPrevStep: () => { id: string; name: string };
    goBack: () => void;
    leaveChosenOpenAccessOption: () => Promise<void>;
    reload: () => Promise<void>;
    confirmStep: (step: string, data: any) => Promise<void>;
    onConfirmSubstep: (isStepConfirmed: boolean) => void;
    onLoadSubstep: (substep?: string, page?: string, error?: any) => void;
    onPaymentCheckout: () => void;
    confirmStepTracker: (currentStep: number) => Promise<void>;
    updateDiscount: (articleId: string, discountCodes: string[]) => Promise<Discount>;
};

// @ts-ignore
const Context = React.createContext<ContextInterface>({});

const getPageInfo = OAOptions => {
    if (OAOptions && OAOptions.show) {
        const pageInfo: Record<string, any> = {};
        pageInfo.fullWOAAOffer = !!(OAOptions.eligibility && !OAOptions.isInstitutionalDiscount);
        if (pageInfo.fullWOAAOffer) {
            pageInfo.acceptedFullWOAAOffer = OAOptions.confirmed ? !!OAOptions.authorSelectedOnlineOpen : null;
        }
        return pageInfo;
    }
    return null;
};

function withProvider(WrappedComponent) {
    @autobind
    class ContextWrapper extends React.PureComponent<Props, State> {
        static propTypes = {
            articleId: PropTypes.string.isRequired,
            l: PropTypes.func.isRequired,
            step: PropTypes.string,
        };

        static defaultProps = {
            step: undefined,
        };

        state: State = {
            activeStep: undefined,
            all: undefined,
            article: undefined,
            isAffiliationsConfirming: false,
            isChosenOpenAccessOptionLeaving: false,
            isCopyrightConfirming: false,
            isDiscountAndBillingConfirming: false,
            isFundersConfirming: false,
            isLicenseSubmissionConfirming: false,
            isLicenseTypeConfirming: false,
            isOnlineOpenOptionConfirming: false,
            isPaymentConfirming: false,
            isPaymentMethodConfirming: false,
            isProcessing: true,
            isRCAChangeConfirming: false,
            isSubscriptionOptionConfirming: false,
            isUpdatingInfo: false,
            journal: undefined,
            listCopyrightOwnerships: [],
            steps: [],
            uploadsConfig: {
                maxFilesNumber: 0,
                maxTotalFilesSize: 0,
                maxTotalLicenseFilesSize: 0,
                supportedMimeTypes: [],
            },
        };

        @tryCatch.showPopUpAndGoToDashboard
        async componentDidMount() {
            const { articleId, l, step } = this.props;

            const { article, journal } = await middleware.product.getArticleAndJournal(articleId);
            const listCopyrightOwnerships = await middlewareLicense.listCopyrightOwnerships();
            const uploadsConfig = await UploadMiddleware.getValidationData(this.props.articleId);

            let all = await middlewareLicense.getAll(articleId);
            all = await this.confirmLicenseAgreementTypeIfOnlyOneAvailable(articleId, all, l);
            const steps = calculateSteps(STEPS, all, articleId, l);

            let activeStep;

            if (this.isValidStep(steps, step)) {
                activeStep = step;
            } else if (this.hasNextStep(steps)) {
                activeStep = this.getNextStep(steps).id;
            } else {
                routes.navigateToDashboard();
                return;
            }

            this.setState({
                activeStep,
                all,
                article,
                isProcessing: false,
                journal,
                l,
                listCopyrightOwnerships,
                steps,
                uploadsConfig,
            });
        }

        componentDidUpdate(prevProps, prevState) {
            const { step } = this.props;
            const { activeStep, steps } = this.state;

            if (step !== activeStep && step !== prevProps.step) {
                if (this.isValidStep(steps, step)) {
                    this.setState({ activeStep: step });
                } else if (this.hasNextStep(steps)) {
                    this.setState({ activeStep: this.getNextStep(steps).id });
                } else {
                    this.goToArticlePage();
                    return;
                }
            }

            if (activeStep !== step && activeStep !== prevState.activeStep && step === prevProps.step) {
                // fix browser address bar
                routes.navigateToUrl(this.getStep(this.state.steps, this.state.activeStep).url);
            }
        }

        async sendAnalytics(newAll) {
            const { activeStep, article, journal, steps } = this.state;
            const step = steps.find(s => s.id === activeStep);
            const { id, name } = step;
            const updatedStepInfo = newAll ? newAll[id] : undefined;
            const isConfirmed = updatedStepInfo ? !!updatedStepInfo.confirmed || !updatedStepInfo.show : false;
            await sendAnalyticsOnClick({
                article,
                journal,
                step: { data: { ...updatedStepInfo, id }, isConfirmed, name },
            });
        }

        formAnalyticsData(isStepConfirmed = false) {
            const { all } = this.state;
            const { activeStep, article, journal, steps } = this.state;
            const step = steps.find(s => s.id === activeStep);
            const { id, name } = step;
            const updatedStepInfo = all ? all[id] : undefined;
            return {
                article,
                journal,
                step: { data: { ...updatedStepInfo, id }, isConfirmed: isStepConfirmed, name },
            };
        }

        async sendPaymentConfirmedAnalytics(order) {
            const { activeStep, article, journal, steps } = this.state;
            const step = steps.find(s => s.id === activeStep);
            const { id, name } = step;
            await sendAnalyticsOnClick({
                article,
                journal,
                step: { data: { ...order, id }, isConfirmed: true, name },
            });
        }

        async onConfirmSubstep(isStepConfirmed = false) {
            const data = this.formAnalyticsData(isStepConfirmed);
            await sendAnalyticsOnClick(data);
        }

        onPaymentCheckout() {
            const data = this.formAnalyticsData(true);
            addPaymentCheckout(data);
        }

        onLoadSubstep(substep, page) {
            const { activeStep, article, journal, steps } = this.state;
            const { name } = steps.find(s => s.id === activeStep);

            if (name) {
                const step = { ...compactObject({ name, substep }), isConfirmed: false };
                const data = { article, journal, step };
                sendAnalyticsOnLoad(data, pageEvents.PAGE_LOAD);
            }

            if (page) {
                const other = getPageInfo(this.state.all?.[StepEnum.OPEN_ACCESS_OPTIONS]);
                infoUpdated({ article, journal, other, page });
            }
        }

        async handleNewData(newAll, changeStep = true) {
            const { articleId, l } = this.props;

            if (get(newAll, `${StepEnum.CONFIRMATION}.redirectToDashboard`) === true) {
                this.goToArticlePage();
                return;
            }

            const all = await this.confirmLicenseAgreementTypeIfOnlyOneAvailable(articleId, newAll, l);
            const steps = calculateSteps(STEPS, all, articleId, this.props.l);

            let { activeStep } = this.state;
            if (changeStep || !this.isValidStep(steps, activeStep)) {
                if (this.hasNextStep(steps)) {
                    activeStep = this.getNextStep(steps).id;
                } else {
                    this.goToArticlePage();
                    return;
                }
            }

            await new Promise<void>(resolve =>
                this.setState(
                    {
                        activeStep,
                        all,
                        steps,
                    },
                    resolve,
                ),
            );
        }

        getStep(steps, step) {
            return steps.find(x => x.id === step);
        }

        getPrevStep(steps, activeStep) {
            const activeStepIndex = steps.findIndex(x => x.id === activeStep);

            if (activeStepIndex === 0) {
                return { id: 'dashboard', name: this.props.l('BUTTONS.BACK_TO_ARTICLE_PAGE') };
            }

            return steps[activeStepIndex - 1];
        }

        getNextStep(steps) {
            return steps.find(x => !x.completed);
        }

        goToArticlePage() {
            routes.navigateToUrl(`article/${this.props.articleId}`);
        }

        hasNextStep(steps) {
            return this.getNextStep(steps) != null;
        }

        isValidStep(steps, stepId) {
            return !!stepId && !get(this.getStep(steps, stepId), 'disabled', true);
        }

        goBack() {
            const prevStep = this.getPrevStep(this.state.steps, this.state.activeStep);

            if (prevStep.id !== 'dashboard') {
                routes.navigateToUrl(prevStep.url.replace(/^#/, ''));
            } else {
                this.goToArticlePage();
            }
        }

        goForward() {
            const { steps } = this.state;

            if (this.hasNextStep(steps)) {
                routes.navigateToUrl(this.getNextStep(this.state.steps).url.replace(/^#/, ''));
            } else {
                this.goToArticlePage();
            }
        }

        async goToConfirmationPageIfSanction(all) {
            await this.handleNewData(all);
        }

        async confirmStep(step, data, skipAnalytics = false) {
            const all = await middlewareLicense.confirmStep(this.props.articleId, step, data);
            if (step === 'openAccessCoverage') {
                const { confirmed, show } = all[step];
                const openAccessCoverage = { ...all[step], ...data, confirmed, show };
                all[step] = openAccessCoverage;
            }
            if (!skipAnalytics) {
                this.sendAnalytics(all);
            }
            await this.handleNewData(all);
        }

        async reload() {
            await this.handleNewData(await middlewareLicense.getAll(this.props.articleId), false);
        }

        @tryCatch.popUpOnly
        @processing('isIncorrectAuthorListConfirming')
        async confirmIncorrectAuthorList(reasons, message) {
            const all = await middlewareLicense.confirmIncorrectAuthorList(this.props.articleId, reasons, message);
            this.sendAnalytics(all);
            const canContinue = !all[StepEnum.CONFIRMATION].page;

            await this.handleNewData(all, !canContinue);

            return canContinue;
        }

        @tryCatch.popUpOnly
        @processing('isRCAChangeConfirming')
        async confirmRCAChange(correspondingAuthors, rca) {
            const { all } = this.state;

            const { rcaConfirmationData } = all[StepEnum.CONFIRM_AUTHORS];

            await this.confirmStep(StepEnum.CONFIRM_AUTHORS, {
                correspondingAuthors: correspondingAuthors.map(x => x.participantId),
                rca: rca.participantId,
                specifiedEmails: correspondingAuthors
                    .filter(x => !rcaConfirmationData.find(y => y.participantId === x.participantId).email)
                    .map(x => ({ email: x.email, participantId: x.participantId })),
            });
        }

        @tryCatch.popUpOnly
        @processing('isAffiliationsConfirming')
        async confirmAffiliations(affiliations, countryName) {
            // await this.confirmStep(StepEnum.AFFILIATIONS, {});
            const all = await middlewareLicense.confirmStep(this.props.articleId, StepEnum.AFFILIATIONS, {
                countryName,
            });
            all.articleAffiliations.affiliations = affiliations;
            all.articleAffiliations.countryName = countryName;
            this.sendAnalytics(all);
            if (
                this.state.all.articleAffiliations.sanctionAffiliationsAndSubsLicenseExists &&
                this.state.activeStep === StepEnum.AFFILIATIONS
            ) {
                const message = this.props.l('ORDER_PANELS.SANCTION_COUNTRY_WARNING.SUBSCRIPTION_AFFILIATION_MESSAGE');
                const errorMsg = new ServiceError(message, null, null, null);
                await showDialog.addErrorMessageDialog(errorMsg, () => this.goToConfirmationPageIfSanction(all));
            } else {
                await this.handleNewData(all);
            }
        }

        @tryCatch.popUpOnly
        @processing('isFundersConfirming')
        async confirmResearchFunders(researchPrograms) {
            await this.confirmStep(StepEnum.FUNDERS, { items: researchPrograms });
        }

        @tryCatch.popUpOnly
        @processing('isCopyrightConfirming')
        async confirmCopyrightOwnership(copyrightOwnerships) {
            await this.confirmStep(StepEnum.COPYRIGHT, { copyrightOwnerships });
        }

        @tryCatch.popUpOnly
        @processing('isOpenAccessCoverageConfirming')
        async confirmOpenAccessCoverage(grantFunding) {
            await this.confirmStep(StepEnum.OPEN_ACCESS_COVERAGE, grantFunding);
        }

        async confirmOpenAccessOptions(newAuthorSelectedOnlineOpen) {
            const { authorSelectedOnlineOpen, confirmed } = this.state.all[StepEnum.OPEN_ACCESS_OPTIONS];

            if (confirmed) {
                if (authorSelectedOnlineOpen === newAuthorSelectedOnlineOpen) {
                    this.goForward();
                } else if (newAuthorSelectedOnlineOpen || (await confirmChangeFromOnlineOpenToSubscription())) {
                    await this.confirmStep(StepEnum.OPEN_ACCESS_OPTIONS, {
                        authorSelectedOnlineOpen: newAuthorSelectedOnlineOpen,
                    });
                }
            } else if (newAuthorSelectedOnlineOpen || (await confirmSubscriptionSelection())) {
                await this.confirmStep(StepEnum.OPEN_ACCESS_OPTIONS, {
                    authorSelectedOnlineOpen: newAuthorSelectedOnlineOpen,
                });
            }
        }

        @tryCatch.popUpOnly
        @processing('isSubscriptionOptionConfirming')
        async confirmSubscriptionOption() {
            await this.confirmOpenAccessOptions(false);
        }

        @tryCatch.popUpOnly
        @processing('isOnlineOpenOptionConfirming')
        async confirmOnlineOpenOption() {
            await this.confirmOpenAccessOptions(true);
        }

        @tryCatch.popUpOnly
        @processing('isChosenOpenAccessOptionLeaving')
        async leaveChosenOpenAccessOption() {
            await this.confirmOpenAccessOptions(this.state.all[StepEnum.OPEN_ACCESS_OPTIONS].authorSelectedOnlineOpen);
        }

        @tryCatch.popUpOnly
        @processing('isLicenseTypeConfirming')
        async confirmLicenseType(licenseType) {
            updateWithSelection(this.state.all[StepEnum.LICENSE_TYPES], { value: licenseType });
            await this.confirmStep(StepEnum.LICENSE_TYPES, {
                licenseChoices: this.state.all[StepEnum.LICENSE_TYPES].licenseChoices,
            });
        }

        async confirmLicenseAgreementTypeIfOnlyOneAvailable(articleId, all, l) {
            const steps = calculateSteps(STEPS, all, articleId, l);

            const licenseAgreementTypes = get(all, StepEnum.LICENSE_TYPES);
            if (
                this.hasNextStep(steps) &&
                this.getNextStep(steps).id === StepEnum.LICENSE_TYPES &&
                !licenseAgreementTypes?.confirmed &&
                isLicenseChoiceSkipped(licenseAgreementTypes)
            ) {
                // just show agreement types page in case of an error to show an error
                // when user confirms agreement type by clicking on a button
                try {
                    updateWithSelection(licenseAgreementTypes);
                    return await middlewareLicense.confirmStep(articleId, StepEnum.LICENSE_TYPES, {
                        licenseChoices: licenseAgreementTypes.licenseChoices,
                    });
                } catch (error) {
                    console.error(error);
                }
            }

            return all;
        }

        @processing('isDiscountAndBillingConfirming')
        async confirmDiscountAndBilling(order, isGold = false) {
            try {
                const data = isGold ? { order } : { onlineOpenOrder: order };
                await this.confirmStep(StepEnum.DISCOUNT_AND_BILLING, data);
            } catch (error) {
                if (error.code?.includes('504')) {
                    showDialog.modal(closeDialogAnd => ({
                        closeBtnLabel: this.props.l('LICENSE_SIGNING.PAYMENT.REVIEW_ORDER_SUBMIT_TIMEOUT.BUTTON'),
                        message: this.props.l('LICENSE_SIGNING.PAYMENT.REVIEW_ORDER_SUBMIT_TIMEOUT.MESSAGE'),
                        onClose: closeDialogAnd(() => routes.navigateToDashboard()),
                        type: 'info',
                    }));
                } else {
                    showDialog.error(error);
                }
            }
        }

        @processing('isPaymentConfirming')
        async confirmPayment(onlineOpenOrder) {
            await this.sendPaymentConfirmedAnalytics({ onlineOpenOrder });
            await this.confirmStep(StepEnum.PAYMENT, { onlineOpenOrder }, true);
        }

        @processing('isPaymentMethodConfirming')
        async confirmPaymentMethod(order, isEditOrderFlow = false) {
            await this.onConfirmSubstep();
            const { articleId, paymentDetails } = order;
            await middleware.payment.savePaymentMethod(articleId, paymentDetails, isEditOrderFlow);
            await this.handleNewData(await middlewareLicense.getAll(this.props.articleId), false);
            const { activeStep, all } = this.state;
            return all[activeStep];
        }

        async deleteOnlineOpenDraft() {
            const all = await middlewareLicense.deleteOnlineOpenOrderDraft(this.props.articleId);
            this.sendAnalytics(all);
            await this.handleNewData(all);
        }

        @tryCatch.popUpOnly
        @processing('isLicenseSubmissionConfirming')
        async confirmElectronicLicenseSubmission(authorSignature) {
            await this.confirmStep(StepEnum.LICENSE_SUBMISSION, {
                electronicLicenseSubmission: { authorSignature },
                signingMethod: SigningMethodEnum.ELECTRONIC,
            });
        }

        @tryCatch.popUpOnly
        @processing('isLicenseSubmissionConfirming')
        async confirmPrintLicenseSubmission(files, fileNames, markFilesAsCorruptedByNames) {
            try {
                await this.confirmStep(StepEnum.LICENSE_SUBMISSION, {
                    printLicenseSubmission: files,
                    signingMethod: SigningMethodEnum.PRINT,
                });
            } catch (error) {
                if (error.code === ErrorEnum.LICENSE_UPLOADING_FILE_IS_CORRUPTED) {
                    markFilesAsCorruptedByNames(error.payload);

                    error.message = this.props.l('ERROR.LICENSE_UPLOADING_FILE_IS_CORRUPTED', {
                        payload: error.payload.map(e => fileNames[e]),
                    });
                }

                if (error.code === ErrorEnum.LICENSE_UPLOADING_TOTAL_LICENSE_FILE_SIZE_EXCEEDED) {
                    error.message = this.props.l('ERROR.LICENSE_UPLOADING_TOTAL_FILE_SIZE_EXCEEDED');
                }

                throw error;
            }
        }

        @tryCatch.popUpOnly
        @processing('isLicenseSubmissionConfirming')
        async confirmLicenseMultiStep(selectionType) {
            if (selectionType) {
                await middlewareLicense.comfirmLicenseMultiStep(this.props.articleId, selectionType);
            }
        }

        async confirmStepTracker(currentStep) {
            const curr = LICENSE_STEPS[currentStep];
            if (curr?.id) {
                await this.confirmLicenseMultiStep(curr.selectionType);

                const stepTracker = this.state.all?.licenseSubmission?.stepTracker || {};
                // todo: we may use response from confirming, or even regular confirmStep (and it's response) to regenerate screen/step
                stepTracker[curr.id] = stepTracker[curr.id] || {};
                stepTracker[curr.id].confirmed = true;
            }
        }

        @processing('isUpdatingInfo')
        async updateDiscount(articleId, discountCodes): Promise<Discount> {
            const newAll = await applyDiscounts(articleId, discountCodes);
            const discount = newAll.discountAndBillingDetails?.order?.prices?.[0]?.appliedDiscounts?.[0];
            await this.handleNewData(newAll, false);
            return discount;
        }

        render() {
            return (
                <Context.Provider
                    value={{
                        ...this.state,
                        confirmAffiliations: this.confirmAffiliations,
                        confirmCopyrightOwnership: this.confirmCopyrightOwnership,
                        confirmDiscountAndBilling: this.confirmDiscountAndBilling,
                        confirmElectronicLicenseSubmission: this.confirmElectronicLicenseSubmission,
                        confirmIncorrectAuthorList: this.confirmIncorrectAuthorList,
                        confirmLicenseMultiStep: this.confirmLicenseMultiStep,
                        confirmLicenseType: this.confirmLicenseType,
                        confirmOnlineOpenOption: this.confirmOnlineOpenOption,
                        confirmOpenAccessCoverage: this.confirmOpenAccessCoverage,
                        confirmPayment: this.confirmPayment,
                        confirmPaymentMethod: this.confirmPaymentMethod,
                        confirmPrintLicenseSubmission: this.confirmPrintLicenseSubmission,
                        confirmRCAChange: this.confirmRCAChange,
                        confirmResearchFunders: this.confirmResearchFunders,
                        confirmStep: this.confirmStep,
                        confirmStepTracker: this.confirmStepTracker,
                        confirmSubscriptionOption: this.confirmSubscriptionOption,
                        deleteOnlineOpenDraft: this.deleteOnlineOpenDraft,
                        getActiveStep: () => this.getStep(this.state.steps, this.state.activeStep),
                        getPrevStep: () => this.getPrevStep(this.state.steps, this.state.activeStep),
                        goBack: this.goBack,
                        leaveChosenOpenAccessOption: this.leaveChosenOpenAccessOption,
                        onConfirmSubstep: this.onConfirmSubstep,
                        onLoadSubstep: this.onLoadSubstep,
                        onPaymentCheckout: this.onPaymentCheckout,
                        reload: this.reload,
                        updateDiscount: this.updateDiscount,
                    }}
                >
                    <WrappedComponent {...this.props} step={this.state.activeStep} />
                </Context.Provider>
            );
        }
    }

    return ContextWrapper;
}

const useLicenseFlow = () => React.useContext(Context);

export { calculateSteps, getVisibleSteps, getFilledSteps, STEPS, withProvider, useLicenseFlow };
export default {
    withContext: withContext(Context),
    withProvider: Component =>
        compose(withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING, ID.ORDER_PANELS), withProvider)(Component),
};
