import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import FunderPanelContext2 from 'app/blocks/Panel/funder/FunderPanelContext2';
import CheckboxButton from 'app/ui/form/CheckboxButton';

function NoFunders({ l, noFunders, onNoFundersChange }) {
    return (
        <CheckboxButton
            checked={noFunders}
            className="NoFunders"
            data-seleniumid="no-funders-checkbox"
            id="no-funders"
            label={l('LICENSE_SIGNING.FUNDERS.LABEL.NO_FUNDERS')}
            onChange={onNoFundersChange}
        />
    );
}

NoFunders.propTypes = {
    l: PropTypes.func.isRequired,
    noFunders: PropTypes.bool.isRequired,
    onNoFundersChange: PropTypes.func.isRequired,
};

export default compose(
    FunderPanelContext2.withContext(state => ({
        noFunders: state.noResearchPrograms,
        onNoFundersChange: state.toggleNoFundingFlag,
    })),
    withCodes2(ID.LICENSE_SIGNING),
)(NoFunders);
