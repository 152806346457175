import cn from 'classnames';
import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import { processing } from 'app/blocks/common/decorators';
import { progressiveRequest } from 'app/blocks/common/utils';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import './ProgressIndicator.scss';

type Props<T> = {
    config?: Parameters<typeof progressiveRequest>[1];
    component: (payload: T) => React.ReactElement;
    l: l;
    onError?: (error: ServiceError) => string;
    onLoad?: (payload: T) => Promise<void> | void;
    request: () => Promise<T>;
    seleniumid?: string;
};

type State<T> = {
    errorMsg?: string;
    hideIndicator: boolean;
    isLoading: boolean;
    lightLoader: boolean;
    percent: number;
    response?: T;
};

@autobind
class ProgressIndicator<T> extends Component<Props<T>, State<T>> {
    state: State<T> = {
        errorMsg: null,
        hideIndicator: false,
        isLoading: false,
        lightLoader: true,
        percent: 0,
    };

    @processing('isLoading')
    async componentDidMount() {
        const { config = {}, l, onError, onLoad } = this.props;
        let response;

        setTimeout(() => this.setState({ lightLoader: false }), 5000);

        try {
            response = await progressiveRequest(this.props.request, config, this.onProgress);
            if (onLoad) {
                await onLoad(response);
            }
        } catch (error) {
            let errMsg = error?.message || l('ERROR.OTHER_ERROR');

            if (onError) {
                errMsg = onError(error as ServiceError) || errMsg;
            }

            this.setState({
                errorMsg: errMsg,
            });

            if (this.state.lightLoader) {
                // if we don't see indicator before - hide it immediately
                this.setState({ hideIndicator: true });
            }

            setTimeout(() => this.setState({ hideIndicator: true }), 10000);
        } finally {
            this.setState({ lightLoader: false, response });
        }
    }

    onProgress(percent) {
        this.setState({ percent });
    }

    render() {
        const { errorMsg, hideIndicator, isLoading, lightLoader, percent, response } = this.state;
        const { component, l, seleniumid } = this.props;

        if (lightLoader) {
            return <Layout isLoading />;
        }

        return (
            <>
                {isLoading && (
                    <div
                        dangerouslySetInnerHTML={{ __html: l('DASHBOARD.PROGRESS_INDICATOR.LOADING_TEXT') }}
                        data-seleniumid={`${seleniumid}-loading-text`}
                    />
                )}

                {errorMsg && (
                    <div
                        className="ProgressIndicator-Error"
                        dangerouslySetInnerHTML={{ __html: errorMsg }}
                        data-seleniumid={`${seleniumid}-error-container`}
                    />
                )}

                {(isLoading || errorMsg) && (
                    <div
                        className={cn('ProgressIndicator', {
                            Hide: hideIndicator,
                            Red: errorMsg,
                        })}
                        data-seleniumid={`${seleniumid}-progress-bar-container`}
                    >
                        <span
                            className={cn('ProgressIndicator-Text', {
                                Primary: percent < 50,
                                White: percent >= 50,
                            })}
                            data-seleniumid={`${seleniumid}-progress-bar-percent`}
                        >
                            {`${percent}%`}
                        </span>
                        <div
                            className={cn('ProgressIndicator-Bar', {
                                Red: errorMsg,
                            })}
                            style={{ width: `${percent}%` }}
                        />
                    </div>
                )}

                {!isLoading && !errorMsg && component(response)}
            </>
        );
    }
}

export default withCodes(ProgressIndicator, ID.DASHBOARD, ID.ERROR) as <T extends any>(
    props: Omit<Props<T>, 'l'>,
) => any;
