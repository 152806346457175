/* eslint-disable react/no-string-refs */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'underscore';
import { Layout, Title } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import * as middlewareCommon from 'app/blocks/middleware/middleware';
import ContentSearch from 'app/pages/search/_advanced/content-search/content_search';
import { Form } from './search_advanced.form';

class ContentSearchForm extends Component {
    static propTypes = {
        articleForm: PropTypes.instanceOf(Form).isRequired,
        codes: PropTypes.shape({}).isRequired,
        jid: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        jid: null,
    };

    state = {
        isLoading: true,
        journal: null,
    };

    componentDidMount() {
        this.updateJournal();
    }

    componentDidUpdate(prevProps) {
        if (this.props.jid !== prevProps.jid) {
            this.updateJournal();
        }
    }

    convertCodes = codes => {
        if (!_.isObject(codes)) {
            return codes;
        }

        const keys = Object.keys(codes);

        if (keys.indexOf('0') > -1) {
            return keys.map(key => this.convertCodes(codes[key]));
        }

        keys.forEach(key => {
            // eslint-disable-next-line no-param-reassign
            codes[key] = this.convertCodes(codes[key]);
        });

        return codes;
    };

    @tryCatch
    @processing('isLoading')
    async updateJournal() {
        let journal = null;

        if (this.props.jid) {
            journal = await middlewareCommon.product.getJournalById(this.props.jid);
        }

        this.setState({ journal });
    }

    selectForm = () => this.props.articleForm;

    search = () => this.props.onSearch(this.selectForm(), 'article');

    isSearchDisabled = () => this.selectForm().checkIsEmpty();

    setValue = (field, value, multipleValue) => this.selectForm().setValue(field, value, multipleValue);

    onValueChange = (field, value, multipleValue) => {
        this.setValue(field, value, multipleValue);
        if (field !== 'journalId') {
            // TODO setValue mutates this.props.journalForm, this.props.articleForm. Remove forceUpdate hack
            this.forceUpdate();
        }
    };

    render() {
        const { isLoading, journal } = this.state;
        const { codes } = this.props;

        return (
            <div className="container-lg">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <Title seleniumid="advanced-search-page">{codes.SEARCH_CODES.SEARCH.PAGE.title}</Title>
                        <div
                            className="AdvancedSearchSeperator"
                            dangerouslySetInnerHTML={{ __html: codes.SEARCH_CODES.SEARCH.PAGE.description }}
                        />
                        <Layout isLoading={isLoading}>
                            {!isLoading && (
                                <ContentSearch
                                    disabledSearchBtn={this.isSearchDisabled()}
                                    journalData={journal}
                                    onSearch={this.search}
                                    onValueChange={this.onValueChange}
                                />
                            )}
                        </Layout>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes(ContentSearchForm, ID.SEARCH_CODES);
