import cn from 'classnames';
import React from 'react';
import CheckboxButton from '../CheckboxButton';
import './CheckboxList.scss';

export default function CheckboxList({
    className,
    onChange,
    options,
    style,
    values,
    ...props
}: {
    className?: string;
    options: Array<{ value: string; label: string }>;
    onChange: (values: string[]) => void;
    values: string[];
    style?: Record<string, string>;
    [prop: string]: any;
}): React.ReactElement {
    return (
        <div className={cn('CheckboxList', className)} style={style}>
            {options.map(({ label, value }) => (
                <CheckboxButton
                    {...props}
                    key={value}
                    checked={values.includes(value)}
                    data-seleniumid={`Checkbox-${value}`}
                    label={label}
                    onChange={() => {
                        if (values.includes(value)) {
                            onChange(values.filter(x => x !== value));
                        } else {
                            onChange([...values, value]);
                        }
                    }}
                    value={value}
                />
            ))}
        </div>
    );
}
