import React, { createContext, useCallback, useMemo, useState } from 'react';
import { l } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { compose } from 'app/blocks/common/utils';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import withContext from 'app/pages/license-signing-process/context/withContext';
import { DiscountError } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import { composeAppliedDiscountMessage } from './Labels';

export type DiscountsContextType = {
    onApply: () => Promise<any>;
    code?: string;
    onChange: (code: string) => void;
};

export const Context = createContext<DiscountsContextType>({
    onApply: () => Promise.resolve(),
    onChange: () => {},
});

const DiscountsContext = ({ children }) => {
    const { article, updateDiscount } = useLicenseFlow();
    const [code, setCode] = useState('');

    const onApply = useCallback(async () => {
        const discountCode = code.trim();
        if (discountCode) {
            try {
                const discount = await updateDiscount(article.id, [discountCode]);
                setCode('');
                showDialog.success({
                    innerContent: (
                        <div className="appliedDiscountMessage">
                            <span className="appliedDiscountMessage-title">
                                {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.LARGEST_DISCOUNT_APPLIED_TITLE')}
                            </span>
                            <span
                                className="appliedDiscountMessage-message"
                                dangerouslySetInnerHTML={{ __html: composeAppliedDiscountMessage(discount) }}
                            />
                        </div>
                    ),
                });
            } catch (err) {
                const invalidCode = err?.code === DiscountError.INVALID_CODE;
                showDialog.info({
                    innerContent: (
                        <div className="appliedDiscountMessage">
                            <span className="appliedDiscountMessage-title">
                                {!invalidCode &&
                                    l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.APPLY_DISCOUNT_ERROR_TITLE')}
                                {invalidCode &&
                                    l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.APPLY_DISCOUNT_INVALID_TITLE')}
                            </span>
                            {!invalidCode && (
                                <span
                                    className="appliedDiscountMessage-message"
                                    dangerouslySetInnerHTML={{
                                        __html: l(
                                            'LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.APPLY_DISCOUNT_ERROR_MESSAGE',
                                        ),
                                    }}
                                />
                            )}
                        </div>
                    ),
                });
            }
        }
    }, [article, code, updateDiscount]);

    const onChange = useCallback((codeString: string) => {
        setCode(codeString);
    }, []);

    const value = useMemo(() => ({ code, onApply, onChange }), [code, onApply, onChange]);
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

const withProvider = WrappedComponent => props =>
    (
        <DiscountsContext>
            <WrappedComponent {...props} />
        </DiscountsContext>
    );

export default {
    withContext: withContext(Context),
    withProvider: Component => compose(withProvider)(Component),
};
