import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { BackButton, ForwardButton } from 'app/pages/license-signing-process/buttons';
import { SectionTitle } from 'app/pages/license-signing-process/styles';
import CheckboxButton from 'app/ui/form/CheckboxButton';

import './MultiAuthors.scss';

function MultiAuthorsView({
    article,
    canSubmit,
    confirmStep,
    currentStep,
    getPrevStep,
    goBack,
    isObtained,
    l,
    ownerships,
    setCurrentStep,
    setIsObtained,
    submitLicense,
}) {
    return (
        <div className="MultiAuthorLicenseSubmission">
            {currentStep === 0 ? (
                <>
                    <SectionTitle
                        className="MultiAuthorLicenseSubmission-Title"
                        data-seleniumid="license-submission-page-multi-authors-title"
                    >
                        {l('LICENSE_SUBMISSION.LABELS.TITLE')}
                    </SectionTitle>
                    <div
                        className="MultiAuthorLicenseSubmission-SubText"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.MULTI_DESCRIPTION', {
                                link: `#license-signing/${article?.id}/copyrightOwnership`,
                            }),
                        }}
                        data-seleniumid="license-submission-download-sub-text"
                    />
                </>
            ) : (
                <>
                    <SectionTitle
                        className="MultiAuthorLicenseSubmission-Title"
                        data-seleniumid="license-submission-page-multi-authors-title"
                    >
                        {l('LICENSE_SUBMISSION.LABELS.TITLE')}
                        <div
                            className="MultiAuthorLicenseSubmission-SmallText"
                            data-seleniumid="license-submission-continued-text"
                        >
                            {l('LICENSE_SUBMISSION.LABELS.CONTINUED')}
                        </div>
                    </SectionTitle>
                    <div
                        className="MultiAuthorLicenseSubmission-SubText"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SUBMISSION.LABELS.UPLOAD.MULTI_DESCRIPTION'),
                        }}
                        data-seleniumid="license-submission-upload-sub-text"
                    />

                    <div
                        className="MultiAuthorLicenseSubmission-UploadInstructions"
                        data-seleniumid="upload-instructions"
                    >
                        <div className="Header" data-seleniumid="upload-instructions-header">
                            {l('LICENSE_SUBMISSION.LABELS.UPLOAD.MULTI_TITLE')}
                        </div>
                        <div
                            className="List"
                            dangerouslySetInnerHTML={{
                                __html: l('LICENSE_SUBMISSION.LABELS.UPLOAD.MULTI_HINT'),
                            }}
                            data-seleniumid="upload-instructions-list"
                        />
                    </div>

                    <div className="MultiAuthorLicenseSubmission-InfoBlock" data-seleniumid="license-submission-banner">
                        <SvgIcon.info className="InfoIcon" iconTitle="Info" />
                        <div
                            className="InfoBox-Message"
                            dangerouslySetInnerHTML={{
                                __html: l('LICENSE_SUBMISSION.LABELS.UPLOAD.MULTI_INFO'),
                            }}
                        />
                    </div>
                </>
            )}

            <div
                className="MultiAuthorLicenseSubmission-OwnershipList"
                data-seleniumid="license-submission-ownership-list"
            >
                {ownerships}
            </div>

            {currentStep === 0 && (
                <div className="CheckboxContainer">
                    <CheckboxButton
                        checked={isObtained}
                        data-seleniumid="license-submission-all=licenses-downloaded-checkbox"
                        id="license-submission-all=licenses-downloaded-checkbox"
                        label={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: l(`LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.CONFIRM_CHECKBOX`),
                                }}
                            />
                        }
                        onChange={() => setIsObtained(!isObtained)}
                    />
                </div>
            )}

            <div key={currentStep} className="Actions">
                <hr className="Actions-Spacer" />
                {/* eslint-disable react/jsx-no-bind */}
                <div className="Actions-ButtonsContainer">
                    <BackButton onClick={currentStep === 0 ? goBack : () => setCurrentStep(0)}>
                        {getPrevStep().name}
                    </BackButton>

                    {currentStep === 0 ? (
                        <ForwardButton disabled={!isObtained} onClick={confirmStep}>
                            {l('LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.MULTI_AUTHOR.NEXT_BUTTON_FIRST')}
                        </ForwardButton>
                    ) : (
                        <ForwardButton disabled={!canSubmit} onClick={submitLicense}>
                            {l('LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.MULTI_AUTHOR.NEXT_BUTTON_SECOND')}
                        </ForwardButton>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withCodes(MultiAuthorsView, ID.LICENSE_SUBMISSION);
