import cn from 'classnames';
import React from 'react';
import { onEnterKeyPress } from 'app/blocks/common/key-utils';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

type Props = {
    description: string;
    index: number;
    title: string;
};

export default function Item({ description, index, title }: Props) {
    const [isOpened, setOpened] = React.useState(false);

    return (
        <div
            className={cn('ArticleAffiliationsHelpWidget-Item', {
                'ArticleAffiliationsHelpWidget-Item--opened': isOpened,
            })}
            data-seleniumid={`ArticleAffiliationsHelpWidget-Item-${index}`}
        >
            <div
                className="ArticleAffiliationsHelpWidget-ItemTitle"
                data-seleniumid={`ArticleAffiliationsHelpWidget-ItemTitle-${index}`}
                onClick={() => setOpened(!isOpened)}
                onKeyPress={onEnterKeyPress(() => setOpened(!isOpened))}
                role="button"
                tabIndex={0}
            >
                <SvgIcon.chevron iconTitle={title} />
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </div>

            <div
                className="ArticleAffiliationsHelpWidget-ItemDescription"
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    );
}
