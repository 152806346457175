import cn from 'classnames';
import React, { useCallback } from 'react';
import { l } from 'app/blocks/common/codes';
import { SubTitle } from 'app/blocks/titles/titles';
import { Input } from 'app/ui/form';
import RadioButton from 'app/ui/form/RadioButton';
import { NoOption, GrantFunding as GrantFundingType, GrantFundingValue, OTHER } from './types';

import './GrantFunding.scss';

type Reason = {
    code?: string;
    text?: string;
};

type GrantFundingProps = {
    noOptions?: NoOption[];
    value?: GrantFundingType;

    onChange: (reason: Reason) => void;
    onNoReasonChange: (noReason: Reason) => void;
    onOtherChange: (otherReason: Reason) => void;
    otherReason: string;
};

const GrantFunding: React.FC<GrantFundingProps> = ({
    noOptions = [],
    onChange,
    onNoReasonChange,
    onOtherChange,
    otherReason,
    value,
}) => {
    const handleOtherReasonChange = useCallback(event => onOtherChange({ text: event.target.value }), [onOtherChange]);

    return (
        <div className="grantFunding">
            <SubTitle className="grantFunding-title">
                {l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.GRANT_FUNDING.TITLE')}
            </SubTitle>
            <div>
                <div
                    className={cn('grantFunding-option', {
                        selected: value?.answerState === GrantFundingValue.HaveFunds,
                    })}
                >
                    <RadioButton
                        checked={value?.answerState === GrantFundingValue.HaveFunds}
                        className="grantFunding-optionRadio"
                        data-seleniumid="grant-funding-yes-option"
                        id="grant-funding-yes-option"
                        label={l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.GRANT_FUNDING.YES_OPTION_TITLE')}
                        name="grantFunding"
                        onChange={() =>
                            onChange({
                                code: GrantFundingValue.HaveFunds,
                            })
                        }
                    />
                </div>
                <div
                    className={cn('grantFunding-option', {
                        selected: value?.answerState === GrantFundingValue.DoNotHaveFunds,
                    })}
                >
                    <RadioButton
                        checked={value?.answerState === GrantFundingValue.DoNotHaveFunds}
                        className="grantFunding-optionRadio"
                        data-seleniumid="grant-funding-no-option"
                        id="grant-funding-no-option"
                        label={l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.GRANT_FUNDING.NO_OPTION_TITLE')}
                        name="grantFunding"
                        onChange={() => onChange({ code: GrantFundingValue.DoNotHaveFunds })}
                    />
                    {value?.answerState === GrantFundingValue.DoNotHaveFunds && (
                        <div className="grantFunding-noReasons">
                            <strong className="grantFunding-noReasonsTitle">
                                {l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.GRANT_FUNDING.NO_REASONS_TITLE')}
                            </strong>
                            <span className="grantFunding-noReasonsSub">
                                {l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.GRANT_FUNDING.NO_REASONS_SUBTITLE')}
                            </span>
                            {noOptions
                                .sort(({ sortOrder: a }, { sortOrder: b }) => a - b)
                                .map(({ label, optionCode }) => {
                                    return (
                                        <React.Fragment key={optionCode}>
                                            <RadioButton
                                                checked={value.reasonCode === optionCode}
                                                data-seleniumid={`grant-funding-no-reason-${optionCode}-label`}
                                                id={`grant-funding-no-reason-${optionCode}-label`}
                                                label={label}
                                                name="optionCode"
                                                onChange={() =>
                                                    onNoReasonChange({
                                                        code: optionCode,
                                                        text: optionCode === OTHER ? '' : label,
                                                    })
                                                }
                                            />
                                            {value.reasonCode === OTHER && optionCode === OTHER && (
                                                <Input
                                                    className="grantFunding-otherReasonInput"
                                                    data-seleniumid="grant-funding-no-reason-other-input"
                                                    id="grant-funding-no-reason-other-input"
                                                    maxLength={250}
                                                    onChange={handleOtherReasonChange}
                                                    value={otherReason}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GrantFunding;
