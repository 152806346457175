import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import './ConfirmRCAEligibilityMessage.scss';

function ConfirmRCAEligibilityMessage({ affiliations = [], authors = [], className, l }) {
    const eligibilityAuthors = authors.filter(x => affiliations.some(y => x.participantId === y.participantId));

    if (eligibilityAuthors.length === 0) {
        return null;
    }

    const message = (
        <>
            <ul className="ConfirmRCAEligibilityMessage-FunderList">
                {eligibilityAuthors
                    .map(x => {
                        const name = `${x.firstName} ${x.lastName}`;
                        const institutions = affiliations
                            .find(y => x.participantId === y.participantId)
                            .institutions.map(y => y.name)
                            .join(', ');

                        return { institutions, name };
                    })
                    .map(({ institutions, name }) => (
                        <li
                            key={name}
                            dangerouslySetInnerHTML={{
                                __html: l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.ELIGIBILITY.AUTHOR_PROMPT', {
                                    institutions,
                                    name,
                                }),
                            }}
                        />
                    ))}
            </ul>

            <div
                dangerouslySetInnerHTML={{
                    __html: l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.ELIGIBILITY.PROMPT'),
                }}
            />
        </>
    );

    return (
        <EligibilityMessage
            className={cn('ConfirmRCAEligibilityMessage', className)}
            message={message}
            title={l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.ELIGIBILITY.TITLE')}
        />
    );
}

export default withCodes2(ID.LICENSE_SIGNING)(ConfirmRCAEligibilityMessage);
