import cn from 'classnames';
import React from 'react';
import getPublicationStatus from 'app/blocks/article-publication-status/article-publication-status';
import doesAuthorHaveCorrespondingPrimaryRole from 'app/blocks/AuthorRole';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import DiscountBanner from 'app/blocks/discount-banner/DiscountBanner';
import { ArticlePayload } from 'app/blocks/middleware/dashboard';
import routes from 'app/pages/routes';
import ArticleDetails from './ArticleDetails';
import DashboardEligibilityMessage from './DashboardEligibilityMessage/DashboardEligibilityMessage';
import * as utils from './utils';

type Props = {
    isFoundByDoi: boolean;
    item: ArticlePayload;
    l: l;
    searchWords?: string[];
};

function DashboardItems({ isFoundByDoi, item, l, searchWords = [] }: Props) {
    const { article, eligibility, rcaInfo, status } = item;

    const isBlocked = utils.isBlocked(item);
    const canDoRequiredActions = utils.canDoRequiredActions(item);
    const actionsRequiredAndICanDoTheseActions = canDoRequiredActions && item.requiredActions;
    const corrAuthor = doesAuthorHaveCorrespondingPrimaryRole(item.participantRole);
    const statusCode = getPublicationStatus({ ...status, rcaInfo });
    const showDiscountBanner = item.geoWaiver?.showGeoWaiverBanner || false;
    const { waiverCountry = '' }: { waiverCountry?: string } = item.geoWaiver ?? {};
    const title = 'Geographical waiver';
    const description = `${l('DASHBOARD.GEO_WAIVER.DESCRIPTION', { discountCountry: waiverCountry })}
            <br />
            <br />
            ${l('DASHBOARD.GEO_WAIVER.DESCRIPTION_LINK')}`;
    return (
        <div
            className={cn('Item', {
                Required: actionsRequiredAndICanDoTheseActions,
                WaitingAction: isBlocked,
            })}
            data-seleniumid={`article-card-${article.id}${isBlocked ? '--blocked' : ''}`}
            id={item.article.id}
        >
            {actionsRequiredAndICanDoTheseActions && (
                <div className="Item-RequiredHeader" data-seleniumid="required-header">
                    <SvgIcon.error
                        className="Item-RequiredHeader-Icon"
                        iconTitle="Action Required"
                        seleniumid="required-header-icon"
                    />
                    <span className="Item-RequiredHeader-Text" data-seleniumid="required-header-text">
                        {l('DASHBOARD.ARTICLE_CARD.REQUIRED_ACTION')}
                    </span>
                </div>
            )}

            {showDiscountBanner && (
                <div className="WaiverBlock">
                    <DiscountBanner
                        discountDescription={description}
                        icon="globe"
                        isTransparent={false}
                        title={title}
                    />
                </div>
            )}

            <div className="ArticleBlock" data-seleniumid={`dashboard-article-${article.id}`}>
                {corrAuthor && eligibility && !status.published && (
                    <DashboardEligibilityMessage eligibility={eligibility} />
                )}

                <ArticleDetails
                    isFoundByDoi={isFoundByDoi}
                    item={item}
                    searchWords={searchWords}
                    statusCode={statusCode}
                />
            </div>

            {!isBlocked && !status.withdrawn && (
                <div className="Item-Actions" data-seleniumid="article-actions">
                    {actionsRequiredAndICanDoTheseActions ? (
                        <PrimaryButton
                            className="Required"
                            data-seleniumid="manage-article-required"
                            onClick={() => routes.navigateToUrl(`article/${article.id}`)}
                        >
                            {l('DASHBOARD.ARTICLE_CARD.MANAGE_ARTICLE_REQUIRED')}
                        </PrimaryButton>
                    ) : (
                        <Button
                            data-seleniumid="manage-article"
                            onClick={() => routes.navigateToUrl(`article/${article.id}`)}
                        >
                            {l('DASHBOARD.ARTICLE_CARD.MANAGE_ARTICLE')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

export default withCodes(DashboardItems, ID.DASHBOARD);
