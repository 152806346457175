import { getUrlParam } from 'app/blocks/middleware/url-params';
import { REST } from './utils';

const { GET, POST } = REST('/user');

export const sendResetCode = (email: string): Promise<void> =>
    POST('/password/sendResetCode/', { primaryEmailAddr: email, returnUrl: getUrlParam('returnUrl') });

export const verifyResetLink = (uuid: string): Promise<{ emailId: string }> =>
    GET(`/password/verifyResetCode/${uuid}/`);

export const resetUsingCode = (email: string, password: string, uuid: string): Promise<{ emailId: string }> =>
    POST(`/password/resetUsingCode/${uuid}/`, {
        emailId: email,
        password,
    });
