import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './AuthorRolesWidget.scss';

function AuthorRolesWidget({ className, l, style }) {
    return (
        <div className={cn('AuthorRolesWidget', className)} style={style}>
            <div>
                <SvgIcon.edit iconTitle="Edit Icon" />
                <div>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_CORR_AUTHOR.SELECTION_WILL_BE_REFLECTED')}</div>
            </div>
            <div>
                <SvgIcon.error iconTitle="Warning Icon" />
                <div>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_CORR_AUTHOR.SELECTION_CAN_NOT_BE_CHANGED')}</div>
            </div>
        </div>
    );
}

export default withCodes2(ID.LICENSE_SIGNING)(AuthorRolesWidget);
