import _ from 'underscore';
import { ValidationError } from 'app/blocks/common/utils-errortypes';
import validation from 'app/blocks/common/validation';
import PostalCodeValidationData from '../../../../../../../static/codes/postal-code-formats.json';
import OOStateMandatoryCountriesData from '../../../../../../../static/codes/state-mandatory-countries.json';

export const MESSAGE_EMPTY_ADDRESS = {
    addressLine1: 'Please enter street address',
    city: 'Please enter city',
    country: 'Please select country',
    firstName: 'Enter first name',
    fullName: 'Enter full name',
    institution: 'Please select institution',
    lastName: 'Enter last name',
    phoneNumber: 'Please enter phone number',
    primaryEmail: 'Enter email name',
    state: 'Please enter state',
    zipCode: 'Please enter zip code',
};
export const ERROR_INVALID_ADDRESS = {
    addressLine1: { code: 'PROFILE_ACCNT_ADRESS_INVALID_DATA_ERR_TEXT', message: 'Address is invalid' },
    addressLine2: { code: 'PROFILE_ACCNT_ADRESS_INVALID_DATA_ERR_TEXT', message: 'Address is invalid' },
    city: { code: 'PROFILE_ACCNT_CITY_INVALID_ERR_TEXT', message: 'City is invalid' },
    country: { code: 'PROFILE_ACCNT_COUNTRY_INVALID_ERR_TEXT', message: 'Country is invalid' },
    departmentName: { code: 'PROFILE_ACCNT_DEPT_INVALID_DATA_ERR_TEXT', message: 'Department is invalid' },
    firstName: { code: 'PROFILE_ACCNT_FIRST_NAME_INVALID_DATA_ERR_TEXT', message: 'First name is invalid' },
    fullName: { code: 'PROFILE_ACCNT_FULL_NAME_INVALID_DATA_ERR_TEXT', message: 'Full name is invalid' },
    institution: { code: 'PROFILE_ACCNT_INST_INVALID_DATA_ERR_TEXT', message: 'Institution is invalid' },
    lastName: { code: 'PROFILE_ACCNT_LAST_NAME_INVALID_DATA_ERR_TEXT', message: 'Last name is invalid' },
    phoneNumber: {
        code: 'PROFILE_ACCNT_PHONE_NUM_INVALID_ERR_TEXT',
        message: 'Phone number is invalid',
    },
    primaryEmail: { code: 'EMAIL_IS_INVALID', message: 'Email has invalid format' },
    state: { code: 'PROFILE_ACCNT_STATE_INVALID_ERR_TEXT', message: 'State is invalid' },
    zipCode: { code: 'PROFILE_ACCNT_ZIP_CODE_DATA_ERR_TEXT', message: 'Postal code is invalid' },
};
export const SELECTBOX_TO_VALIDATE = {
    country: { empty: 'countryCode', invalid: 'countryName' },
    institution: { empty: 'institutionName', invalid: 'institutionName' },
    state: { empty: 'stateCode', invalid: 'stateName' },
};
export const SELECTBOX_EMPTY_VALIDATION_TYPE = 'empty';
export const SELECTBOX_INVALID_VALIDATION_TYPE = 'invalid';

export function getFieldNameToValidate(field, validationType) {
    return (SELECTBOX_TO_VALIDATE[field] && SELECTBOX_TO_VALIDATE[field][validationType]) || field;
}

export const rule = {
    address: {
        every: {
            required: [
                'fullName',
                'institution',
                'addressLine1',
                'city',
                'country',
                'state',
                'zipCode',
                'phoneNumber',
                'primaryEmail',
            ],
            validateToHaveOnlyLatinCharacters: [
                'institution',
                'departmentName',
                'addressLine1',
                'addressLine2',
                'city',
                'zipCode',
                'country',
            ],
            validateUserNameToHaveOnlyLatinCharacters: ['fullName'],
        },
        onlineOpen: {
            required: [
                'firstName',
                'lastName',
                'institution',
                'addressLine1',
                'city',
                'country',
                'state',
                'zipCode',
                'phoneNumber',
                'primaryEmail',
            ],
            validateToHaveOnlyLatinCharacters: [
                'institution',
                'departmentName',
                'addressLine1',
                'addressLine2',
                'city',
                'zipCode',
                'country',
            ],
            validateUserNameToHaveOnlyLatinCharacters: ['firstName', 'lastName'],
        },
    },
};

const OOStateMandatoryCountries = OOStateMandatoryCountriesData.map(country => country['Country Key']);
const OOPostalCodeHelpTextRequiredCountries = PostalCodeValidationData.map(country => country.countryCode);

function isStateRequired(countryCode, isOpenAccessOrder = false) {
    return isOpenAccessOrder
        ? ['US', 'CA', 'GB', 'AU', 'IN'].includes(countryCode)
        : OOStateMandatoryCountries.includes(countryCode);
}

function isPostalCodeHelpTextRequired(countryCode) {
    return OOPostalCodeHelpTextRequiredCountries.includes(countryCode);
}

function validateUserNameToHaveOnlyLatinCharacters(value, result) {
    return !validation.validateInputConstrainedLatinExtendedChars(value) && result;
}

function validatePostalCode(countryCode, value, result) {
    return (
        !validation.validatePostalCode(
            value,
            PostalCodeValidationData.find(item => item.countryCode === countryCode)?.regex,
        ) && result
    );
}

function validateToHaveOnlyLatinCharacters(value, result) {
    return !validation.validateInputFullLatinExtendedChars(value) && result;
}

function validateOnNumbers(value, result) {
    return !validation.isPhoneNumberValid(value) && result;
}

function validateEmail(item, result) {
    return !validation.isEmailValid(item) && result;
}

export function validateAddress(address, isOnlineOpenOrder) {
    if (!address) {
        throw new Error('address should be defined');
    }
    const validationResult = {};
    const rules = isOnlineOpenOrder ? rule.address.onlineOpen : rule.address.every;

    if (rules) {
        rules.required.forEach(field => {
            const fieldName = getFieldNameToValidate(field, SELECTBOX_EMPTY_VALIDATION_TYPE);
            validationResult[field] = validation.validateRequiredField(
                address[fieldName],
                MESSAGE_EMPTY_ADDRESS[field],
            );
            if (!isStateRequired(address.countryCode, !isOnlineOpenOrder)) {
                validationResult.state = false;
            }
        });
        rules.validateUserNameToHaveOnlyLatinCharacters.forEach(field => {
            validationResult[field] =
                validationResult[field] ||
                validateUserNameToHaveOnlyLatinCharacters(address[field], ERROR_INVALID_ADDRESS[field]);
        });
        rules.validateToHaveOnlyLatinCharacters.forEach(field => {
            const fieldName = getFieldNameToValidate(field, SELECTBOX_INVALID_VALIDATION_TYPE);
            if (!_.isUndefined(address[fieldName])) {
                validationResult[field] =
                    validationResult[field] ||
                    validateToHaveOnlyLatinCharacters(address[fieldName], ERROR_INVALID_ADDRESS[field]);
            }
        });
    }

    validationResult.phoneNumber =
        validationResult.phoneNumber || validateOnNumbers(address.phoneNumber, ERROR_INVALID_ADDRESS.phoneNumber);
    validationResult.primaryEmail =
        validationResult.primaryEmail || validateEmail(address.primaryEmail, ERROR_INVALID_ADDRESS.primaryEmail);
    validationResult.zipCode =
        validationResult.zipCode ||
        validatePostalCode(address.countryCode, address.zipCode, ERROR_INVALID_ADDRESS.zipCode);

    return validationResult;
}

function validateAddresses(state, isOnlineOpenOrder) {
    const invalid = {
        mainAddress: validation.asFalseIfAllObjPropsEmpty(validateAddress(state.mainAddress, isOnlineOpenOrder)),
    };

    return validation.asFalseIfAllObjPropsEmpty(invalid);
}

export { isStateRequired, isPostalCodeHelpTextRequired };
export default (state, isOnlineOpenOrder) => {
    const validationResult = validateAddresses(state, isOnlineOpenOrder);
    if (validationResult) {
        // FIXME after oa controller is removed
        throw Object.assign(new ValidationError('Address validation error', validationResult), validationResult);
    }
};
