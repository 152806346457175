import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import get from 'lodash.get';
import React from 'react';
import _ from 'underscore';
import { Layout } from 'app/blocks/blocks';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { compose, getQueryParamSafe, looseEqual } from 'app/blocks/common/utils';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import withAuth from 'app/blocks/common/withAuth';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import { NONE_VALUE } from 'app/blocks/institution-picker/institution-picker-view';
import { getDiscounts } from 'app/blocks/middleware/discount';
import * as middleware from 'app/blocks/middleware/middleware';
import LicenseSigningProcessContext from 'app/pages/license-signing-process/context/Context';
import withContext from 'app/pages/license-signing-process/context/withContext';
import { GOAOrder } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';
import PaymentStepEnum from 'app/pages/license-signing-process/Payment/PaymentStepEnum';
import { suggestAddresses } from 'app/pages/license-signing-process/Payment/utils';
// import StepEnum from 'app/pages/license-signing-process/StepEnum';
import payment from 'app/pages/orders/orders.payment';
import { notifyUserThatOrderIsSaved, notifyUserThatVatTaxShouldBeReentered } from 'app/pages/orders/orders.utils';
import { isStateRequired } from 'app/pages/orders/panel/contact/validation';
import routes from 'app/pages/routes';
import tempStorage from 'app/route/tempStorage';

function resetInititateState(initiate, order) {
    return update(initiate, {
        [PaymentPanelEnum.DISCOUNT]: { discountDetails: { $set: { ...order?.discountDetails } } },
        // eslint-disable-next-line func-names
        [PaymentPanelEnum.CONTACT]: (function () {
            const addresses = {
                mainAddress: order?.billingAddress,
            };

            return _.object(
                Object.keys(addresses),
                Object.values(addresses).map(value => ({ $set: value })),
            );
        })(),
        [PaymentPanelEnum.VAT_TAX]: { vatTaxDetails: { $set: { ...order.vatTaxDetails } } },
        statusPopupError: { $set: undefined },
    });
}

const getOrderWithUpdates = state => {
    return {
        ...state.order,
        billingAddress: get(state, 'initiate.addresses.mainAddress'),
        discountDetails: get(state, 'initiate.discount.discountDetails'),
        vatTaxDetails: get(state, 'initiate.vatTax.vatTaxDetails'),
    };
};

interface Address {
    countryCode: string;
}

type GOAState = {
    initiate: {
        addresses?: { mainAddress: Address };
        // @ts-ignore
        [PaymentPanelEnum.DISCOUNT]: Object;
        // @ts-ignore
        [PaymentPanelEnum.CONTACT]: Object;
        // @ts-ignore
        [PaymentPanelEnum.VAT_TAX]: Object;
        statusPopupError?: Object;
    };
    isLoading: boolean;
    isProcessing: boolean;
    isOrderFromNonSanctionCountry: boolean;
    order?: GOAOrder;
    previousBillingCountry?: Object;
    prices: [];
    review?: Object;
    socopayEnabled?: boolean;
    journalDiscounts?: {
        discounts?: object[];
        success: boolean;
    };
};

type DiscountAndBillingContextProps = {
    article: {
        id: string;
    };
    confirmDiscountAndBilling: (order: Object) => {};
    isOrderExisting: boolean;
    journal: {
        journalUuid: string;
    };
    location?: { href: string };
    order: GOAOrder;
    reload: () => {};
    setChangesChecker: (callback?: Function) => void;
    substep?: string;
    unsetChangesChecker: (callback?: Function) => void;
    confirmStep: (step: string, options: {}) => void;
    l: (key: string, params?: string) => string;
    isEditOrderFlow: boolean;
};

interface Panel {
    panelName: string;
    scrollToPanel: () => {};
    validate: () => {};
}

const Context = React.createContext({});

function withProvider(WrappedComponent) {
    @autobind
    class DiscountAndBillingContext extends React.PureComponent<DiscountAndBillingContextProps> {
        private initialOrder: GOAOrder;

        private panels: Panel[];

        static defaultProps = {
            location: window.location,
            substep: PaymentStepEnum.INITIATE,
        };

        state: GOAState = {
            initiate: {
                [PaymentPanelEnum.DISCOUNT]: {},
                [PaymentPanelEnum.CONTACT]: {},
                [PaymentPanelEnum.VAT_TAX]: {},
            },
            isLoading: false,
            isOrderFromNonSanctionCountry: true,
            isProcessing: true,
            prices: [],
            review: {},
        };

        @tryCatch.showPopUpAndGoToDashboard
        @processing
        async componentDidMount() {
            const params = getQueryParamSafe(this.props?.location?.href);
            const panel = tempStorage.get();

            if (params?.code) {
                showDialog.error({
                    // @ts-ignore
                    code: params.code,
                    message: params.message && decodeURIComponent(params.message.replace(/\+/g, '%20')),
                    refId: params.refId,
                });
            }
            const { journal, order } = this.props;
            const journalUuid = journal?.journalUuid;

            const [emailDetails, socopayEnabled, journalDiscounts] = await Promise.all([
                middleware.profile.getEmailDetails(),
                middleware.payment.getSocopayEnabled(),
                this.getDiscounts(journalUuid),
            ]);

            this.setState(
                // @ts-ignore
                (state: GOAState) => ({
                    emailDetails,
                    initOrder: { ...order },
                    initiate: resetInititateState(state.initiate, order),
                    journalDiscounts,
                    order,
                    socopayEnabled,
                }),
                () => {
                    this.initialOrder = getOrderWithUpdates(this.state);
                },
            );

            this.panels = [];

            this.props.setChangesChecker(() => !looseEqual(this.initialOrder, this.getOrderWithUpdates()));

            if (panel) {
                setTimeout(() => this.scrollTo(panel), 300);
            }

            if (params.scrollTo) {
                setTimeout(() => this.scrollTo(params.scrollTo), 300);
            }
        }

        async getDiscounts(journalUuid: string): Promise<{ discounts?: object[]; success: boolean }> {
            try {
                const discounts = await getDiscounts(journalUuid);
                return { discounts, success: true };
            } catch (e) {
                return { success: false };
            }
        }

        async componentDidUpdate(prevProps, prevState) {
            const countryCodePath = 'initiate.addresses.mainAddress.countryCode';
            const oldBillingCountry = get(prevState, countryCodePath);
            const newBillingCountry = get(this.state, countryCodePath);
            this.setState({ previousBillingCountry: oldBillingCountry });

            if (oldBillingCountry && oldBillingCountry !== newBillingCountry) {
                if (oldBillingCountry && this.isVatTaxNonEmpty()) {
                    notifyUserThatVatTaxShouldBeReentered();
                    this.setVatTaxState({ vatTaxDetails: {} }, () => {});
                }
                this.setState(state => ({ ...state }));
            }

            // fixme
            if (this.props.substep !== prevProps.substep) {
                this.props.setChangesChecker(() => !looseEqual(this.initialOrder, this.getOrderWithUpdates()));
            }
        }

        setInitiateState(patch, callback = () => {}) {
            this.setState(
                (state: Readonly<GOAState>) => ({ initiate: { ...state.initiate, ...patch(state.initiate) } }),
                callback,
            );
        }

        isVatTaxNonEmpty() {
            return Object.values(get(this.state, 'initiate.vatTax.vatTaxDetails', {})).some(x => !!x);
        }

        isWaiverApplied() {
            return (
                get(this.state, 'order').prices[0]?.appliedDiscounts.filter(
                    ({ percentageDiscount }) => percentageDiscount === 100,
                ).length > 0
            );
        }

        shouldRemovePaymentMethod(newBillingCountry) {
            return (
                get(this.state, 'order.paymentDetails.paymentMethod') === payment.method.SOCOPAY.key &&
                (!this.state.socopayEnabled || newBillingCountry !== 'CN')
            );
        }

        // initiate things
        registerPanel(panel) {
            this.panels.push(panel);
        }

        unregisterPanel(panel) {
            this.panels = (this.panels || []).filter(x => x.panelName === panel.panelName);
        }

        scrollTo(type) {
            this.panels.find(panel => panel.panelName === type).scrollToPanel();
        }

        updateValidationResults(validationResults) {
            this.setState((state: Readonly<GOAState>) => ({
                initiate: {
                    ...state.initiate,
                    addresses: { ...state?.initiate?.addresses, validationResults },
                },
            }));
        }

        updateAddress(mainAddress) {
            this.setState((state: Readonly<GOAState>) => ({
                initiate: {
                    ...state.initiate,
                    addresses: { ...state[PaymentPanelEnum.CONTACT], ...{ mainAddress } },
                },
            }));
        }

        setVatTaxState(patch, callback) {
            this.setInitiateState(
                (state: Readonly<GOAState>) => ({ vatTax: { ...state[PaymentPanelEnum.VAT_TAX], ...patch } }),
                callback,
            );
        }

        isSaveAndPreviewAllowed() {
            return [
                get(this.state, 'initiate.addresses.mainAddress.firstName'),
                get(this.state, 'initiate.addresses.mainAddress.lastName'),
                get(this.state, 'initiate.addresses.mainAddress.institutionName') === NONE_VALUE ||
                    !!get(this.state, 'initiate.addresses.mainAddress.institutionName'),
                get(this.state, 'initiate.addresses.mainAddress.addressLine1'),
                get(this.state, 'initiate.addresses.mainAddress.city'),
                get(this.state, 'initiate.addresses.mainAddress.countryCode'),
                isStateRequired(get(this.state, 'initiate.addresses.mainAddress.countryCode'))
                    ? get(this.state, 'initiate.addresses.mainAddress.stateCode')
                    : true,
                get(this.state, 'initiate.addresses.mainAddress.zipCode'),
                get(this.state, 'initiate.addresses.mainAddress.phoneNumber'),
                get(this.state, 'initiate.addresses.mainAddress.primaryEmail'),
                get(this.state, 'isOrderFromNonSanctionCountry'),
            ].every(x => !!x);
        }

        getOrderWithUpdates() {
            return {
                ...this.state.order,
                billingAddress: get(this.state, 'initiate.addresses.mainAddress'),
                vatTaxDetails: get(this.state, 'initiate.vatTax.vatTaxDetails'),
            };
        }

        createWarningPopupMessage(key, payload = null) {
            const { l } = this.props;
            if (payload) {
                return new ServiceError(l(key, payload), null, null, null);
            }

            return new ServiceError(l(key), null, null, null);
        }

        async showSanctionedBillingAttemptsPopup(remainingSanctionedBillingAttempts) {
            if (parseInt(remainingSanctionedBillingAttempts, 10) > 0) {
                const key = 'ORDER_PANELS.SANCTION_COUNTRY_WARNING.SUBSCRIPTION_TO_OPEN_LIMIT_NOT_EXCEEDED';
                const payload = {
                    remainingAttemptValue: remainingSanctionedBillingAttempts,
                };
                await showDialog.addErrorMessageDialog(this.createWarningPopupMessage(key, payload));
            } else {
                const key = 'ORDER_PANELS.SANCTION_COUNTRY_WARNING.SUBSCRIPTION_TO_OPEN_LIMIT_EXCEEDED';
                await showDialog.addErrorMessageDialog(
                    this.createWarningPopupMessage(key),
                    this.props.confirmDiscountAndBilling,
                );
            }
        }

        async validateAndGetOrderWithUpdates() {
            await Promise.all((this.panels || []).map(it => it.validate()));

            const addresses = await suggestAddresses(
                {
                    mainAddress: this.state?.order?.billingAddress,
                },
                {
                    mainAddress: this.state?.initiate?.addresses?.mainAddress,
                },
            );

            const newBillingCountry = get(addresses, 'mainAddress.countryCode');

            const { previousBillingCountry } = this.state;

            if (this.shouldRemovePaymentMethod(newBillingCountry)) {
                delete this.state.order.paymentDetails.paymentMethod;
            }

            const isBillingCountryChangedAndVatTaxShouldBeReentered =
                previousBillingCountry !== newBillingCountry && this.isVatTaxNonEmpty();

            this.updateAddress(addresses?.mainAddress);

            if (isBillingCountryChangedAndVatTaxShouldBeReentered) {
                throw new Error('VatTaxShouldBeReentered');
            }

            return {
                ...this.state.order,
                billingAddress: addresses.mainAddress,
                vatTaxDetails: get(this.state, 'initiate.vatTax.vatTaxDetails'),
            };
        }

        closeStatusPopup() {
            this.setInitiateState(() => ({ statusPopupError: undefined }));
        }

        @tryCatch.popUpOnly
        @processing('initiate.isSavingForLater')
        async saveForLater() {
            const order = this.getOrderWithUpdates();
            await middleware.onlineopen.saveForLater(order);
            this.setState({ order });
            await notifyUserThatOrderIsSaved('OPEN_ACCESS', 'initiate');

            this.props.unsetChangesChecker();

            routes.navigateToUrl(`article/${this.state.order.article.id}`);
        }

        @tryCatch.popUpOnly
        @processing('initiate.isConfirming')
        async onInitiateConfirm() {
            try {
                const { article, isEditOrderFlow, l } = this.props;

                const {
                    remainingSanctionedBillingAttempts,
                    sanctionCountryWithNewOrder,
                    sanctionCountryWithOOOrderEdit,
                    sanctionCountryWithSubscriptionLicense,
                } = await middleware.orders.checkBillingCountryAndOrderStatus(
                    this.state?.initiate?.addresses?.mainAddress?.countryCode,
                    isEditOrderFlow,
                    article?.id,
                );

                if (sanctionCountryWithOOOrderEdit) {
                    const key = 'ORDER_PANELS.SANCTION_COUNTRY_WARNING.HOA_ORDER_EDIT_MESSAGE';
                    await showDialog.addErrorMessageDialog(this.createWarningPopupMessage(key));
                } else if (sanctionCountryWithSubscriptionLicense) {
                    this.showSanctionedBillingAttemptsPopup(remainingSanctionedBillingAttempts);
                } else if (sanctionCountryWithNewOrder && !this.isWaiverApplied()) {
                    await showDialog.confirmation({
                        cancelBtnLabel: l('BUTTONS.CANCEL'),
                        confirmBtnLabel: l('BUTTONS.CONTINUE'),
                        message: l('ORDER_PANELS.SANCTION_COUNTRY_WARNING.GOA_MESSAGE'),
                    });
                } else {
                    const order = await this.validateAndGetOrderWithUpdates();
                    await new Promise<void>(resolve => {
                        this.setState(
                            (state: Readonly<GOAState>) => ({
                                initiate: resetInititateState(state.initiate, order),
                                order,
                            }),
                            async () => {
                                this.initialOrder = this.getOrderWithUpdates();
                                const { billingAddress, vatTaxDetails } = order;
                                await this.props.confirmDiscountAndBilling({ billingAddress, vatTaxDetails });
                                resolve();
                            },
                        );
                    });
                }
            } catch (error) {
                // instanceof does not work
                if (error.name === 'ValidationError') {
                    this.setInitiateState(() => ({
                        statusPopupError: new Error(
                            'There was an error with your submission. Please scroll down to review',
                        ),
                    }));
                } else if (error.message === 'VatTaxShouldBeReentered') {
                    this.scrollTo(PaymentPanelEnum.VAT_TAX);
                } else {
                    throw error;
                }
            }
        }

        render() {
            if (this.state.isProcessing) {
                return <Layout isLoading />;
            }

            return (
                <Context.Provider
                    value={{
                        ...this.state,
                        article: this.props.article,
                        closeStatusPopup: this.closeStatusPopup,
                        initiate: {
                            ...this.state.initiate,
                            isSaveAndPreviewAllowed: this.isSaveAndPreviewAllowed(),
                        },
                        isLoading: this.state.isLoading,
                        isOrderExisting: this.props.isOrderExisting,
                        isWaiverApplied: this.isWaiverApplied(),
                        journal: this.props.journal,
                        journalDiscounts: this.state.journalDiscounts,
                        l: this.props.l,
                        onInitiateConfirm: this.onInitiateConfirm,
                        order: this.props.order,
                        orderWithUpdates: this.getOrderWithUpdates(),
                        registerPanel: this.registerPanel,
                        saveForLater: this.saveForLater,
                        setVatTaxState: this.setVatTaxState,
                        unregisterPanel: this.unregisterPanel,
                        updateAddress: this.updateAddress,
                        updateValidationResults: this.updateValidationResults,
                    }}
                >
                    <WrappedComponent {...this.props} />
                </Context.Provider>
            );
        }
    }

    return DiscountAndBillingContext;
}

export { withProvider };
export default {
    withContext: withContext(Context),
    withProvider: Component =>
        compose(
            LicenseSigningProcessContext.withContext(state => ({
                article: state.article,
                confirmDiscountAndBilling: order => state.confirmDiscountAndBilling(order, true),
                confirmStep: state.confirmStep,
                isEditOrderFlow: get(state, 'all.discountAndBillingDetails.isEditOrderFlow', false),
                isOrderExisting: state.all.onlineOpenPayment.isEditOrderFlow,
                journal: state.journal,
                l: state.l,
                onConfirmSubstep: state.onConfirmSubstep,
                order: state.all?.discountAndBillingDetails?.order,
                reload: state.reload,
            })),
            withConfirmLeaving,
            withAuth,
            withProvider,
        )(Component),
};
