export enum GrantFundingValue {
    HaveFunds = 'HaveFunds',
    DoNotHaveFunds = 'DoNotHaveFunds',
    Reset = 'Reset',
    NotAnsweredYet = 'NotAnsweredYet',
}

export const OTHER = 'OTHER';

export type NoOption = {
    label: string;
    optionCode: string;
    sortOrder: number;
};

export type GrantFunding = {
    answerState?: GrantFundingValue;
    reasonCode?: string;
    reasonText?: string;
};
