import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ArticleAffiliationsMiddleware from 'app/blocks/article-affiliations-editor/middleware';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { markAsChanged, markAsUnchanged, processing, tryCatch } from 'app/blocks/common/decorators';
import PageEnum from 'app/blocks/common/PageEnum';
import { Handlers, compose, cloneDeepSimple } from 'app/blocks/common/utils';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import * as middlewareLicense from 'app/blocks/middleware/license';
import * as Middleware from 'app/blocks/middleware/middleware';
import * as middlewareProfile from 'app/blocks/middleware/profile';
import { StatusPopupTypeEnum } from 'app/blocks/StatusPopup/StatusPopup';
import AffiliationsStepEnum from './AffiliationsStepEnum';
import ArticleAffiliationsEditorView from './article-affiliations-editor-view';
import { confirmAffiliationDeletion, isEmailSuggestion, prepareAffiliationsForEditor } from './utils';

const HIDE_POPUP_TIMEOUT = 10000;

function mapPickerItemToInstitution(val) {
    return {
        ...val,
        custom: val.custom || false,
        institutionId: val.id || val.institutionId,
        institutionName: val.name || val.institutionName,
    };
}

type Props = Pick<GetProps<typeof ArticleAffiliationsEditorView>, 'renderButtons' | 'zeroPriceOrderExists'> & {
    afterCancelHandler?: (affiliation: ArticleAffiliation[]) => void;
    afterSaveHandler?: (affiliation: ArticleAffiliation[], country: string) => void;
    article: Product.Article;
    articleAffiliations: any;
    articleId: string;
    disabled?: boolean;
    journal: Product.Journal;
    l: l;
    setChangesChecker: (fn: () => boolean) => void;
    onConfirmSubstep: (isStepConfirmed: boolean) => void;
    onLoadSubstep: (subStep?: string, page?: string, error?) => void;
    updateContext: () => void;
    countryName: string;
    countryDropdownVisible: boolean;
};

type InheritState = Pick<
    GetProps<typeof ArticleAffiliationsEditorView>,
    | 'affiliations'
    | 'article'
    | 'geoTargetedMessage'
    | 'institutionsIdFromProfile'
    | 'isSaving'
    | 'journal'
    | 'newAffiliation'
    | 'noAffiliations'
    | 'noGeoWaiver'
    | 'selectedPrimaryAffiliationId'
    | 'statusPopup'
>;

type State = InheritState & {
    confirmed: boolean;
    emailSuggestionsResolved: any[];
    initialAffiliations: any[];
    isLoading: boolean;
    isLoadingError?: Error;
    needWoaConfirmation: boolean;
    notAffiliated: boolean;
    selectedAffiliationByCountry?: Object;
    isPriceProposalCountry: boolean;
    subStep: Values<typeof AffiliationsStepEnum>;
    countryName: string;
};

const pages = {
    [AffiliationsStepEnum.SELECT_PRIMARY_AFFILIATION]: PageEnum.AFFILIATIONS_SELECT_PRIMARY,
    [AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY]: PageEnum.AFFILIATIONS_SELECT_WOA_INSTITUTION,
};

const handlers = new Handlers();

@autobind
class ArticleAffiliationsEditor extends Component<Props, State> {
    // eslint-disable-next-line react/no-unused-class-component-methods
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    _ = handlers.link(this, 'onLoad');

    static defaultProps = {
        disabled: false,
    };

    static normalize(affiliations) {
        return affiliations.map(({ confirmed = false, custom = false, ...x }) => ({ ...x, confirmed, custom }));
    }

    state: State = {
        affiliations: [],
        article: null,
        confirmed: false,
        countryName: this.props.countryName,
        emailSuggestionsResolved: [],
        initialAffiliations: [],
        institutionsIdFromProfile: [],
        isLoading: true,
        isPriceProposalCountry: true,
        isSaving: false,
        journal: null,
        needWoaConfirmation: false,
        newAffiliation: false,
        noAffiliations: false,
        noGeoWaiver: false,
        notAffiliated: false,
        subStep: AffiliationsStepEnum.AFFILIATION_LIST,
    };

    isChanged = false;

    popupTimer;

    // see https://jira.wiley.com/browse/AS-15471 for this strange logic details - it prevents 'blinking'
    // flag 'hideEligibilityMessage' is used in ./article-affiliations-panel/Institutions/ConfirmedInstitution/ConfirmedInstitution.tsx
    hideNewEligibility(affiliations = []) {
        const { initialAffiliations = [] } = this.state;

        for (const newAffiliation of affiliations) {
            if (newAffiliation.eligibleForOO) {
                const oldAffiliation = initialAffiliations.find(
                    old => old.affiliationId === newAffiliation.affiliationId,
                );
                if (!(oldAffiliation && oldAffiliation.eligibleForOO)) {
                    newAffiliation.hideEligibilityMessage = true;
                }
            }
        }

        return affiliations;
    }

    getIndex(id) {
        return this.state.affiliations.findIndex(aff => aff.id === id);
    }

    isDisabled() {
        return this.state.isLoading || this.state.isSaving || this.props.disabled;
    }

    isEmptyAffiliations() {
        return this.state.affiliations.length === 0;
    }

    onPriceProposalCountryChange(value: boolean) {
        this.setState({ isPriceProposalCountry: value });
    }

    onCountryChange(basedCountryName) {
        this.setState({ countryName: basedCountryName });
    }

    canConfirm() {
        if (this.state.subStep === AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY) {
            return this.state.selectedAffiliationByCountry !== null || this.state.notAffiliated;
        }
        if (this.state.subStep === AffiliationsStepEnum.SELECT_PRIMARY_AFFILIATION) {
            return !!this.state.selectedPrimaryAffiliationId;
        }

        const unconfirmedAffiliations = (this.state.affiliations || []).filter(x => !x.confirmed);

        let isFilled: boolean = false;

        if (this.state.journal.revenueModel === 'OA' && this.props.countryDropdownVisible) {
            isFilled =
                (this.state.noAffiliations || !this.isEmptyAffiliations()) &&
                (this.state.noGeoWaiver || this.state.isPriceProposalCountry);
            return !this.isDisabled() && isFilled && unconfirmedAffiliations.length === 0 && !!this.state.countryName;
        }
        isFilled = this.state.noAffiliations || !this.isEmptyAffiliations();
        return !this.isDisabled() && isFilled && unconfirmedAffiliations.length === 0;
    }

    getNormalizedAffilitaions(affiliations) {
        return ArticleAffiliationsEditor.normalize(this.removeEmailSuggestions(affiliations || []));
    }

    getBlackList = affiliations => (affiliations || []).filter(x => !!x.institutionId).map(x => x.institutionId);

    // eslint-disable-next-line react/no-unused-class-component-methods
    onLoad(error?) {
        const { subStep } = this.state;
        const { onLoadSubstep } = this.props;

        if (!!subStep && typeof onLoadSubstep === 'function') {
            const page = pages[subStep] ?? PageEnum.AFFILIATIONS;
            onLoadSubstep(subStep, page, error);
        }

        if (error) {
            throw error;
        }
    }

    @markAsUnchanged
    @processing('isLoading')
    @tryCatch.showPopUpAndGoToDashboard
    // @ts-ignore
    @tryCatch({ errorHandler: e => handlers.onLoad(e) })
    async reload() {
        const promiseResults = await Promise.all([
            this.loadArticleAndJournal(),
            this.loadAffiliations(),
            middlewareProfile.getAffiliations(),
        ]);

        const [
            { article, journal },
            { affiliations, geoTargetedMessage, latestChangeByUser, needWoaConfirmation, suggestions },
            profileAffiliations,
        ] = promiseResults;

        const preparedAffiliations = prepareAffiliationsForEditor(affiliations, suggestions, latestChangeByUser);

        this.setState({
            affiliations: preparedAffiliations,
            article,

            geoTargetedMessage: !latestChangeByUser && geoTargetedMessage ? geoTargetedMessage.html : null,
            initialAffiliations: cloneDeepSimple(preparedAffiliations),
            institutionsIdFromProfile: profileAffiliations.map(aff => aff.institutionId).filter(instId => instId),
            journal,
            needWoaConfirmation,
            noAffiliations: latestChangeByUser && affiliations.length === 0,
        });

        this.props.setChangesChecker(() => this.isChanged);
        this.onLoad();
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps, prevState) {
        const { subStep } = this.state;
        if (subStep !== prevState.subStep) {
            this.onLoad();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.popupTimer);
    }

    loadArticleAndJournal = () => {
        // TODO fix after standalone affiliation pages are removed
        const { article, articleId, journal } = this.props;
        if (article && journal) {
            return Promise.resolve({ article, journal });
        }

        return Middleware.product.getArticleAndJournal(articleId);
    };

    loadAffiliations() {
        return ArticleAffiliationsMiddleware.getAffiliations(this.props.articleId);
    }

    removeEmailSuggestions(affiliations) {
        return affiliations.filter(aff => !aff.emailMatched);
    }

    async saveAffiliations(andCheck: boolean = false) {
        const { emailSuggestionsResolved } = this.state;

        const { affiliations, needWoaConfirmation, suggestions } = await ArticleAffiliationsMiddleware.saveAffiliations(
            this.props.articleId,
            {
                affiliations: this.getNormalizedAffilitaions(this.state.affiliations),
                emailSuggestionsResolved,
            },
        );

        if (this.state.journal?.revenueModel === 'OO') {
            await middlewareLicense.getBasePrices(this.props.articleId);
        }
        await this.props.updateContext();

        await new Promise<void>(resolve =>
            this.setState(
                {
                    affiliations: this.hideNewEligibility(
                        prepareAffiliationsForEditor(affiliations, suggestions, true),
                    ),
                    needWoaConfirmation,
                },
                () => resolve(),
            ),
        );
        if (andCheck) {
            await this.checkAffiliationList();
        } else {
            this.props.onConfirmSubstep(false);
        }
    }

    async savePrimaryAffiliation() {
        await ArticleAffiliationsMiddleware.savePrimaryAffiliation(
            this.props.articleId,
            this.state.selectedPrimaryAffiliationId,
        );
    }

    checkAffiliationList = async () => {
        const { afterSaveHandler, articleId, journal, onConfirmSubstep } = this.props;
        const { affiliations, countryName, needWoaConfirmation } = this.state;
        const eligibleAffiliations = affiliations.filter(i => i.eligibleForOO);
        const selectedPrimaryAffiliation = affiliations.filter(i => i.primary);
        const selectedPrimaryAffiliationId = selectedPrimaryAffiliation.length
            ? selectedPrimaryAffiliation[0].affiliationId
            : null;

        if (needWoaConfirmation) {
            onConfirmSubstep(false);
            this.setState({
                notAffiliated: false,
                selectedAffiliationByCountry: null,
                subStep: AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY,
            });
        } else if (eligibleAffiliations.length > 1 && journal.revenueModel !== 'OA') {
            onConfirmSubstep(false);
            this.setState({
                selectedPrimaryAffiliationId,
                subStep: AffiliationsStepEnum.SELECT_PRIMARY_AFFILIATION,
            });
        } else if (afterSaveHandler) {
            await ArticleAffiliationsMiddleware.confirmAffiliations(articleId);
            afterSaveHandler(affiliations, countryName);
        } else {
            onConfirmSubstep(false);
        }
    };

    async onClickSavePrimaryAffiliation() {
        const { afterSaveHandler } = this.props;
        const { affiliations, countryName } = this.state;

        await this.savePrimaryAffiliation();

        if (afterSaveHandler) {
            afterSaveHandler(affiliations, countryName);
        }
    }

    async onClickSaveWOAInstitution() {
        const { affiliations, countryName, selectedAffiliationByCountry: institution } = this.state;
        const { afterSaveHandler, articleId } = this.props;

        if (institution) {
            const affiliation = mapPickerItemToInstitution(institution);
            affiliation.confirmed = true;

            affiliations.push(affiliation);
            await new Promise<void>(resolve => this.setState({ affiliations: [...affiliations] }, () => resolve()));

            await this.saveAffiliations();
            await ArticleAffiliationsMiddleware.confirmAffiliations(articleId);

            this.setState({ subStep: AffiliationsStepEnum.AFFILIATION_LIST });
            this.reload();
        } else {
            await ArticleAffiliationsMiddleware.confirmAffiliations(articleId);
            afterSaveHandler(affiliations, countryName);
        }
    }

    @markAsChanged
    onSelectAffiliationAsPrimary(selectedPrimaryAffiliationId) {
        this.setState({ selectedPrimaryAffiliationId });
    }

    @markAsChanged
    onSelectAffiliationByCountry(institution) {
        this.setState({ selectedAffiliationByCountry: institution });
    }

    @markAsUnchanged
    @processing('isSaving')
    @tryCatch.popUpOnly
    async onClickSave() {
        if (this.state.subStep === AffiliationsStepEnum.AFFILIATION_LIST) {
            await this.saveAffiliations(true);
        } else if (this.state.subStep === AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY) {
            await this.onClickSaveWOAInstitution();
        } else {
            await this.onClickSavePrimaryAffiliation();
        }
    }

    async onClickCancel() {
        const { afterCancelHandler } = this.props;
        const { affiliations, subStep } = this.state;
        if (subStep === AffiliationsStepEnum.AFFILIATION_LIST && afterCancelHandler) {
            afterCancelHandler(affiliations);
        } else if (subStep === AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY) {
            this.setState({
                subStep: AffiliationsStepEnum.AFFILIATION_LIST,
            });
        } else {
            this.setState({ subStep: AffiliationsStepEnum.AFFILIATION_LIST });
            this.reload();
        }
    }

    @markAsChanged
    onNoAffiliationsChange() {
        const { noAffiliations } = this.state;
        const willBeNo = !noAffiliations;

        if (willBeNo) {
            this.setState({ newAffiliation: false });
        }

        this.setState({ noAffiliations: this.isEmptyAffiliations() && willBeNo });
    }

    onNoGeoWaiverChange = () => {
        const { noGeoWaiver } = this.state;
        this.setState({ noGeoWaiver: !noGeoWaiver });
    };

    // this will set 'confirmed', NOT set affiliationId, and 'possibleMatches' will be removed as well
    @markAsChanged
    onChange(id, institution) {
        const affiliation = mapPickerItemToInstitution(institution);
        const { affiliations, confirmed, emailSuggestionsResolved } = this.state;

        let affIndex = this.getIndex(id);
        if (!id) {
            affIndex = affiliations.push(affiliation) - 1;
            this.setState({ newAffiliation: false });
        }

        const prev = affiliations[affIndex];

        affiliation.id = prev.id || uuidv4();
        affiliation.confirmed = true;

        if (affiliation.emailMatched) {
            emailSuggestionsResolved.push(affiliation.emailMatched);
            this.setState({ emailSuggestionsResolved });
            delete affiliation.emailMatched;
        }
        if (id && !prev.confirmed) {
            this.showPopup(
                StatusPopupTypeEnum.INFO,
                this.props.l('ARTICLE_AFFILIATION_EDITOR.POPUP_UPDATE_INFO', {
                    from: prev.institutionName,
                    name: affiliation.institutionName,
                }),
            );
        }

        affiliations[affIndex] = affiliation;

        this.setState({
            affiliations: [...affiliations],
            confirmed,
        });
    }

    @markAsChanged
    async onDelete(id) {
        const { l } = this.props;
        const { affiliations, emailSuggestionsResolved } = this.state;

        const affIndex = this.getIndex(id);
        const affiliation = affiliations[affIndex];
        const name = isEmailSuggestion(affiliation) ? affiliation.name : affiliation.institutionName;
        const emailMatched = !!affiliation.emailMatched;

        const onApprove = () => {
            this.showPopup(StatusPopupTypeEnum.WARN, l('ARTICLE_AFFILIATION_EDITOR.POPUP_DELETE_INFO', { name }));

            this.setState({ affiliations: affiliations.filter(aff => aff.id !== id) });
        };

        if (emailMatched) {
            emailSuggestionsResolved.push(affiliation.emailMatched);
            this.setState({ emailSuggestionsResolved }, onApprove);
        } else if (await confirmAffiliationDeletion(name, affiliation.eligibleForOO)) {
            onApprove();
        }
    }

    @markAsChanged
    onAdd() {
        this.setState({ newAffiliation: true });
    }

    onCancel = () => {
        this.setState({ newAffiliation: false });
    };

    showPopup(type, text) {
        // eslint-disable-next-line sort-keys
        this.setState({ statusPopup: { type, text } });

        clearTimeout(this.popupTimer);
        this.popupTimer = setTimeout(() => this.onCloseStatusPopup(), HIDE_POPUP_TIMEOUT);
    }

    onCloseStatusPopup = () => {
        clearTimeout(this.popupTimer);
        this.setState({ statusPopup: null });
    };

    @markAsChanged
    setNotAffiliated(notAffiliated) {
        this.setState({ notAffiliated });
    }

    render() {
        const { articleAffiliations, countryDropdownVisible, renderButtons, zeroPriceOrderExists } = this.props;
        const {
            affiliations,
            article,
            geoTargetedMessage,
            institutionsIdFromProfile,
            isLoading,
            isLoadingError,
            isSaving,
            journal,
            newAffiliation,
            noAffiliations,
            noGeoWaiver,
            selectedPrimaryAffiliationId,
            statusPopup,
            subStep,
        } = this.state;

        return (
            <Layout isLoading={isLoading}>
                {!isLoading && !isLoadingError && (
                    <ArticleAffiliationsEditorView
                        affiliations={affiliations}
                        article={article}
                        articleAffiliations={articleAffiliations}
                        blacklist={this.getBlackList(affiliations)}
                        canCancel={!this.isDisabled()}
                        canConfirm={this.canConfirm()}
                        countryDropdownVisible={countryDropdownVisible}
                        eligibleAffiliations={affiliations.filter(i => i.eligibleForOO)}
                        geoTargetedMessage={geoTargetedMessage}
                        institutionsIdFromProfile={institutionsIdFromProfile}
                        isDisabled={this.isDisabled()}
                        isEmptyAffiliations={this.isEmptyAffiliations()}
                        isSaving={isSaving}
                        journal={journal}
                        newAffiliation={newAffiliation}
                        noAffiliations={noAffiliations}
                        noGeoWaiver={noGeoWaiver}
                        onAdd={this.onAdd}
                        onCancel={this.onCancel}
                        onChange={this.onChange}
                        onClickCancel={this.onClickCancel}
                        onClickSave={this.onClickSave}
                        onCloseStatusPopup={this.onCloseStatusPopup}
                        onCountryChange={this.onCountryChange}
                        onDelete={this.onDelete}
                        onNoAffiliationsChange={this.onNoAffiliationsChange}
                        onNoGeoWaiverChange={this.onNoGeoWaiverChange}
                        onPriceProposalCountryChange={this.onPriceProposalCountryChange}
                        onSelectAffiliationAsPrimary={this.onSelectAffiliationAsPrimary}
                        onSelectAffiliationByCountry={this.onSelectAffiliationByCountry}
                        renderButtons={renderButtons}
                        selectedPrimaryAffiliationId={selectedPrimaryAffiliationId}
                        setNotAffiliated={this.setNotAffiliated}
                        statusPopup={statusPopup}
                        subStep={subStep}
                        zeroPriceOrderExists={zeroPriceOrderExists}
                    />
                )}
            </Layout>
        );
    }
}

export default compose(
    withConfirmLeaving,
    withCodes2(ID.BUTTONS, ID.ARTICLE_AFFILIATION_EDITOR),
)(ArticleAffiliationsEditor);
