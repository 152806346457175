import React from 'react';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import './DiscountBanner.scss';

export type Props = {
    discountDescription: string;
    title: string;
    isTransparent?: boolean;
    icon?: string;
};

export default function DiscountBanner({ discountDescription, icon, isTransparent, title }: Props): React.ReactElement {
    return (
        <EligibilityMessage
            className="DiscountBanner"
            icon={icon}
            isGray={!isTransparent}
            message={
                <div
                    dangerouslySetInnerHTML={{ __html: discountDescription }}
                    data-seleniumid="discount-message-text"
                />
            }
            title={title}
            withHeader
        />
    );
}

DiscountBanner.defaultProps = {
    icon: 'hand',
    isTransparent: true,
};
