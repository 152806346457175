import cn from 'classnames';
import React, { useState } from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import DiscountsTypeContent from './DiscountsTypeContent';

import './DiscountsTypeSection.scss';

type Props = {
    content: string[];
    'data-seleniumid': string;
    title: string;
};

function DiscountsTypeSection({ content = [], title = '', ...props }: Props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const headerClassName = isExpanding =>
        cn(
            'DiscountsType-Header',
            isExpanding ? 'DiscountsType-Header--expanding' : 'DiscountsType-Header--collapsing',
        );
    return (
        <section className="DiscountsType" {...props}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={headerClassName(isExpanded)} onClick={() => setIsExpanded(!isExpanded)}>
                <SvgIcon.chevron />
                <a target="_blank">{title}</a>
            </div>
            <div className={isExpanded ? 'DiscountsType-Content--expanded' : 'DiscountsType-Content--collapsed'}>
                <DiscountsTypeContent>{content}</DiscountsTypeContent>
            </div>
        </section>
    );
}

export default DiscountsTypeSection;
