import React from 'react';
import { MainTitle } from 'app/blocks/titles/titles';

type Props = {
    title?: string;
    children: React.ReactNode;
    seleniumid?: string;
};

function PageContainer({ children, seleniumid, title }: Props) {
    return (
        <div className="container-lg">
            <div className="col">
                {title && (
                    <MainTitle data-seleniumid={seleniumid} style={{ marginBottom: 0 }}>
                        {title}
                    </MainTitle>
                )}
                {children}
            </div>
        </div>
    );
}

export default PageContainer;
