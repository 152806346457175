import React, { Component } from 'react';
import ASInfo from 'app/blocks/common/as-info';
import { ID, withCodes } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import { sendResetCode } from 'app/blocks/middleware/password';
import { MainTitle } from 'app/blocks/titles/titles';
import EmailPanel from 'app/pages/login/jsx/email-panel';
import ResetPassword from 'app/pages/login/jsx/reset-password';
import LoginUtils from 'app/pages/login/utils/login-utils';
import routes from 'app/pages/routes';
import './recover-password-page.scss';

type Props = {
    l: l;
    uuid?: string;
};

class RecoverPasswordPage extends Component<Props> {
    goToLogin = email => routes.toLogin({ email });

    sendResetLink = async email => {
        const { l } = this.props;

        try {
            Alerts.renderSpinner();

            await sendResetCode(email);

            showDialog.success({
                message: l('RECOVER_PASSWORD.confirmationMessages.PWD_RECOVERY_LINK_HAS_SENT_CONF_TEXT'),
                onClose: () => this.goToLogin(email),
            });
        } catch (error) {
            if (error.code === 'AUTH_FAILURE_NOT_ACTIVATED') {
                showDialog.confirmation(error, {
                    cancelBtnLabel: l('BUTTONS.CANCEL'),
                    confirmBtnLabel: l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_VERIFY_EMAIL_BUTTON_LABEL'),
                    message: l(`RECOVER_PASSWORD.errorMessages.${error.code}`),
                    onApprove: () => LoginUtils.resendEmailRequest(email),
                });
            } else {
                showDialog.error(error, {
                    message: await ContentUtils.getServerErrorMessage(ID.RECOVER_PASSWORD, error),
                });
            }
        } finally {
            Alerts.removeSpinner();
        }
    };

    render() {
        const { l, uuid } = this.props;
        const pageTitle = l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_PASSWORD_REC_LABEL');

        return (
            <div className="RecoveryPassword container-lg">
                <MainTitle className="Header">{pageTitle}</MainTitle>

                {uuid ? (
                    <ResetPassword uuid={uuid} />
                ) : (
                    <div className="RecoveryPasswordContainer">
                        <EmailPanel
                            autofocus
                            buttonCaption={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_SEND_BUTTON_LABEL')}
                            header={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_REQ_PWD_RESET_LABEL')}
                            inputCaption={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_PRIMARY_EMAIL_ADDR_LABEL')}
                            inputPlaceholder={l(
                                'RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_PRIMARY_EMAIL_PLACEHOLDER',
                            )}
                            onSubmit={this.sendResetLink}
                            seleniumid="recover-reset-password"
                        />
                    </div>
                )}
                <ASInfo page={pageTitle} />
            </div>
        );
    }
}

export { RecoverPasswordPage };
export default withCodes(RecoverPasswordPage, ID.RECOVER_PASSWORD, ID.BUTTONS);
