import cn from 'classnames';
import React from 'react';
import { WhiteBlock } from 'app/blocks/blocks';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './page__see-more.scss';

type Props = {
    isLoading?: boolean;
    onClick: () => void;
    'data-seleniumid': string;
    text: string;
};

function PageSeeMore({ 'data-seleniumid': seleniumid, isLoading = false, onClick, text }: Props) {
    return (
        <WhiteBlock
            className={cn('base_vc p_mini text-center text_md base_pointer', {
                'dashboard__see-more_loading': isLoading,
            })}
            data-seleniumid={`${seleniumid}-see-more-link`}
            // @ts-ignore
            onClick={onClick}
        >
            <div>
                <span className="p-r_md">{text}</span>
                <SvgIcon.chevron className="dashboard__see-more-icon" iconDescr="Arrow down" iconTitle="See more" />
            </div>
        </WhiteBlock>
    );
}

export default PageSeeMore;
