import React, { useReducer, useMemo, useEffect } from 'react';
import { l } from 'app/blocks/common/codes';
import { currencySymbols, enrichPrices } from 'app/blocks/common/getCurrencySymbol';
import PageEnum from 'app/blocks/common/PageEnum';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import GrantFunding from 'app/blocks/GrantFunding';

import { PricesVertical } from 'app/blocks/PricesSection';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';

import LicenseSigningProcessContext from 'app/pages/license-signing-process/context/Context';
import { BackAndForthContainer, SectionTitle, SectionPrompt } from 'app/pages/license-signing-process/styles';
import { NoOption, GrantFunding as GrantFundingType, GrantFundingValue, OTHER } from './types';
import './OpenAccessCoverage.scss';

type Price = {
    amount: number;
    base: number;
    currency: keyof typeof currencySymbols;
};

type StepData = {
    prices: Price[];
} & GrantFundingType;

type OpenAccessCoverageProps = {
    confirmStep: (grandFunding: GrantFundingType) => Promise<void>;
    getPrevStep: () => { name: string };
    goBack: () => void;
    isConfirming: boolean;
    onLoadSubstep: (substep, page) => void;
    stepData: StepData;
};

type OpenAccessCoverageState = {
    grantFunding: GrantFundingType;
    canConfirm: boolean;
    otherReason: string;
};

enum OpenAccessCoverageActionType {
    CHANGE_GRANT_FUNDING_VALUE,
    CHANGE_GRANT_FUNDING_NO_REASON,
    CHANGE_GRANT_FUNDING_OTHER,
}

type OpenAccessCoverageAction = {
    type: OpenAccessCoverageActionType;
    payload: any;
};

const openAccessCoverageReducer = (
    state: OpenAccessCoverageState,
    action: OpenAccessCoverageAction,
): OpenAccessCoverageState => {
    let { canConfirm, grantFunding, otherReason } = state;
    switch (action.type) {
        case OpenAccessCoverageActionType.CHANGE_GRANT_FUNDING_VALUE: {
            if (action.payload.code === GrantFundingValue.HaveFunds) {
                grantFunding = { ...grantFunding, answerState: GrantFundingValue.HaveFunds, reasonText: '' };
                canConfirm = true;
            } else {
                grantFunding = { ...grantFunding, answerState: GrantFundingValue.DoNotHaveFunds };
                canConfirm = !!state.grantFunding.reasonText || (grantFunding.reasonCode === OTHER && !!otherReason);
            }
            break;
        }
        case OpenAccessCoverageActionType.CHANGE_GRANT_FUNDING_NO_REASON: {
            grantFunding = {
                ...grantFunding,
                reasonCode: action.payload?.code,
                reasonText: action.payload?.code === OTHER ? otherReason : action.payload?.text,
            };
            canConfirm = action.payload?.code !== OTHER || !!grantFunding.reasonText;
            break;
        }
        case OpenAccessCoverageActionType.CHANGE_GRANT_FUNDING_OTHER: {
            grantFunding = { ...grantFunding, reasonText: action.payload?.text };
            canConfirm = !!action.payload?.text;
            otherReason = action.payload?.text;
            break;
        }
        default: {
            /* empty */
        }
    }
    return { canConfirm, grantFunding, otherReason };
};

const OpenAccessCoverage: React.FC<OpenAccessCoverageProps> = ({
    confirmStep,
    getPrevStep,
    goBack,
    isConfirming,
    onLoadSubstep,
    stepData,
}) => {
    useEffect(() => {
        onLoadSubstep(null, PageEnum.OPEN_ACCESS_COVERAGE);
    }, [onLoadSubstep]);

    const { prices, ...initial } = stepData;
    const [{ canConfirm, grantFunding, otherReason }, dispatch] = useReducer(openAccessCoverageReducer, {
        canConfirm: !!initial.answerState && initial.answerState !== GrantFundingValue.NotAnsweredYet,
        grantFunding: initial,
        otherReason: '',
    });

    const mappedPrices = useMemo(() => {
        return enrichPrices(prices);
    }, [prices]);

    const noOptions = Object.values(
        l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.NO_OPTIONS') || {},
    ) as unknown as NoOption[];
    return (
        <span className="openAccessCoverage">
            <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.PAGE_TITLE')}</SectionTitle>

            <SectionPrompt>{l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.PAGE_SUBTITLE')}</SectionPrompt>

            <PricesVertical
                className="openAccessCoverage-priceList"
                discountedPriceListTitle={l('LICENSE_SIGNING.OPEN_ACCESS_COVERAGE.DISCOUNTED_PRICE_LIST_TITLE')}
                isDiscounted
                l={l}
                prices={mappedPrices}
            />

            <GrantFunding
                noOptions={noOptions}
                onChange={payload =>
                    dispatch({ payload, type: OpenAccessCoverageActionType.CHANGE_GRANT_FUNDING_VALUE })
                }
                onNoReasonChange={payload =>
                    dispatch({ payload, type: OpenAccessCoverageActionType.CHANGE_GRANT_FUNDING_NO_REASON })
                }
                onOtherChange={payload =>
                    dispatch({ payload, type: OpenAccessCoverageActionType.CHANGE_GRANT_FUNDING_OTHER })
                }
                otherReason={otherReason}
                value={grantFunding}
            />

            <BackAndForthContainer>
                <BackAndForthButtons
                    buttonCancelLabel={getPrevStep().name}
                    buttonConfirmingLabel={l('BUTTONS.CONFIRMING')}
                    buttonConfirmLabel={l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                    canCancel
                    canConfirm={canConfirm}
                    isConfirming={isConfirming}
                    onCancel={goBack}
                    onConfirm={() => confirmStep(grantFunding)}
                />
            </BackAndForthContainer>
        </span>
    );
};

export { OpenAccessCoverage, OpenAccessCoverageProps, StepData };

export default compose(
    withScrollToTop,
    LicenseSigningProcessContext.withContext(state => ({
        confirmStep: state.confirmOpenAccessCoverage,
        getPrevStep: state.getPrevStep,
        goBack: state.goBack,
        isConfirming: state.isOpenAccessCoverageConfirming,
        onLoadSubstep: state.onLoadSubstep,
        stepData: state.all.openAccessCoverage,
    })),
)(OpenAccessCoverage);
