import React from 'react';
import { PrimaryButton, LinkButton } from 'app/blocks/buttons/buttons';
import { l } from 'app/blocks/common/codes';
import { Modal } from '../blocks';

import './LicenseSelectionModal.scss';

function LicenseSelectionModal({
    headAndIconHeader,
    modalHeading = '',
    onClose = () => {},
    onLicenseTypeChange = () => {},
    shortNameValue = '',
}) {
    const handleClick = () => {
        onClose();
        onLicenseTypeChange();
    };

    const footer = (
        <div className="Dialog-BodyActions Dialog-BodyActions-Centered">
            <LinkButton data-seleniumid="license-selection-modal-close-button" onClick={onClose}>
                {l('BUTTONS.CLOSE')}
            </LinkButton>
            <PrimaryButton data-seleniumid="license-selection-modal-select-button" onClick={handleClick}>
                Select {l(`LICENSE.${shortNameValue}.shortName`)}
            </PrimaryButton>
        </div>
    );

    return (
        <Modal
            className="LicenseSelectionModalWrapper"
            ignoreBackdropClicks
            modalFooter={footer}
            modalHeading={modalHeading}
            onClose={onClose}
            type="custom"
        >
            <div className="LicenseSelectionModal Dialog-Body" data-seleniumid="LicenseSelection-LicenseSelectionModal">
                <div className="modal-format modal-format-center">
                    {headAndIconHeader}
                    <div data-seleniumid="LicenseSelection-title">{l(`LICENSE.${shortNameValue}.name`)}</div>
                </div>
                <div>
                    <div>
                        <div className="blockFormat" data-seleniumid="license-selection-modal-listcontainer1">
                            <strong data-seleniumid="license-selection-modal-listcontainer1-label">
                                {l(`LICENSE.${shortNameValue}.LEARN_MORE.YOU_ARE_FREE_TO.TITLE`)}
                            </strong>
                            <ul className="block-gap" data-seleniumid="license-selection-modal-listcontainer1-list">
                                {Object.values(l(`LICENSE.${shortNameValue}.LEARN_MORE.YOU_ARE_FREE_TO.POINTS`)).map(
                                    (item: string) => (
                                        <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
                                    ),
                                )}
                            </ul>
                        </div>
                        <div className="blockFormat" data-seleniumid="license-selection-modal-listcontainer2">
                            <strong data-seleniumid="license-selection-modal-listcontainer2-label">
                                {l(`LICENSE.${shortNameValue}.LEARN_MORE.EVERYONE_CAN.TITLE`)}
                            </strong>
                            <ul className="block-gap" data-seleniumid="license-selection-modal-listcontainer2-list">
                                {Object.values(l(`LICENSE.${shortNameValue}.LEARN_MORE.EVERYONE_CAN.POINTS`)).map(
                                    (item: string) => (
                                        <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
                                    ),
                                )}
                            </ul>
                        </div>
                        <div className="blockFormat" data-seleniumid="license-selection-modal-listcontainer3">
                            <strong data-seleniumid="license-selection-modal-listcontainer3-label">
                                {l(`LICENSE.${shortNameValue}.LEARN_MORE.EVERYONE_MUST.TITLE`)}
                            </strong>
                            <ul data-seleniumid="license-selection-modal-listcontainer2-list">
                                {Object.values(l(`LICENSE.${shortNameValue}.LEARN_MORE.EVERYONE_MUST.POINTS`)).map(
                                    (item: string) => (
                                        <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
                                    ),
                                )}
                            </ul>
                        </div>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: l(`LICENSE.LICENSE_LEARN_MORE_NOTE`, {
                                licenseLinks: l(`LICENSE.${shortNameValue}.url`),
                            }),
                        }}
                        data-seleniumid="license-selection-modal-note"
                        style={{ textAlign: 'center' }}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default LicenseSelectionModal;
