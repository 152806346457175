import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving, { onLeavePage } from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { BackAndForthContainer, SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';
import ConfirmRCAEligibilityMessage from './ConfirmRCAEligibilityMessage';
import ConfirmRCARadioList from './ConfirmRCARadioList';
import './ConfirmRCA.scss';

function canProvideFunding(participantId, affiliations) {
    return !!affiliations && affiliations.find(x => x.participantId === participantId);
}

function getOptions(correspondingAuthors, responsibleCorrAuthorId, l, affiliations) {
    return correspondingAuthors.map(({ firstName, lastName, participantId }) => {
        const checked = participantId === responsibleCorrAuthorId;

        const label = (
            <>
                {`${firstName} ${lastName}`}
                {canProvideFunding(participantId, affiliations) && <SvgIcon.openAccess className="ml-base" />}
                {checked && (
                    <span className="ml-base" style={{ fontSize: 12, fontWeight: 'normal' }}>
                        {l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.RCA_AUTHOR')}
                    </span>
                )}
            </>
        );

        return { label, value: participantId };
    });
}

function ConfirmRCA({ confirmRCA, correspondingAuthors, l, rca, setChangesChecker, unsetChangesChecker }) {
    const { all, article } = useLicenseFlow();
    const { authorAffiliationResponses: affiliations } = all[StepEnum.CONFIRM_AUTHORS];

    const [rcaId, setRcaId] = useState(rca?.participantId);

    React.useEffect(() => {
        const setChangesCheckerTimeout = setTimeout(() => setChangesChecker(() => true, onLeavePage), 1000);

        return () => clearTimeout(setChangesCheckerTimeout);
    });

    const options = React.useMemo(
        () => getOptions(correspondingAuthors, rcaId, l, affiliations),
        [correspondingAuthors, rcaId, l, affiliations],
    );

    return (
        <>
            <section className="ConfirmRCA">
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.TITLE')}</SectionTitle>

                <SectionPrompt>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.PROMPT')}</SectionPrompt>

                <ConfirmRCAEligibilityMessage
                    affiliations={affiliations}
                    authors={correspondingAuthors}
                    className="mt-2x"
                />

                <ConfirmRCARadioList className="mt-2x" onChange={setRcaId} options={options} value={rcaId} />
            </section>

            <BackAndForthContainer>
                <BackAndForthButtons
                    buttonCancelLabel={l('LICENSE_SIGNING.BUTTON.CORRESPONDING_AUTHORS')}
                    buttonConfirmLabel={l('LICENSE_SIGNING.BUTTON.ASSIGN_RESPONSIBLE_AUTHOR')}
                    canConfirm={!!rcaId}
                    onCancel={() => {
                        unsetChangesChecker();
                        navigate(`/license-signing/${article.id}/confirmAuthors`);
                    }}
                    onConfirm={() => {
                        unsetChangesChecker();
                        confirmRCA(rcaId);
                    }}
                    processingLabel={l('BUTTONS.CONFIRMING')}
                />
            </BackAndForthContainer>
        </>
    );
}

ConfirmRCA.propTypes = {
    confirmRCA: PropTypes.func.isRequired,
    correspondingAuthors: PropTypes.arrayOf(PropTypes.shape({ participantId: PropTypes.string })).isRequired,
    l: PropTypes.func.isRequired,
    rca: PropTypes.shape({ participantId: PropTypes.string }),
    setChangesChecker: PropTypes.func.isRequired,
    unsetChangesChecker: PropTypes.func.isRequired,
};

ConfirmRCA.defaultProps = {
    rca: undefined,
};

export default compose(withConfirmLeaving, withScrollToTop, withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING))(ConfirmRCA);
