import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

/* eslint-disable import/prefer-default-export */
const LICENSE_ICONS = {
    'CC-BY': [SvgIcon.ccCc, SvgIcon.ccBy],
    'CC-BY-NC': [SvgIcon.ccCc, SvgIcon.ccBy, SvgIcon.ccNc],
    'CC-BY-NC-ND': [SvgIcon.ccCc, SvgIcon.ccBy, SvgIcon.ccNc, SvgIcon.ccNd],
    'CC-BY-NC-SA': [SvgIcon.ccCc, SvgIcon.ccBy, SvgIcon.ccNc, SvgIcon.ccSa],
    'CC-BY-ND': [SvgIcon.ccCc, SvgIcon.ccBy, SvgIcon.ccNd],
    'CC-BY-SA': [SvgIcon.ccCc, SvgIcon.ccBy, SvgIcon.ccSa],
} as const;

export { LICENSE_ICONS };
