import React, { Component } from 'react';
import { processRegistrationPageLoad } from 'app/blocks/analytics/analytics';
import { Title, WhiteBlock } from 'app/blocks/blocks';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import { processing } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import PageEnum from 'app/blocks/common/PageEnum';
import Alerts from 'app/blocks/common/spinner';
import * as Utils from 'app/blocks/common/utils';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import Layout from 'app/blocks/Layout/Layout';
import * as middlewareRegister from 'app/blocks/middleware/register';
import routes from 'app/pages/routes';

type Props = {
    l: l;
    verifyEmailUuid: string;
};

type State = {
    isLoading: boolean;
    exceptionError?: ServiceError;
    title: string;
    message?: string;
    returnUrl?: string;
};

export class EmailVerificationPage extends Component<Props, State> {
    state: State = {
        isLoading: true,
        exceptionError: null,
        title: '',
        message: '',
        returnUrl: '',
    };

    @processing('isLoading')
    async componentDidMount() {
        const { l, verifyEmailUuid } = this.props;
        const title = l(`${ID.LOGIN}.confirmationMessages.LOGIN_VERIFY_POST_CONF_TEXT`);

        try {
            const { returnUrl = '' } = await middlewareRegister.registerFinalize(verifyEmailUuid);
            this.setState({
                title,
                returnUrl,
            });
            processRegistrationPageLoad(false, { registration: { step: 'registration verified' } });
        } catch (error) {
            const message = await ContentUtils.getServerErrorMessage(ID.REGISTRATION, error);

            switch (error.code) {
                case 'REGISTRATION_VERIFY_ACCOUNT_ALREADY_ACTIVE':
                    this.setState({ message, title });
                    break;

                case 'REGISTRATION_VERIFY_ACCOUNT_NOT_FOUND':
                    this.setState({ message });
                    break;

                default:
                    showDialog.error(error, { message });

                    this.setState({ exceptionError: new ServiceError(message, error.code, '', error.refId) });
            }
        }
    }

    onClickLogin = () => {
        const { returnUrl } = this.state;
        if (returnUrl) {
            Alerts.renderSpinner();
            Utils.windowLocationReplace(returnUrl);
        } else {
            routes.toLogin();
        }
    };

    render() {
        const { l } = this.props;
        const { exceptionError, isLoading, message, returnUrl, title } = this.state;

        return (
            <div className="container-lg">
                <Title pageid={PageEnum.EMAIL_VERIFICATION} seleniumid="email-verification-page">
                    {l(`${ID.LOGIN}.uiLabelMessages.LOGIN_EMAIL_VERIFICATION_LABEL`)}
                </Title>

                <div className="row">
                    <div className="col-md-7">
                        <Layout error={exceptionError} isLoading={isLoading}>
                            {!exceptionError && (
                                <WhiteBlock>
                                    {title && <h5>{title}</h5>}
                                    <div className="inline-help-message">
                                        {message || l(`${ID.LOGIN}.inlineHelp.LOGIN_LOGIN_WITH_EMAIL_INLINE_HELP`)}
                                    </div>
                                    <div>
                                        <PrimaryButton id="verifyEmailId" onClick={this.onClickLogin}>
                                            {returnUrl
                                                ? l(
                                                      `${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_REDIRECT_BUTTON_LABEL`,
                                                  )
                                                : l(`${ID.LOGIN}.uiLabelMessages.LOGIN_LOGIN_BUTTON_LABEL`)}
                                        </PrimaryButton>
                                    </div>
                                </WhiteBlock>
                            )}
                        </Layout>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes(EmailVerificationPage, ID.LOGIN, ID.REGISTRATION);
