import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import { O } from 'ts-toolbelt';
import _ from 'underscore';
import Article from 'app/blocks/article/article';
import { Layout, Title } from 'app/blocks/blocks';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import PageEnum from 'app/blocks/common/PageEnum';
import { cleanFields } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import PubEditorReviewConfirmation from 'app/blocks/confirmation/confirmation';
import middlewareUpload from 'app/blocks/middleware/upload';
import { SubTitle } from 'app/blocks/titles/titles';
import { navigate } from 'app/route/history';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import { Input2 } from 'app/ui/form/inputs';
import RadioButton from 'app/ui/form/RadioButton';
import './pubeditor-review.scss';

type Props = {
    error?: string;
    l: l;
    refId?: string;
    token: string;
};

type State = O.Optional<Awaited<ReturnType<typeof middlewareUpload.getUploadReviewMetadata>>> & {
    copyrightLine?: string;
    isCanceling: boolean;
    isLicenseCorrect?: boolean;
    isLoading: boolean;
    isSending: boolean;
    selectedReasons: Set<string>;
    verificationCompleted: boolean;
};

@autobind
export class LicenseVerification extends Component<Props, State> {
    static defaultProps = {
        error: undefined,
        refId: undefined,
    };

    state: State = {
        availableRejectionReasons: [],
        isCanceling: false,
        isLicenseCorrect: undefined,
        isLoading: true,
        isSending: false,
        selectedReasons: new Set(),
        verificationCompleted: false,
    };

    @processing('isLoading')
    async componentDidMount() {
        const { error: message, refId } = this.props;

        if (message && message.length !== 0) {
            showDialog.error({
                message,
                onClose: this.cancel,
                refId,
            });
        } else {
            try {
                const data = await middlewareUpload.getUploadReviewMetadata(this.props.token);
                await this.validateData(data);

                this.setState({ ...data });
            } catch (error) {
                showDialog.addErrorMessageDialog(error, this.cancel);
            }
        }
    }

    onToggleReason(reasonId: string) {
        this.setState(state => {
            const reasons = state.selectedReasons;

            if (reasons.has(reasonId)) {
                reasons.delete(reasonId);
            } else {
                reasons.add(reasonId);
            }

            return { selectedReasons: reasons };
        });
    }

    getSelectedReasonIds() {
        return Array.from(this.state.selectedReasons);
    }

    isSendPossible = () => {
        const s = this.state;

        switch (s.isLicenseCorrect) {
            case true:
                return !s.copyrightRequired || validation.hasAnySymbol(s.copyrightLine);
            case false:
                return s.selectedReasons.size > 0;
            default:
                return false;
        }
    };

    updateCopyrightLine = value => {
        this.setState({ copyrightLine: value });
    };

    cancel = () => {
        navigate('');
    };

    @tryCatch.popUpOnly
    @processing('isSending')
    async send() {
        await cleanFields(this, ({ copyrightLine }) => ({ copyrightLine }));

        await middlewareUpload.submitReview(this.props.token, {
            copyright: this.state.copyrightLine,
            licenseVerified: this.state.isLicenseCorrect,
            selectedRejectionReasons: this.getSelectedReasonIds(),
        });

        this.setState({ verificationCompleted: true });
    }

    toggle(value) {
        this.setState({ copyrightLine: '', isLicenseCorrect: value, selectedReasons: new Set() });
    }

    validateData(data) {
        const { l } = this.props;

        if (_.isEmpty(data.licenseCopyPdfUrl) || _.isEmpty(data.availableRejectionReasons)) {
            throw new Error(l('FULL_LICENSE.VERIFICATION.INVALID_DATA'));
        }

        return data;
    }

    render() {
        const { l } = this.props;
        const {
            article,
            availableRejectionReasons,
            copyrightLine,
            copyrightRequired,
            isCanceling,
            isLicenseCorrect,
            isLoading,
            isSending,
            journal,
            licenseCopyPdfUrl,
            selectedReasons,
            verificationCompleted,
        } = this.state;

        if (verificationCompleted) {
            return (
                <div className="container-lg">
                    <Title>{l('FULL_LICENSE.VERIFICATION.TITLE')}</Title>

                    <Layout isLoading={isLoading}>
                        <PubEditorReviewConfirmation
                            name={
                                isLicenseCorrect
                                    ? 'license__confirmation-license-correct'
                                    : 'license__confirmation-license-incorrect'
                            }
                            seleniumid={
                                isLicenseCorrect
                                    ? 'pubeditor-review-confirmation-license-correct'
                                    : 'pubeditor-review-confirmation-license-incorrect'
                            }
                        />
                    </Layout>
                </div>
            );
        }

        const viewLicenseLink = l('FULL_LICENSE.VERIFICATION.INFO_TEXT', {
            pdfUrl: licenseCopyPdfUrl,
            seleniumId: 'pubeditor-review-viewLicense-link',
        });

        return (
            <div className="PubEditor container-lg">
                <Layout isLoading={isLoading}>
                    <Title pageid={PageEnum.PUB_EDITOR}>{l('FULL_LICENSE.VERIFICATION.TITLE')}</Title>
                    <div className="PubEditor-Article">
                        {journal && <Article article={article} journal={journal} />}
                    </div>

                    <div className="PubEditor-Content">
                        <SubTitle>{l('FULL_LICENSE.VERIFICATION.SUBTITLE')}</SubTitle>
                        <div className="Text" dangerouslySetInnerHTML={{ __html: viewLicenseLink }} />
                        <div className="PubEditor-Content-RadioButtons">
                            <RadioButton
                                checked={isLicenseCorrect}
                                className="mt-two-thirds mr-2x"
                                data-seleniumid="pubeditor-review-licenseCorrect-radio-input"
                                id="pubeditor-review-licenseCorrect-radio-input"
                                label={l('FULL_LICENSE.VERIFICATION.CORRECT')}
                                onChange={() => this.toggle(true)}
                                value
                            />

                            <RadioButton
                                checked={isLicenseCorrect === false}
                                className="mt-two-thirds"
                                data-seleniumid="pubeditor-review-licenseIncorrect-radio-input"
                                id="pubeditor-review-licenseIncorrect-radio-input"
                                label={l('FULL_LICENSE.VERIFICATION.INCORRECT')}
                                onChange={() => this.toggle(false)}
                                value={false}
                            />
                        </div>
                        {isLicenseCorrect === false && (
                            <div className="CheckboxContainer">
                                <div className="CheckboxContainer-Text">
                                    {l('FULL_LICENSE.VERIFICATION.SELECT_REASON')}
                                </div>
                                {availableRejectionReasons.map(reason => (
                                    <CheckboxButton
                                        key={reason.id}
                                        checked={selectedReasons.has(reason.id)}
                                        className="CheckboxContainer-CheckBox"
                                        data-seleniumid={`pubeditor-review-licenseIncorrectReason-checkbox-${reason.id}-input`}
                                        id={`pubeditor-review-licenseIncorrectReason-checkbox-${reason.id}`}
                                        label={reason.name}
                                        onChange={() => this.onToggleReason(reason.id)}
                                        value={false}
                                    />
                                ))}
                            </div>
                        )}
                        {copyrightRequired && isLicenseCorrect && (
                            <div className="InputContainer">
                                <div className="InputContainer-Text">
                                    {l('FULL_LICENSE.VERIFICATION.COPYRIGHT_LINE')}
                                </div>
                                <div>
                                    <Input2
                                        className="InputContainer-Input"
                                        data-seleniumid="pubeditor-review-licenseCorrect-copyright-input"
                                        maxLength={1000}
                                        onChange={this.updateCopyrightLine}
                                        type="text"
                                        value={copyrightLine}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="PubEditor-Actions">
                        <Button
                            className="license-verification-cancel-button"
                            data-seleniumid="pubeditor-review-cancel-button"
                            onClick={this.cancel}
                        >
                            {isCanceling
                                ? l('FULL_LICENSE.VERIFICATION.BUTTONS.CANCELING')
                                : l('FULL_LICENSE.VERIFICATION.BUTTONS.CANCEL')}
                        </Button>

                        <PrimaryButton
                            className="license-verification-send-button"
                            data-seleniumid="pubeditor-review-send-button"
                            disabled={!this.isSendPossible() || isSending}
                            onClick={this.send}
                        >
                            {isSending
                                ? l('FULL_LICENSE.VERIFICATION.BUTTONS.SENDING')
                                : l('FULL_LICENSE.VERIFICATION.BUTTONS.SEND')}
                        </PrimaryButton>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default withCodes(LicenseVerification, ID.FULL_LICENSE);
