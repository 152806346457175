/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import StaticContent from 'app/blocks/static-content/static-content';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import RadioButton from 'app/ui/form/RadioButton';

const OPTIONS = ['YES', 'NO'];

function CopyrightOptions(props) {
    const {
        copyrightOwnershipList,
        isSingleAuthor,
        l,
        onChangeCopyrightOptions,
        onChangeOption,
        selectedOption,
        subStep,
    } = props;

    const Component = isSingleAuthor ? RadioButton : CheckboxButton;
    const type = isSingleAuthor ? 'SINGLE_AUTHOR' : 'MULTI_AUTHOR';

    return (
        <>
            {subStep === 0 &&
                OPTIONS.map(option => {
                    const label = l(`LICENSE_SIGNING.COPYRIGHT.${type}.${option}`);
                    const radioLabel = (
                        <div key={label} className="Choice-Title">
                            {label}
                        </div>
                    );
                    return (
                        <label
                            key={`${option}-Option`}
                            className={cn('Copyright-Option', {
                                Selected: selectedOption === option,
                            })}
                            data-seleniumid={`copyrightOwnership-${option}-Option`}
                            htmlFor={`${option}-Option`}
                        >
                            <RadioButton
                                checked={selectedOption === option}
                                data-seleniumid={`radioButton-${option}-Option`}
                                id={`${option}-Option`}
                                label={radioLabel}
                                onChange={() => onChangeOption(option)}
                                value={`${option}-Option`}
                            />
                            <StaticContent
                                className="OptionValue"
                                seleniumid={`${option}-prompt`}
                                src={`${type}_${option}.html`}
                            />
                        </label>
                    );
                })}

            {subStep === 1 &&
                copyrightOwnershipList
                    .filter(o => !(isSingleAuthor && o.id === 'CONTRIBUTOR'))
                    .map(copyrightOwnership => {
                        return (
                            <div
                                key={`${copyrightOwnership.id}-Option`}
                                className={cn('Copyright-Option', {
                                    Selected: copyrightOwnership.selected,
                                })}
                                data-seleniumid={`copyrightOwnership-${copyrightOwnership.id}-Option`}
                            >
                                <div className="Option-Label">
                                    <Component
                                        checked={copyrightOwnership.selected}
                                        className={isSingleAuthor ? 'Copyright-Radio' : 'Copyright-CheckBox'}
                                        data-seleniumid={`checkBoxButton-${copyrightOwnership.id}-Option`}
                                        id={`${copyrightOwnership.id}-Option`}
                                        label={
                                            <div className="Option-Title">
                                                {l(`LICENSE_SUBMISSION.${copyrightOwnership.id}.TITLE`)}
                                            </div>
                                        }
                                        onChange={() => onChangeCopyrightOptions(copyrightOwnership)}
                                        value={`${copyrightOwnership.id}-Option`}
                                    />
                                </div>
                                <div
                                    className={cn('SubOption-Container', {
                                        Selected: copyrightOwnership.selected && copyrightOwnership.subItems?.length,
                                    })}
                                >
                                    <label
                                        className="Copyright-OptionValue"
                                        dangerouslySetInnerHTML={{
                                            __html: l(
                                                `LICENSE_SUBMISSION.${copyrightOwnership.id}.${type}.DESCRIPTION`,
                                            ),
                                        }}
                                        data-seleniumid={`${copyrightOwnership.id}-prompt`}
                                        htmlFor={`${copyrightOwnership.id}-Option`}
                                    />
                                    {copyrightOwnership.selected &&
                                        !!copyrightOwnership.subItems?.length &&
                                        copyrightOwnership.subItems
                                            .filter(o => !o.name)
                                            .map(subItem => {
                                                const subItemLabel = (
                                                    <div
                                                        className={cn('SubOption-Title', {
                                                            Selected: subItem.selected,
                                                            Single: !isSingleAuthor,
                                                        })}
                                                    >
                                                        {l(
                                                            `LICENSE_SUBMISSION.${copyrightOwnership.id}.ITEMS.${subItem.id}.OPTION_TITLE`,
                                                            {},
                                                            l(
                                                                `LICENSE_SUBMISSION.${copyrightOwnership.id}.ITEMS.${subItem.id}.TITLE`,
                                                            ),
                                                        )}
                                                    </div>
                                                );

                                                return (
                                                    <div
                                                        className="SubOption"
                                                        data-seleniumid={`copyrightOwnership-${subItem.id}-SubOption`}
                                                    >
                                                        <Component
                                                            checked={subItem.selected}
                                                            data-seleniumid={`checkBoxButton-${subItem.id}-SubOption`}
                                                            label={subItemLabel}
                                                            onChange={() => onChangeCopyrightOptions(subItem)}
                                                            value={`${subItem.id}-SubOption`}
                                                        />
                                                    </div>
                                                );
                                            })}
                                </div>
                            </div>
                        );
                    })}
        </>
    );
}

export default withCodes2(ID.LICENSE_SIGNING, ID.LICENSE_SUBMISSION)(CopyrightOptions);
