import PropTypes from 'prop-types';
import React from 'react';
import Article from 'app/blocks/article/article';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import OrdersDataItem from 'app/pages/orders/data-item';
import OrdersOAAmountPayable from 'app/pages/orders/oa/amount-payable';
import OrdersPanelContactReview from 'app/pages/orders/panel/contact/review';
import OrdersPanelDiscountReview from 'app/pages/orders/panel/discount/review';
import OrdersPanelPaymentReview from 'app/pages/orders/panel/payment/review';
import OrdersPanelVatTaxReview from 'app/pages/orders/panel/vat-tax/review';

export function isDisplayDiscountSection(obj) {
    return obj && (Object.keys(obj).includes('societyPromoCode') || Object.keys(obj).includes('wileyPromoCode'));
}

export default function OrdersSingleOA(props) {
    const {
        className,
        cpsErrorText,
        dontAllowEdit,
        handleAgreeToPayChange,
        handleAgreeToSocopayPolicy,
        initOrder,
        isAgreeToPay,
        isAgreeToSocopayPolicy,
        isCommonAmountPayablePanel,
        isPaidAlipayOrder,
        isPaidOAAlipayOrder,
        onRequestEditOrder,
        order,
        orderStatusString,
        seleniumid,
        shouldShowCpsError,
    } = props;

    OrdersSingleOA.defaultProps = {
        isCommonAmountPayablePanel: false,
        isPaidOAAlipayOrder: false,
    };

    OrdersSingleOA.propTypes = {
        isCommonAmountPayablePanel: PropTypes.bool,
        isPaidOAAlipayOrder: PropTypes.bool,
    };

    const DataItemAndAuxInfo = React.useMemo(() => OrdersDataItem.create(order, initOrder), [order, initOrder]);
    const isEditable = !dontAllowEdit;
    const isDisplayDiscount = isDisplayDiscountSection(order.discountDetails);
    const isDisplayPaymentDetails = 'paymentDetails' in order;

    return (
        <div className={className}>
            <div className="row order-btm-border">
                <div className="col-md-12 col-12 nopadding">
                    <Article
                        article={DataItemAndAuxInfo.auxInfo.order.article}
                        journal={DataItemAndAuxInfo.auxInfo.order.journal}
                        openAccess
                        seleniumid={seleniumid}
                    />
                </div>
            </div>
            <div>
                {shouldShowCpsError && (
                    <div className="payment-error">
                        <ErrorLabel>
                            <p className="text" data-seleniumid="reviewOrder-errorLabel">
                                {cpsErrorText}
                            </p>
                        </ErrorLabel>
                    </div>
                )}
            </div>

            <OrdersPanelContactReview
                DataItemAndAuxInfo={DataItemAndAuxInfo}
                isEditable={isEditable}
                onRequestEditOrder={onRequestEditOrder}
                showBillingAddress
                showContactAddress={false}
            />

            {isDisplayDiscount && (
                <OrdersPanelDiscountReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={isEditable}
                    onRequestEditOrder={onRequestEditOrder}
                />
            )}

            {isDisplayPaymentDetails && (
                <OrdersPanelPaymentReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={isEditable}
                    isPaidAlipayOrder={isPaidAlipayOrder}
                    isPaidOAAlipayOrder={isPaidOAAlipayOrder}
                    onRequestEditOrder={onRequestEditOrder}
                    orderStatusString={orderStatusString}
                />
            )}

            <OrdersPanelVatTaxReview
                DataItemAndAuxInfo={DataItemAndAuxInfo}
                isEditable={isEditable}
                onRequestEditOrder={onRequestEditOrder}
            />

            <OrdersOAAmountPayable
                DataItemAndAuxInfo={DataItemAndAuxInfo}
                handleAgreeToPayChange={handleAgreeToPayChange}
                handleAgreeToSocopayPolicy={handleAgreeToSocopayPolicy}
                isAgreeToPay={isAgreeToPay}
                isAgreeToSocopayPolicy={isAgreeToSocopayPolicy}
                isCommonAmountPayablePanel={isCommonAmountPayablePanel}
                isPaidOAAlipayOrder={isPaidOAAlipayOrder}
                isShowTax
            />
        </div>
    );
}
