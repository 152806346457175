import { GET, POST } from 'app/blocks/middleware/utils';

type ArticleAffiliationsPayload = {
    affiliations: ArticleAffiliation[];
    geoTargetedMessage: { html: string; country: string };
    latestChangeByUser: boolean;
    needWoaConfirmation: boolean;
    suggestions?: {
        matches?: Record<string, Institution[]>;
        emailSuggestions?: Array<Institution & { emailMatched: string }>;
    };
};

const Middleware = {
    confirmAffiliations(articleId: string): Promise<void> {
        return POST(`/articles/${articleId}/combinedFlow/articleAffiliations/confirm`);
    },
    getAffiliations(articleId: string): Promise<ArticleAffiliationsPayload> {
        return GET(`/article/${articleId}/affiliations/`);
    },
    notifyAffiliation(articleId: string, props): Promise<void> {
        return POST(`/article/${articleId}/affiliations/affiliation-issue`, { ...props });
    },
    saveAffiliations(
        articleId: string,
        props,
    ): Promise<Pick<ArticleAffiliationsPayload, 'affiliations' | 'needWoaConfirmation' | 'suggestions'>> {
        return POST(`/article/${articleId}/affiliations/`, { ...props });
    },
    savePrimaryAffiliation(articleId: string, affiliationId: string): Promise<void> {
        return POST(`/articles/${articleId}/combinedFlow/articleAffiliations/${affiliationId}/primary`);
    },
};

export default Middleware;
