import React, { useEffect, useState } from 'react';
import { WhiteBlock } from 'app/blocks/blocks';
import './content_search.scss';
import { ID, withCodes } from 'app/blocks/common/codes';
import * as middlewareSearch from 'app/blocks/middleware/search';
import MiniSearch from 'app/blocks/mini-search/MiniSearch';
import AdvanceSearchButtons from 'app/pages/search/advance-search-buttons/AdvanceSearchButtons';
import { Input2 } from 'app/ui/form/inputs';
import Select from 'app/ui/form/Select';

function ContentSearch(props) {
    const { codes, disabledSearchBtn, journalData, onSearch, onValueChange } = props;
    const componentCodes = codes.SEARCH_CODES.SEARCH.searchBy;
    const searchCriteria = [componentCodes.criteria.doi, componentCodes.criteria.ref];
    const [doi, setDoi] = useState(null);
    const [refCode, setRefCode] = useState(null);
    const [journalSearch, setJournalSearch] = useState(null);
    const [journalApiData, setJournalApiData] = useState(null);
    const [journalId, setJournalId] = useState(null);
    const [isInput, setIsInput] = useState(false);

    const stringToId = string => {
        return string.replace(/^\w|[A-Z]|\b\w|\s+/g, (camelCaseMatch, i) => {
            if (+camelCaseMatch === 0) return '';
            return i === 0 ? camelCaseMatch.toLowerCase() : camelCaseMatch.toUpperCase();
        });
    };
    const [searchBy, setSearchBy] = useState(stringToId(searchCriteria[0]));

    useEffect(() => {
        if (refCode && journalId) {
            onValueChange('editorialRefCode', refCode);
            onValueChange('journalId', journalId);
        }

        onValueChange('doi', doi);

        if (journalData && !isInput) {
            setSearchBy(stringToId(searchCriteria[1]));
            setJournalSearch(journalData.name);
            onValueChange('journalId', journalData.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refCode, journalId, doi, journalData]);

    const onChangeSearch = event => {
        setSearchBy(event.target.value);
        setDoi(null);
        setJournalSearch(null);
        setRefCode(null);
        setJournalId(null);
        setIsInput(true);
    };

    const onChangeDoi = value => {
        setDoi(value);
    };

    const handleSearch = async query => {
        setJournalSearch(query);
        let data = [];
        if ((query || '').trim()) {
            data = (await middlewareSearch.journalOnlySearch({ title: query }, { offset: 0, size: 5 })).items;
            setJournalApiData(data);
        } else {
            setJournalApiData(data);
            onValueChange('journalId', null);
            onValueChange('editorialRefCode', null);
        }
        setIsInput(true);
    };

    const handleSearchListClick = (id, query) => {
        setJournalId(id);
        setJournalSearch(query);
        setIsInput(true);
    };

    const handleEdRefChange = value => {
        setRefCode(value);
        setIsInput(true);
    };

    return (
        <>
            <WhiteBlock className="page SearchBySeparateMB" data-seleniumid="article-info-search">
                <h3 className="subtitle m-btm_normal">{componentCodes.title}</h3>
                <section className="SearchByContainer">
                    <div className="sbColumn">
                        <label htmlFor="searchCriteria">{componentCodes.search_by}</label>
                        <Select
                            data-seleniumid="article-search-criteria"
                            id="searchCriteria"
                            onChange={onChangeSearch}
                            selected={searchBy}
                            value={searchBy}
                        >
                            {searchCriteria.map(criteria => (
                                <option
                                    key={`${stringToId(criteria)}`}
                                    data-seleniumid={`${stringToId(criteria)}`}
                                    value={`${stringToId(criteria)}`}
                                >
                                    {criteria}
                                </option>
                            ))}
                        </Select>
                    </div>
                    {searchBy === stringToId(searchCriteria[0]) && (
                        <div className="sbColumn">
                            <label htmlFor="search-advanced-search-doi-input">Article DOI</label>
                            <Input2
                                data-seleniumid="search-advanced-search-doi-input"
                                id="search-advanced-search-doi-input"
                                onChange={value => onChangeDoi(value)}
                            />
                        </div>
                    )}

                    {searchBy === stringToId(searchCriteria[1]) && (
                        <>
                            <div className="sbColumn">
                                <label htmlFor="search-advanced-search-editorialRefCode-input">
                                    Editorial Reference Code
                                </label>
                                <Input2
                                    data-seleniumid="search-advanced-search-editorialRefCode-input"
                                    id="search-advanced-search-editorialRefCode-input"
                                    onChange={value => {
                                        handleEdRefChange(value);
                                    }}
                                    value={refCode}
                                />
                            </div>
                            <div className="sbColumn">
                                <label htmlFor="journalSearch">{componentCodes.criteria.journal}</label>
                                <MiniSearch
                                    defaultValue={journalSearch}
                                    onInput={handleSearch}
                                    searchResults={journalApiData}
                                    seleniumId="journalSearch-combobox-input"
                                >
                                    <ul className="MiniSearchContent">
                                        {journalApiData &&
                                            journalApiData.map(journal => (
                                                <li key={journal.id}>
                                                    <button
                                                        onClick={() =>
                                                            handleSearchListClick(journal.id, journal.doc.title)
                                                        }
                                                        type="button"
                                                    >
                                                        {journal.doc.title}
                                                    </button>
                                                </li>
                                            ))}
                                    </ul>
                                </MiniSearch>
                            </div>
                        </>
                    )}
                </section>
            </WhiteBlock>
            <AdvanceSearchButtons
                disableOnNext={disabledSearchBtn}
                hidePrevious
                nextButtonText={componentCodes.search_btn}
                onNext={onSearch}
            />
        </>
    );
}

export default withCodes(React.memo(ContentSearch), ID.SEARCH_CODES);
