import React from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { logout } from 'app/blocks/middleware/authentication';
import { navigate } from 'app/route/history';
import './redirect-login.scss';

export default function RedirectLogin() {
    if (window.performance) {
        if (window.performance.navigation.type === 1) {
            logout(true);
            navigate('/');
        }
    }
    document.getElementById('AS__footer').style.display = 'none';
    document.getElementById('AS__menu').style.display = 'none';

    return (
        <div className="RedirectHeader">
            <div className="RedirectContent" data-seleniumid="errorText">
                {' '}
                There has been an error. Please refresh the page or go back to Wiley Author Services ‘home’.
            </div>
            <PrimaryButton
                data-seleniumid="redirectLogin"
                onClick={() => {
                    logout(true);
                    navigate('/');
                }}
                style={{
                    fontFamily: 'openSans',
                    fontSize: '14px',
                    fontWeight: '600',
                    height: '43px',
                    lineHeight: '17px',
                    width: '280px',
                }}
            >
                Wiley Author Services home
                <>
                    {' '}
                    <SvgIcon.next
                        data-seleniumid="nextIcon"
                        iconTitle="next Icon"
                        style={{ height: '12px', marginLeft: '14px', marginRight: '15px', width: '14px' }}
                    />
                </>
            </PrimaryButton>
        </div>
    );
}
