import React from 'react';
import { ContextInterface } from 'app/pages/article/ArticlePage.context';
import DashboardActionCodes from 'app/types/dashboard-action-codes';
import CoverSalesWidget from './widgets/CoverSalesWidget';
import DownloadArticleWidget from './widgets/DownloadArticleWidget';
import DownloadLicenseWidget from './widgets/DownloadLicenseWidget';
import FundersWidget from './widgets/FundersWidget';
import HelpWidget from './widgets/HelpWidget';
import LicenseWidget from './widgets/LicenseWidget';
import OAWidget from './widgets/OAWidget';
import OOWidget from './widgets/OOWidget';
import ProofingWidget from './widgets/ProofingWidget';
import PublicationHistoryWidget from './widgets/PublicationHistoryWidget';
import ShareWidget from './widgets/ShareWidget';
import VideoAbstractWidget from './widgets/VideoAbstractWidget';

function prepare(list) {
    const ordered = { important: [], left: [], required: [], right: [] };

    for (const tab of list.filter(p => !!p)) {
        if (tab.important) {
            ordered.important.push(tab.Component);
        } else if (tab.required) {
            ordered.required.push(tab.Component);
        } else if (tab.right) {
            ordered.right.push(tab.Component);
        } else {
            ordered.left.push(tab.Component);
        }
    }

    return ordered;
}

export default function (item: ContextInterface): {
    required: React.ReactElement[];
    left: React.ReactElement[];
    right: React.ReactElement[];
    important: React.ReactElement[];
} {
    const { hasAction, hasCitations, hasImportant, hasOption, hasPanel, hasRequired } = item.helpers;

    const published = item?.status?.published;
    const publicationStatusCode = item?.publication?.statusCode;

    return prepare([
        hasPanel('license') &&
            (hasRequired('license') || !hasAction('license', DashboardActionCodes.VIEW_LICENSE)) && {
                Component: <LicenseWidget key="license" />,
                required: hasRequired('license'),
                right: published,
            },
        hasPanel('OOOrder') && {
            Component: <OOWidget key="oo" />,
            required: hasRequired('OOOrder'),
            right: published && hasCitations(),
        },
        hasPanel('OAOrder') && {
            Component: <OAWidget key="OAOrder" />,
            important: hasImportant('OAOrder'),
            required: hasRequired('OAOrder'),
            right: published && hasCitations(),
        },
        hasPanel('proofing') && {
            Component: <ProofingWidget key="proofing" />,
            required: hasRequired('proofing'),
            right: !hasRequired('proofing'),
        },
        hasRequired('funding') && {
            Component: <FundersWidget key="funding" />,
            required: true,
            right: published,
        },
        item?.publication?.available && {
            Component: <DownloadArticleWidget key="downloadArticle" />,
            required: false,
            right: published && hasCitations(),
        },
        hasOption('shareArticle') && {
            Component: <ShareWidget key="shareArticle" />,
            required: false,
            right: published && hasCitations(),
        },
        hasOption('coverCharge') &&
            (!item.status?.published || (item.status?.published && publicationStatusCode === 'EARLY_VIEW')) && {
                Component: <CoverSalesWidget key="coverCharge" />,
                required: false,
                right: false,
            },
        hasOption('makeVideoAbstract') && {
            Component: <VideoAbstractWidget key="makeVideoAbstract" />,
            required: false,
            right: published,
        },
        hasPanel('license') &&
            !hasRequired('license') &&
            hasAction('license', DashboardActionCodes.VIEW_LICENSE) && {
                Component: <DownloadLicenseWidget key="download-license" />,
                required: false,
                right: published,
            },
        item.history && {
            Component: <PublicationHistoryWidget key="history" />,
            right: true,
        },
        {
            Component: <HelpWidget key="help" />,
            right: true,
        },
    ]);
}
