import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const Address = ({ data, seleniumId }) => {
    const addressFields = [
        {
            fieldName: 'city',
            fieldValue: data.city,
        },
        {
            fieldName: 'state',
            fieldValue: data.stateName || data.stateCode,
        },
        {
            fieldName: 'country',
            fieldValue: data.countryCode || data.countryName || data.country,
        },
    ];

    return (
        <>
            {addressFields
                .filter(field => field.fieldValue)
                .map((field, index, fields) => {
                    const item = <span data-seleniumid={`${seleniumId}-${field.fieldName}`}>{field.fieldValue}</span>;
                    return index < fields.length - 1 ? (
                        <Fragment key={field.fieldName}>{item}, </Fragment>
                    ) : (
                        <Fragment key={field.fieldName}>{item}</Fragment>
                    );
                })}
        </>
    );
};

Address.propTypes = {
    data: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        countryCode: PropTypes.string,
        countryName: PropTypes.string,
        stateCode: PropTypes.string,
        stateName: PropTypes.string,
    }).isRequired,
    seleniumId: PropTypes.string,
};

Address.defaultProps = {
    seleniumId: '',
};

export default Address;
