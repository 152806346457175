import React from 'react';
import { Navigate, Route, useLocation, useParams } from 'react-router-dom';
import appPages from 'app/appPages';
import withBanner from 'app/blocks/banner-messages/withBanner';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { cleanToken, compose, getQueryParam, getQueryParamSafe } from 'app/blocks/common/utils';
import withAnalytics, { withAnalyticsContext } from 'app/blocks/common/withAnalytics';
import withAuthRequiredPage from 'app/blocks/common/withAuthRequiredPage';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import withTopMenu from 'app/blocks/common/withTopMenu';
import withUnauthRequiredPage from 'app/blocks/common/withUnauthRequiredPage';
import Message from 'app/blocks/message/message';
import { logout, refreshUser } from 'app/blocks/middleware/authentication';
import withUrlParams, { updateUrlParam } from 'app/blocks/middleware/url-params';
import ArticlePage from 'app/pages/article';
import ArticleAssignmentByJournal from 'app/pages/assign-article/_by-journal/assign-article_by-journal';
import ArticleAssignmentById from 'app/pages/assign-article/article-assignment-by-id/article-assignment-by-id';
import Dashboard from 'app/pages/dashboard';
import LicenseSigningProcessPage from 'app/pages/license-signing-process/LicenseSigningProcessPage';
import LoginPage from 'app/pages/login/login-page';
import RecoverPasswordPage from 'app/pages/login/recover-password-page';
import Orders from 'app/pages/orders/orders';
import ProfilePage from 'app/pages/profile/profile-page';
import PubEditorReview from 'app/pages/pubeditor-review/pubeditor-review';
import EmailVerificationPage from 'app/pages/registration/email-verification-page';
import RegistrationPage from 'app/pages/registration/registration-page';
import AdvanceContentSearch from 'app/pages/search/_advanced/advance_content_search';
import MissingArticleDetails from 'app/pages/search/_advanced/missing-article-details/MissingArticleDetails';
import ContentSearch from 'app/pages/search/content_search';
import Version from 'app/pages/version/version';
import { navigate } from 'app/route/history';
import tempStorage from 'app/route/tempStorage';
import EmailAlertsPage from '../pages/email-alerts/email-alerts-page';
import RedirectLogin from '../pages/login/redirect-login';

const withNamedAuth = page => Component => withAuthRequiredPage(Component, page);
const withAuthForPage = page => compose(withBanner, withNamedAuth(page), withScrollToTop);
const withUnauth = compose(withBanner, withUnauthRequiredPage, withScrollToTop);

function HomeRoute() {
    window.location.replace('/home.html');

    return null;
}

const LoginRoute = withUnauth(props => {
    return <LoginPage {...props} queryParams={getQueryParam(useLocation().search)} skipNotify />;
});

const RegistrationRoute = compose(withUnauth, withUrlParams)(props => <RegistrationPage {...props} />, true);

function RegistrationByInviteRoute(props) {
    updateUrlParam('invitationToken', cleanToken(useParams().token || ''));

    const { article } = getQueryParamSafe(useLocation().search, true);
    if (article) {
        updateUrlParam('invitationArticleId', article);
    }

    return <RegistrationRoute {...props} />;
}

const RecoverPasswordRoute = compose(
    withAnalytics(appPages.RECOVER_PASSWORD),
    withUnauth,
)(props => <RecoverPasswordPage {...props} />);

const ProfileRoute = compose(
    withAnalyticsContext(appPages.PROFILE),
    withAuthForPage(appPages.PROFILE),
    withTopMenu,
)(props => <ProfilePage {...props} section={getQueryParam(useLocation().search).section} />);

const VerifyEmailRoute = compose(
    withBanner,
    withScrollToTop,
)(props => <EmailVerificationPage {...props} verifyEmailUuid={cleanToken(useParams().token)} />);

const MessageRoute = compose(
    withBanner,
    withScrollToTop,
)(props => <Message {...props} {...getQueryParam(useLocation().search)} />);

const ResetPasswordRoute = compose(
    withAnalytics(appPages.RESET_PASSWORD),
    withUnauth,
    withCodes2(ID.RECOVER_PASSWORD),
)(props => (
    <RecoverPasswordPage
        {...props}
        queryParams={getQueryParam(useLocation().search)}
        uuid={encodeURIComponent(cleanToken(useParams().uuid))}
    />
));

const DashboardRoute = compose(
    withAuthForPage(appPages.DASHBOARD),
    withTopMenu,
)(props => <Dashboard {...props} focusOnArticle={getQueryParam(useLocation().search).focusOnArticle} />);

const ArticlePageRoute = withAuthForPage(appPages.ARTICLE_PAGE)(
    withTopMenu(props => <ArticlePage {...props} articleId={useParams().articleId} />),
);

const SearchRoute = compose(
    withBanner,
    withScrollToTop,
)(() => {
    const { text } = getQueryParam(useLocation().search, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const context = React.useMemo(() => tempStorage.get(), [text]);

    return <ContentSearch key={text} context={context} text={text} />;
});

const AdvanceSearchRoute = compose(
    withBanner,
    withScrollToTop,
)(props => {
    const context = React.useMemo(() => tempStorage.get(), []);
    const { jid, type } = getQueryParam(useLocation().search);

    return <AdvanceContentSearch {...props} key={`${jid}-${type}`} context={context} jid={jid} type={type} />;
});

const MissingArticleDetailsRoute = compose(
    withAnalytics(appPages.MISSING_ARTICLE_DETAILS),
    withAuthForPage(appPages.MISSING_ARTICLE_DETAILS),
)(props => <MissingArticleDetails {...props} />);

const VersionRoute = withAnalytics(appPages.VERSION)(props => <Version {...props} />);

const EmailAlertsRoute = withAnalytics(appPages.EMAIL_ALERTS)(props => (
    <EmailAlertsPage {...props} {...getQueryParam(useLocation().search)} />
));

const CheckAuthAndRedirectRoute = withCodes2(
    ID.ERROR,
    ID.LOGIN,
)(props => {
    const { link } = getQueryParam(useLocation().search, true);
    const isLinkValid = link && link[0] === '/';

    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (isLinkValid) {
            (async () => {
                try {
                    const { loggedIn } = await refreshUser();

                    if (loggedIn) {
                        window.location.href = link;
                    } else {
                        setLoading(false);

                        showDialog.error({
                            closeBtnLabel: props.l('LOGIN.pageName'),
                            message: props.l('ERROR.SESSION_HAS_BEEN_EXPIRED'),
                            onClose() {
                                logout();
                            },
                        });
                    }
                } catch (error) {
                    setLoading(false);

                    showDialog.error(error);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isLinkValid) {
        return <Message message={props.l('ERROR.WRONG_REDIRECT_LINK')} />;
    }
    if (isLoading) {
        return <Layout isLoading />;
    }

    return null;
});

function DashboardCallbackRoute(props) {
    const { search } = useLocation();
    if (/[?&]message=/.test(search)) {
        const params = getQueryParam(search, true);
        const message = decodeURIComponent(params.message.replace(/\+/g, '%20'));
        const code = decodeURIComponent(params.code);
        const refId = decodeURIComponent(params.refId);

        return <Dashboard {...props} code={code} error={message} refId={refId} />;
    }

    return null;
}

const ReviewPDFRoute = withBanner(props => {
    const { search } = useLocation();
    if (/[?&]message=/.test(search)) {
        const params = getQueryParam(search, true);
        const message = decodeURIComponent(params.message.replace(/\+/g, '%20'));
        const code = decodeURIComponent(params.code);
        const refId = decodeURIComponent(params.refId);

        return <PubEditorReview {...props} code={code} error={message} refId={refId} />;
    }

    return null;
});

const PubEditorReviewRoute = compose(
    withAnalytics(appPages.PUB_EDITOR_REVIEW),
    withBanner,
)(() => {
    const props = getQueryParam(useLocation().search, true);

    if (props.token) {
        props.token = cleanToken(props.token);
    }

    return <PubEditorReview {...props} />;
});

const LicenseSigningRoute = withBanner(() => {
    const { articleId, step, substep } = useParams();
    return <LicenseSigningProcessPage articleId={articleId} step={step} substep={substep} />;
});

const OrdersRoute = compose(
    withAnalyticsContext(appPages.ORDERS),
    withBanner,
    withNamedAuth(appPages.ORDERS),
    withTopMenu,
)(() => <Orders error={getQueryParam(useLocation().search).error} />);

const OrdersFocusOnArticleRoute = compose(
    withAnalyticsContext(appPages.ORDERS_FOCUS_ON_ARTICLE),
    withBanner,
    withNamedAuth(appPages.ORDERS_FOCUS_ON_ARTICLE),
)(() => {
    return <Orders error={getQueryParam(useLocation().search).error} focusOnArticle={useParams().articleId} />;
});

function cancelArticleAssignment(context) {
    if (typeof context.query === 'string') {
        navigate(`search?text=${context.query}`, undefined, context);
    } else if (context.jid) {
        navigate(`advancedsearch?jid=${context.jid}&type=article`, undefined, context);
    } else {
        navigate(`advancedsearch?type=article`, undefined, context);
    }
}

const AssignArticleRoute = compose(
    withBanner,
    withNamedAuth(appPages.ASSIGN_ARTICLE),
)(() => {
    const params = getQueryParam(useLocation().search);
    const context = React.useMemo(() => tempStorage.get(), []);
    let cancel;

    if (context) {
        cancel = () => cancelArticleAssignment(context);
    } else {
        cancel = () => navigate('dashboard');
    }

    if (params.articleId) {
        return (
            <ArticleAssignmentById
                articleId={params.articleId}
                cancel={cancel}
                gotoDashboard={() => navigate('dashboard')}
            />
        );
    }
    if (params.journalId) {
        return (
            <ArticleAssignmentByJournal
                cancel={cancel}
                gotoDashboard={() => navigate('dashboard')}
                journalId={params.journalId}
            />
        );
    }

    return null;
});

const NoMatchRoute = withBanner(() => <Navigate to="/dashboard" />);

export default [
    <Route key="Home" Component={HomeRoute} path="/" />,
    <Route key="redirectHome" Component={RedirectLogin} path="/errorRedirectHome" />,
    <Route key="Login" Component={LoginRoute} path="/login/*" />,

    <Route key="RegistrationByInvite" Component={RegistrationByInviteRoute} path="/register-invite/:token/*" />,
    <Route key="Registration" Component={RegistrationRoute} path="/register" />,

    <Route key="RecoverPassword" Component={RecoverPasswordRoute} path="/recover/*" />,
    <Route key="Profile" Component={ProfileRoute} path="/profile" />,
    <Route key="VerifyEmail" Component={VerifyEmailRoute} path="/verify/:token/*" />,
    <Route key="Message" Component={MessageRoute} path="/message/*" />,
    <Route key="ResetPassword" Component={ResetPasswordRoute} path="/resetPassword/:uuid/*" />,
    <Route key="Dashboard" Component={DashboardRoute} path="/dashboard" />,
    <Route key="ArticlePage" Component={ArticlePageRoute} path="/article/:articleId/*" />,
    <Route key="Search" Component={SearchRoute} path="/search/*" />,
    <Route key="SearchHelp" Component={MissingArticleDetailsRoute} path="/get-help-on-article" />,
    <Route key="AdvanceSearch" Component={AdvanceSearchRoute} path="/advancedsearch/*" />,
    <Route key="Version" Component={VersionRoute} path="/version/*" />,
    <Route key="CheckAuthAndRedirect" Component={CheckAuthAndRedirectRoute} path="/checkAuthAndRedirect/*" />,

    <Route key="DashboardCallbackRouteArticle" Component={DashboardCallbackRoute} path="/dashboard/articlePdf/" />,
    <Route key="DashboardCallbackRouteInvoice" Component={DashboardCallbackRoute} path="/dashboard/invoicePdf/" />,
    <Route key="DashboardCallbackRouteLicense" Component={DashboardCallbackRoute} path="/license/printLicensePdf/" />,
    <Route key="ReviewPDFRoute" Component={ReviewPDFRoute} path="/license/licenseCopyPdf/" />,
    <Route key="PubEditorReviewRoute" Component={PubEditorReviewRoute} path="/license/verification/" />,
    <Route
        key="LicenseSigning"
        Component={LicenseSigningRoute}
        path="/license-signing/:articleId/:step?/:substep?/*"
    />,
    <Route key="OrdersFocusOnArticle" Component={OrdersFocusOnArticleRoute} path="/orders/focusOnArticle/:articleId" />,
    <Route key="Orders" Component={OrdersRoute} path="/orders" />,
    <Route key="AssignArticle" Component={AssignArticleRoute} path="/assign-article" />,
    <Route key="EmailAlertsPage" Component={EmailAlertsRoute} path="/alerts/:alertId" />,
    <Route key="NoMatch" Component={NoMatchRoute} path="*" />,
];
