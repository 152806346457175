import cn from 'classnames';
import filesize from 'filesize';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';

function ProgressBar({ children, className, percent }) {
    return (
        <div
            className={cn('uploadFileList-progressBar', className)}
            style={{
                position: 'relative',
                zIndex: 0,
            }}
        >
            <div
                className="uploadFileList-progressBarInner"
                style={{
                    bottom: '0',
                    left: '0',
                    position: 'absolute',
                    top: '0',
                    width: `${percent}%`,
                    zIndex: -1,
                }}
            />
            {children}
        </div>
    );
}

class FileItem extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        first: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        item: PropTypes.shape({ _id: PropTypes.string }).isRequired,
        l: PropTypes.func.isRequired,
        last: PropTypes.bool.isRequired,
        onDelete: PropTypes.func.isRequired,
        onMoveDown: PropTypes.func.isRequired,
        onMoveUp: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: undefined,
    };

    renderHeader(isLoading, isCompleted, isError, onDelete, onMoveUp, onMoveDown, item, index, first, last, l) {
        return (
            <div className="uploadFileList-itemHeader" data-seleniumid={`upload-license-file-item-${index}`}>
                {isLoading && (
                    <SvgIcon.loading
                        iconTitle="Loading Icon"
                        seleniumid={`upload-license-file-item-${index}-icon-loading`}
                    />
                )}
                {isCompleted && (
                    <SvgIcon.ok
                        iconTitle="Complete Icon"
                        seleniumid={`upload-license-file-item-${index}-icon-completed`}
                    />
                )}
                {isError && (
                    <SvgIcon.error iconTitle="Error Icon" seleniumid={`upload-license-file-item-${index}-icon-error`} />
                )}

                <div className="uploadFileList-itemDetails">
                    <div className="uploadFileList-fileName" data-seleniumid={`upload-license-file-item-name-${index}`}>
                        {item.file.name}
                    </div>

                    {/* TODO [data-seleniumid="uploadingLicenseItem-status"].text_grey.uploadingLicenseItem-status--completed */}
                    {/* is needed for qa tests, make them use data-seleniumid only */}
                    <div
                        className={cn('uploadingLicenseItem-status text_grey', {
                            'uploadingLicenseItem-status--completed': isCompleted,
                        })}
                        data-seleniumid="uploadingLicenseItem-status"
                    >
                        {isLoading && l('LICENSE_SIGNING.LICENSE_SUBMISSION.LABEL.UPLOADING')}
                        {isCompleted &&
                            l('LICENSE_SIGNING.LICENSE_SUBMISSION.LABEL.UPLOAD_COMPLETED', {
                                size: filesize(item.file.size, { base: 2 }),
                            })}
                        {isError && l('LICENSE_SIGNING.LICENSE_SUBMISSION.LABEL.UPLOAD_FAILED')}
                    </div>
                </div>

                <div className="uploadFileList-itemActions">
                    <button
                        className="btn"
                        data-seleniumid={`upload-license-file-item-link-delete-${index}`}
                        onClick={() => onDelete(item)}
                        type="button"
                    >
                        <SvgIcon.cancel iconTitle="Clear Icon" />
                    </button>
                    <button
                        className="btn"
                        data-seleniumid={`upload-license-file-item-link-up-${index}`}
                        onClick={() => onMoveUp(item)}
                        style={{ visibility: isError || first ? 'hidden' : 'visible' }}
                        type="button"
                    >
                        <SvgIcon.up iconTitle="Move up icon" />
                    </button>
                    <button
                        className="btn"
                        data-seleniumid={`upload-license-file-item-link-down-${index}`}
                        onClick={() => onMoveDown(item)}
                        style={{ visibility: isError || last ? 'hidden' : 'visible' }}
                        type="button"
                    >
                        <SvgIcon.down iconTitle="Move down icon" />
                    </button>
                </div>
            </div>
        );
    }

    render() {
        const { className, first, index, item, l, last, onDelete, onMoveDown, onMoveUp } = this.props;

        const percent = item.percentComplete || 0;
        const isLoading = Math.round(percent) < 100 && !item.isAborted;
        const isCompleted = Math.round(percent) >= 100 && !item.isAborted;
        const isError = item.error || !item.hasCorrectFileType || !item.hasCorrectFileSize || !item.hasCorrectFileName;

        const itemHeader = this.renderHeader(
            isLoading,
            isCompleted,
            isError,
            onDelete,
            onMoveUp,
            onMoveDown,
            item,
            index,
            first,
            last,
            l,
        );

        return (
            <div
                className={cn('uploadFileList-item', className, {
                    'uploadFileList-item--completed': isCompleted,
                    'uploadFileList-item--error': isError,
                })}
            >
                {isLoading ? <ProgressBar percent={percent}>{itemHeader}</ProgressBar> : itemHeader}

                {(item.error || !item.hasCorrectFileType || !item.hasCorrectFileSize || !item.hasCorrectFileName) && (
                    <div className="uploadFileList-itemErrors">
                        {item.error && (
                            <ErrorLabel
                                data-seleniumid={`upload-license-item-custom-error-${index}`}
                                text={`${item.error.message}${
                                    item.error.extendedMessage ? `<br/>(${item.error.extendedMessage})` : ''
                                }`}
                            />
                        )}
                        {!item.hasCorrectFileType && (
                            <ErrorLabel
                                data-seleniumid={`upload-license-item-wrong-type-error-${index}`}
                                text={l('ERROR.UPLOAD_LICENSE_ERROR_TYPES_WRONG_TYPE')}
                            />
                        )}
                        {!item.hasCorrectFileName && (
                            <ErrorLabel
                                data-seleniumid={`upload-license-item-duplicate-error-${index}`}
                                text={l('ERROR.UPLOAD_LICENSE_ERROR_TYPES_DUPLICATED_FILE_NAME')}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withCodes(FileItem, ID.ERROR, ID.LICENSE_SIGNING);
