import React, { Component } from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import InstitutionPicker from 'app/blocks/institution-picker/institution-picker';
import Address from 'app/ui/as-ui-components/address/Address';
import RadioButton from 'app/ui/form/RadioButton';
import { EditorAffiliation } from '../../../utils';

type Props = {
    affiliation: EditorAffiliation;
    blacklist: string[];
    getTextForNotConfirmedItem: (type?: string) => string;
    institutionsIdFromProfile: string[];
    onSelectInstitution: (value: any) => void;
    l: l;
    seleniumid: string;
};

class SuggestionSelector extends Component<Props> {
    state = {
        institutionPickerSelectedItem: {
            id: undefined,
            name: undefined,
        },
        selectedOption: null,
    };

    onChangeHandler = institution => {
        this.props.onSelectInstitution(institution);
        this.setState({ institutionPickerSelectedItem: institution });
    };

    onSelectSuggestion = institution => {
        this.props.onSelectInstitution(institution);
        this.setState({ selectedOption: institution.id });
    };

    onSelectOtherOption = () => {
        this.props.onSelectInstitution(this.state.institutionPickerSelectedItem);
        this.setState({ selectedOption: this.props.affiliation.id });
    };

    render() {
        const {
            affiliation: { id, suggestions = [] },
            blacklist,
            getTextForNotConfirmedItem,
            institutionsIdFromProfile,
            l,
            seleniumid,
        } = this.props;

        const { selectedOption } = this.state;

        return (
            <>
                <p
                    dangerouslySetInnerHTML={{ __html: getTextForNotConfirmedItem() }}
                    className="NotConfirmedInstitution-Text"
                    data-seleniumid={`${seleniumid}-suggestions-hint`}
                />
                <ul className="NotConfirmedInstitution-Options">
                    {suggestions.map(suggestion => {
                        const { id: suggestionId, name, ...otherSuggestionData } = suggestion;
                        const label = (
                            <>
                                <span className="Suggestion-Title">{name}</span>
                                <span className="Suggestion-Text">
                                    <Address data={otherSuggestionData} seleniumId={suggestionId} />
                                </span>
                            </>
                        );

                        return (
                            <li
                                key={suggestionId}
                                className="NotConfirmedInstitution-Option"
                                data-seleniumid={`${seleniumid}-institution-suggestion-option`}
                            >
                                <RadioButton
                                    checked={selectedOption === suggestionId}
                                    data-seleniumid={`${seleniumid}-institution-suggestion-${suggestionId}`}
                                    id={suggestionId}
                                    label={label}
                                    labelClassName="NotConfirmedInstitution-Label Suggestion"
                                    name={id}
                                    onChange={() => this.onSelectSuggestion(suggestion)}
                                />
                            </li>
                        );
                    })}
                    <li
                        className="NotConfirmedInstitution-Option"
                        data-seleniumid={`${seleniumid}-institution-suggestion-option`}
                    >
                        <RadioButton
                            checked={selectedOption === id}
                            data-seleniumid={`${seleniumid}-institution-suggestion-other`}
                            id={id}
                            label={<span className="Suggestion-Title">Search for a different affiliation</span>}
                            labelClassName="NotConfirmedInstitution-Label Suggestion"
                            name={id}
                            onChange={this.onSelectOtherOption}
                        />

                        <span className="NotConfirmedInstitution-InstitutionPicker">
                            <InstitutionPicker
                                blacklist={blacklist}
                                changeHandler={institution => this.onChangeHandler(institution)}
                                institutionsIdFromProfile={institutionsIdFromProfile}
                                onFocus={this.onSelectOtherOption}
                                placeholder={l('ARTICLE_AFFILIATION_EDITOR.PICKER_PLACEHOLDERS.SEARCH')}
                                selectedItem={this.state.institutionPickerSelectedItem}
                                seleniumid={`${seleniumid}-institution-suggestion-other`}
                            />
                        </span>
                    </li>
                </ul>
            </>
        );
    }
}

export default withCodes(SuggestionSelector, ID.ARTICLE_AFFILIATION_EDITOR);
