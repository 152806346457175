import { autobind } from 'core-decorators';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import Article from 'app/blocks/article/article';
import { Layout, Title, WhiteBlock } from 'app/blocks/blocks';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import * as middleware from 'app/blocks/middleware/article-assignment';
import * as middlewareCommon from 'app/blocks/middleware/middleware';
import MiniSearch from 'app/blocks/mini-search/MiniSearch';
import MiniSearchItem from 'app/blocks/mini-search/MiniSearchItem';
import { SubTitle } from 'app/blocks/titles/titles';
import ArticleAssignmentResult from 'app/pages/assign-article/_result/assign-article_result';
import { Input2 } from 'app/ui/form/inputs';
import RadioButton from 'app/ui/form/RadioButton';
import './article-assignment-by-id.scss';

const fuseOptions = {
    findAllMatches: false,
    ignoreLocation: true,
    includeMatches: false,
    isCaseSensitive: false,
    keys: ['name'],
    minMatchCharLength: 3,
    shouldSort: true,
    threshold: 0.0,
};

let fuseInstance = null;

@autobind
class ArticleAssignmentById extends React.Component {
    static propTypes = {
        articleId: PropTypes.string.isRequired,
        gotoDashboard: PropTypes.func.isRequired,
    };

    state = {
        ArticleAndJournal: {},
        authorSearchValue: null,
        authorSelected: false,
        authors: [],
        correspondingAuthor: null,
        filteredAuthors: [],
        isAssigning: false,
        isLoading: true,
        note: null,
        selectedParticipantId: null,
        showNotListed: false,
    };

    authorSearchRadioRef = React.createRef();

    @processing('isLoading')
    async componentDidMount() {
        try {
            const ArticleAndJournal = await middlewareCommon.product.getArticleAndJournal(this.props.articleId);
            const authorPayload = await middlewareCommon.product.getAuthors(this.props.articleId);
            this.setState({ ArticleAndJournal, authors: this.modifyAuthorData(authorPayload) });
            this.createFuseInstance(authorPayload);
        } catch (error) {
            showDialog.addErrorMessageDialog(error, this.cancel);
        }
    }

    handleSearchAreaClick() {
        this.authorSearchRadioRef.click();
    }

    handleSearch(query) {
        const selectedParticipantName = query;
        this.setState(prevState => ({
            ...prevState,
            authorSearchValue: selectedParticipantName,
        }));

        if (query.length + 1 === 1) {
            this.setState(prevState => ({
                ...prevState,
                filteredAuthors: prevState.authors,
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                filteredAuthors: this.processSearchData(prevState.authors, query),
            }));
        }

        if (query && query.length === 0) {
            this.setState(prevState => ({
                ...prevState,
                filteredAuthors: prevState.authors,
            }));
        }
    }

    handleSearchFocusOut() {
        this.setState(prevState => ({ ...prevState, filteredAuthors: prevState.authors }));
    }

    onChangeTextArea(note) {
        this.setState(prevState => ({ ...prevState, note }));
    }

    onClickHandle(event) {
        const active = document.querySelector('.article_author_active');
        if (active) {
            active.classList.remove('article_author_active');
        }
        event.target.parentElement.parentElement.parentElement.classList.toggle('article_author_active');

        if (event.target.value === 'not_listed') {
            this.setState(prevState => ({ ...prevState, authorSelected: false, showNotListed: true }));
        } else {
            this.setState(prevState => ({ ...prevState, authorSelected: true, showNotListed: false }));
        }
    }

    onSearchAuthorSelect(data) {
        const selectedParticipantId = data.participantId;
        const selectedParticipantName = data.name;
        this.setState(prevState => ({
            ...prevState,
            authorSearchValue: selectedParticipantName,
            selectedParticipantId,
        }));
    }

    onAuthorSelect(event) {
        const selectedParticipantId = event.target.value;
        this.setState(prevState => ({ ...prevState, selectedParticipantId }));
    }

    /**
     * This is only used to create a fusejs instance
     * @param authorData
     */
    createFuseInstance(authorData) {
        fuseInstance = new Fuse(authorData, fuseOptions);
    }

    modifyAuthorData(authors) {
        if (authors) {
            return authors.map(author => {
                const ad = author;
                ad.name = `${ad.firstName} ${ad.lastName}`;
                delete ad.firstName;
                delete ad.lastName;
                return ad;
            });
        }
        return [];
    }

    processSearchData(authors, query) {
        return fuseInstance.search(query).map(author => author.item);
    }

    dataModify(state) {
        if (state.selectedParticipantId) {
            return { selectedParticipantId: state.selectedParticipantId };
        }
        return { correspondingAuthor: state.correspondingAuthor, note: state.note };
    }

    @tryCatch.popUpOnly
    @processing('isAssigning')
    async assignArticle() {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            result: await middleware.assignArticle(this.props.articleId, this.dataModify(this.state)),
        });
    }

    isReadyToSubmit() {
        const { authorSelected, correspondingAuthor, isAssigning, isLoading, selectedParticipantId } = this.state;

        return (
            (!isAssigning && !isLoading && _.isBoolean(correspondingAuthor)) ||
            (authorSelected && selectedParticipantId)
        );
    }

    render() {
        const { gotoDashboard } = this.props;
        const {
            ArticleAndJournal,
            authorSelected,
            authors,
            correspondingAuthor,
            filteredAuthors,
            isAssigning,
            isLoading,
            result,
            showNotListed,
        } = this.state;
        return (
            <div className="container-lg">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <Title seleniumid="assign-article-page">Find your missing article</Title>
                        {result ? (
                            <ArticleAssignmentResult gotoDashboard={gotoDashboard} result={result} />
                        ) : (
                            <div>
                                <Layout isLoading={isLoading}>
                                    <WhiteBlock className="page p-2x">
                                        <SubTitle className="m-btm_normal">Article details</SubTitle>

                                        <Article
                                            article={ArticleAndJournal.article}
                                            journal={ArticleAndJournal.journal}
                                            seleniumid="assign-"
                                        />
                                    </WhiteBlock>
                                    <WhiteBlock className="page p-2x m-top_md">
                                        <SubTitle className="m-btm_normal">Which author are you?</SubTitle>
                                        <div className="m-btm_mini" data-seleniumid="action-criteria">
                                            You may be asked to confirm your identity with our support team before the
                                            article gets added to your dashboard.
                                        </div>
                                        <ul className="AssignArticleByIdSelect">
                                            {authors.length > 20 && (
                                                <li>
                                                    <RadioButton
                                                        ref={input => {
                                                            this.authorSearchRadioRef = input;
                                                        }}
                                                        checked={authorSelected}
                                                        data-seleniumid="authorSearch-radio"
                                                        id="authorSearch-radio"
                                                        label={
                                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                                            <div
                                                                className="menu__search_panel AssignArticleAuthorSearch"
                                                                onClick={() => this.handleSearchAreaClick()}
                                                            >
                                                                <label
                                                                    className="label hd"
                                                                    htmlFor="authorSearch"
                                                                    style={{ fontStyle: 'italic' }}
                                                                >
                                                                    Author List
                                                                </label>
                                                                <MiniSearch
                                                                    defaultValue={this.state.authorSearchValue}
                                                                    focusedOut={() => this.handleSearchFocusOut()}
                                                                    onInput={value => this.handleSearch(value)}
                                                                    searchResults="none"
                                                                    seleniumId="author-list-dropdown"
                                                                    withSub
                                                                >
                                                                    {filteredAuthors.map(author => (
                                                                        <div key={author.participantId}>
                                                                            <MiniSearchItem
                                                                                key={author.participantId}
                                                                                dataSeleniumId={`author-${author.name}`}
                                                                            >
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.onSearchAuthorSelect({
                                                                                            name: `${author.name}`,
                                                                                            participantId:
                                                                                                author.participantId,
                                                                                        })
                                                                                    }
                                                                                    type="button"
                                                                                >
                                                                                    {author.name}
                                                                                </button>
                                                                            </MiniSearchItem>
                                                                        </div>
                                                                    ))}
                                                                </MiniSearch>
                                                            </div>
                                                        }
                                                        name="author_action"
                                                        onClick={event => {
                                                            this.onClickHandle(event);
                                                        }}
                                                        value="author_action"
                                                    />
                                                </li>
                                            )}
                                            {authors.length <= 20 && (
                                                <>
                                                    {authors.map(author => (
                                                        <li key={author.participantId}>
                                                            <RadioButton
                                                                data-seleniumid={`author-rb-${author.participantId}`}
                                                                label={
                                                                    <span className="label selection-label">
                                                                        {author.name}
                                                                    </span>
                                                                }
                                                                name="author_action"
                                                                onClick={event => {
                                                                    this.onClickHandle(event);
                                                                    this.onAuthorSelect(event);
                                                                }}
                                                                value={author.participantId}
                                                            />
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                            <li>
                                                <RadioButton
                                                    data-seleniumid="author-not-on-the-list"
                                                    label={
                                                        <span
                                                            className="label selection-label hd"
                                                            style={{ fontStyle: 'italic' }}
                                                        >
                                                            I am not listed
                                                        </span>
                                                    }
                                                    name="author_action"
                                                    onClick={event => this.onClickHandle(event)}
                                                    value="not_listed"
                                                />
                                                {showNotListed && (
                                                    <div className="AssignArticleAuthorTextAreaWrap">
                                                        <p>
                                                            Only credited authors may add this paper to their dashboard.
                                                            In order to assist you in adding this article to your
                                                            account, tell us more about your role on this paper:
                                                        </p>
                                                        <span className="label hd">Role in this paper</span>
                                                        <Input2
                                                            data-seleniumid="role-in-article-input"
                                                            onChange={value => this.onChangeTextArea(value)}
                                                            type="textarea"
                                                        />
                                                        <br />
                                                        <div className="label hd">
                                                            Are you a corresponding author on this paper?{' '}
                                                            <i className="required" style={{ marginLeft: '10px' }}>
                                                                required
                                                            </i>
                                                        </div>
                                                        <div className="AssignArticleByIdTxtSeparate">
                                                            <RadioButton
                                                                checked={correspondingAuthor === true}
                                                                data-seleniumid="assign-article_by-id-radio-corr-author"
                                                                id="assign-article_by-id-radio-corr-author"
                                                                label="I am a corresponding author"
                                                                name="assign-article_by-id-radio"
                                                                onChange={() =>
                                                                    this.setState({ correspondingAuthor: true })
                                                                }
                                                                style={{ display: 'inline-flex', marginRight: '30px' }}
                                                                value
                                                            />
                                                        </div>
                                                        <div className="AssignArticleByIdTxtSeparate">
                                                            <RadioButton
                                                                checked={correspondingAuthor === false}
                                                                data-seleniumid="assign-article_by-id-radio-co-author"
                                                                id="assign-article_by-id-radio-co-author"
                                                                label="I am a co-author"
                                                                name="assign-article_by-id-radio"
                                                                onChange={() =>
                                                                    this.setState({ correspondingAuthor: false })
                                                                }
                                                                style={{ display: 'inline-flex' }}
                                                                value={false}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                        </ul>
                                    </WhiteBlock>
                                </Layout>
                                <div className="clearfix" />
                                <hr className="AdvanceSearchButtonsHr" />
                                <div className="clearfix" />
                                <div className="m-top_md">
                                    <div className="pull-right">
                                        <PrimaryButton
                                            data-seleniumid="assign-article-confirm-button"
                                            disabled={!this.isReadyToSubmit()}
                                            onClick={this.assignArticle}
                                        >
                                            {isAssigning ? (
                                                'Assigning Article...'
                                            ) : (
                                                <>
                                                    Add missing article
                                                    <SvgIcon.next iconTitle="next Icon" style={{ marginLeft: '8px' }} />
                                                </>
                                            )}
                                        </PrimaryButton>
                                    </div>

                                    <span className="clearfix" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleAssignmentById;
