import PropTypes from 'prop-types';
import React from 'react';
import { renderToString } from 'react-dom/server';
import _ from 'underscore';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import * as discountMiddleware from 'app/blocks/middleware/discount';
import OrdersRow from 'app/blocks/Row/Row';
import withDiscountContainer from './withDiscountContainer';

const DiscountTypeEnum = {
    SOCIETY: 'society',
    WILEY: 'wiley',
};

class Discount extends React.Component {
    static propTypes = {
        currency: PropTypes.string.isRequired,
        discountDetails: PropTypes.shape({
            institution: PropTypes.string,
            institutionId: PropTypes.number,
            societyId: PropTypes.number,
            societyPromoCode: PropTypes.string,
            wileyPromoCode: PropTypes.string,
        }).isRequired,
        discountsError: PropTypes.bool.isRequired,
        handleOtherPromoCodeChange: PropTypes.func.isRequired,
        handleSocietyPromoCodeChange: PropTypes.func.isRequired,
        hasAdditionalDiscounts: PropTypes.bool.isRequired,
        hasSocietyDiscounts: PropTypes.bool.isRequired,
        isValidating: PropTypes.bool.isRequired,
        journalId: PropTypes.string.isRequired,
        l: PropTypes.func.isRequired,
        onSocietyPromoCodeFocus: PropTypes.func,
        onWileyPromoCodeFocus: PropTypes.func,
        panelName: PropTypes.string,
        validationResults: PropTypes.shape({}),
    };

    static defaultProps = {
        onSocietyPromoCodeFocus: () => {},
        onWileyPromoCodeFocus: () => {},
        panelName: undefined,
        validationResults: {},
    };

    async getDiscountHelpTextsAsList(params, defaultHelp) {
        let lines = [];

        try {
            lines = await discountMiddleware.getDiscountHelpTexts(params);
        } catch (error) {
            console.error(error);
        }

        if (lines.length === 0) {
            lines = [defaultHelp];
        }

        return renderToString(
            <ul className="discount-help-text">
                {lines.map(item => (
                    <li key={_.uniqueId()} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
            </ul>,
        );
    }

    render() {
        const {
            currency,
            discountDetails,
            discountsError,
            handleOtherPromoCodeChange,
            handleSocietyPromoCodeChange,
            hasAdditionalDiscounts,
            hasSocietyDiscounts,
            isValidating,
            journalId,
            l,
            onSocietyPromoCodeFocus,
            onWileyPromoCodeFocus,
            panelName,
            validationResults,
        } = this.props;

        if (isValidating) {
            return <Layout isLoading />;
        }

        return (
            <>
                <div className={`${panelName}-Text`}>{l('ORDER_PANELS.PANEL_DISCOUNT.TITLE')}</div>
                <div className={`${panelName}-Form`}>
                    {!discountsError && hasSocietyDiscounts && (
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.societyPromoCode}
                            formSeleniumid="order-discount"
                            label={l('ORDER_PANELS.PANEL_DISCOUNT.SOCIETY_PROMO_CODE_LABEL')}
                            name="societyPromoCode"
                            onChange={handleSocietyPromoCodeChange}
                            onFocus={onSocietyPromoCodeFocus}
                            placeholder={l('ORDER_PANELS.PANEL_DISCOUNT.SOCIETY_PROMO_CODE_PLACEHOLDER')}
                            prompt={{
                                getBody: () =>
                                    this.getDiscountHelpTextsAsList(
                                        {
                                            currency,
                                            discountType: DiscountTypeEnum.SOCIETY,
                                            journalId,
                                        },
                                        l('TOOLTIP.society'),
                                    ),
                                seleniumid: 'order-discount-societyPromoCode__prompt-icon',
                            }}
                            value={discountDetails.societyPromoCode}
                        />
                    )}

                    {hasAdditionalDiscounts && (
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.wileyPromoCode}
                            formSeleniumid="order-discount"
                            isOptional
                            label={l('ORDER_PANELS.PANEL_DISCOUNT.ADDITIONAL_CODES')}
                            name="additionalPromoCode"
                            onChange={handleOtherPromoCodeChange}
                            onFocus={onWileyPromoCodeFocus}
                            placeholder={l('ORDER_PANELS.PANEL_DISCOUNT.ADDITIONAL_CODES_PLACEHOLDER')}
                            prompt={{
                                getBody: () =>
                                    this.getDiscountHelpTextsAsList(
                                        {
                                            currency,
                                            discountType: DiscountTypeEnum.WILEY,
                                            journalId,
                                        },
                                        l('TOOLTIP.wiley'),
                                    ),
                                seleniumid: 'order-discount-additionalPromoCode__prompt-icon',
                            }}
                            value={discountDetails.wileyPromoCode}
                        />
                    )}
                </div>
            </>
        );
    }
}

export { Discount };
export default compose(withDiscountContainer, withCodes2(ID.ORDER_PANELS, ID.TOOLTIP))(Discount);
