import React from 'react';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import routes from 'app/pages/routes';

type Props = Either<{ text: string; message: string }> & { refId?: string };

export default function Message(props: Props): null {
    React.useEffect(() => {
        const message = props.text || props.message;

        if (message && message.length !== 0) {
            showDialog.error({
                message,
                onClose: () => {
                    routes.navigateToDashboard();
                },
                refId: props.refId || '',
            });
        }
    }, [props.message, props.refId, props.text]);

    return null;
}
