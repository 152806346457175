import cn from 'classnames';
import React, { PureComponent } from 'react';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import * as discountMiddleware from 'app/blocks/middleware/discount';
import DiscountsTypeSection from './DiscountsTypeSection';

import './orderDiscounts.scss';

const DiscountTypes: DiscountType[] = ['society', 'wiley'];

type Props = {
    className?: string;
    journalId: string;
    l: l;
};

type State = {
    isLoadingError?: Error;
    discountTexts: Array<{
        content: string[];
        title: string;
        type: string;
    }>;
};

export class OrderDiscounts extends PureComponent<Props, State> {
    static getDerivedStateFromProps(props, state) {
        if (!('prevJournalId' in state) || props.journalId !== state.prevJournalId) {
            return {
                discountTexts: [],
                isLoadingError: null,
                prevJournalId: props.journalId,
            };
        }
        return null;
    }

    state: State = {
        discountTexts: [],
    };

    componentDidMount() {
        this.getDiscountsTexts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.journalId !== this.props.journalId) {
            this.getDiscountsTexts();
        }
    }

    @tryCatch({ errorHandler: () => {} })
    @processing('isLoading')
    async getDiscountsTexts() {
        const { journalId, l } = this.props;

        const texts = await Promise.all(
            DiscountTypes.map(type => discountMiddleware.getDiscountWidgetTexts(journalId, type)),
        );

        const discountTexts = texts
            .map((item, index) => ({
                content: item,
                title: l(`${ID.DISCOUNT_WIDGET}.TYPES.${DiscountTypes[index].toUpperCase()}`),
                type: DiscountTypes[index],
            }))
            .filter(item => item.content && item.content.length);

        this.setState({ discountTexts });
    }

    render() {
        const { className, l } = this.props;
        const { discountTexts, isLoadingError } = this.state;

        if (isLoadingError || (discountTexts.length === 0 && !isLoadingError)) return null;

        return (
            <section className={cn('orderDiscounts', className)} data-seleniumid="order_discounts">
                <h2 className="orderDiscounts-title" data-seleniumid="order_discounts__prompt-title">
                    {l(`${ID.DISCOUNT_WIDGET}.TITLE`)}
                </h2>

                <p className="orderDiscounts-subtitle">{l(`${ID.DISCOUNT_WIDGET}.SUBTITLE`)}</p>
                <div className="DiscountsTypes">
                    {isLoadingError ? (
                        <Layout error={isLoadingError} />
                    ) : (
                        discountTexts.map(item => (
                            <div key={item.type}>
                                <DiscountsTypeSection
                                    content={item.content}
                                    data-seleniumid={`order_discounts__tab_${item.type}`}
                                    title={item.title}
                                />
                            </div>
                        ))
                    )}
                </div>

                <p
                    className="orderDiscounts-footer"
                    dangerouslySetInnerHTML={{ __html: l(`${ID.DISCOUNT_WIDGET}.FOOTER`) }}
                />
            </section>
        );
    }
}

export default withCodes2(ID.DISCOUNT_WIDGET)(OrderDiscounts);
