import React from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import './custom-dropzone.scss';

type Props = {
    actions: Pick<
        DropzoneProps,
        'onDragEnter' | 'onDragLeave' | 'onDragOver' | 'onDrop' | 'onDropAccepted' | 'onDropRejected'
    >;
    children: DropzoneProps['children'];
    enabledOnDropEnter?: boolean;
    isDropEnabled?: boolean;
};

export default class DropZoneWrapper extends React.Component<Props, { highlightBorder: boolean }> {
    state = {
        highlightBorder: false,
    };

    subscriptions: Array<() => void> = [];

    componentDidMount() {
        const onDragEnter = (event: DragEvent) => {
            this.setState({ highlightBorder: true }, () => this.outsideZoneHandler(event));
        };
        const onDragOver = (event: DragEvent) => {
            this.outsideZoneHandler(event);
        };
        const onDrop = (event: DragEvent) => {
            this.setState({ highlightBorder: false }, () => this.outsideZoneHandler(event));
        };
        const onDragLeave = (event: DragEvent) => {
            const top = event.pageY;
            const right = document.body.clientWidth - event.pageX;
            const bottom = document.body.clientHeight - event.pageY;
            const left = event.pageX;

            if (top <= 10 || right <= 20 || bottom <= 10 || left <= 20) {
                this.setState({ highlightBorder: false });
            }
        };

        window.addEventListener('dragenter', onDragEnter);
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', onDrop);
        document.body.addEventListener('dragleave', onDragLeave);

        this.subscriptions.push(() => {
            window.removeEventListener('dragenter', onDragEnter);
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', onDrop);
            document.body.removeEventListener('dragleave', onDragLeave);
        });
    }

    componentWillUnmount() {
        this.subscriptions.forEach(fn => fn());
    }

    outsideZoneHandler(event: DragEvent) {
        const isDataEnabled = !!(event.target as Element).closest('div[data-enabled="yes"]');

        if (!isDataEnabled) {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.effectAllowed = 'none';
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.dropEffect = 'none';
        }
    }

    render() {
        const {
            actions: { onDragEnter, onDragLeave, onDragOver, onDrop, onDropAccepted, onDropRejected },
            children,
            enabledOnDropEnter,
            isDropEnabled,
        } = this.props;
        const { highlightBorder } = this.state;

        return (
            <Dropzone
                disabled={!isDropEnabled || !enabledOnDropEnter}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={(...args) => isDropEnabled && onDrop(...args)}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                // @ts-ignore
                style={{
                    borderColor: highlightBorder ? '#4caf50' : '#666',
                    borderWidth: highlightBorder ? 3 : 2,
                }}
            >
                {children}
            </Dropzone>
        );
    }
}
