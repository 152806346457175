import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { Input } from 'app/ui/form';
import './password.scss';

type Props = {
    className?: string;
    classNameButton?: string;
    classNameButtonIcon?: string;
    classNameComponent?: string;
    isError?: boolean;
    inputRef?: React.RefCallback<any>;
    seleniumid?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

type State = {
    type: 'text' | 'password';
};

class Password extends React.PureComponent<Props, State> {
    static defaultProps = {
        className: '',
        classNameButton: '',
        classNameButtonIcon: '',
        classNameComponent: '',
        inputRef: () => {},
        isError: false,
        seleniumid: '',
    };

    state: State = { type: 'password' };

    inputField = null;

    toggleInputType = () => {
        this.inputField.focus();

        this.setState(state => ({ type: state.type === 'password' ? 'text' : 'password' }));
    };

    toggleInputTypeWoBlur = e => {
        e.preventDefault();
        this.toggleInputType();
    };

    render() {
        const { type } = this.state;

        const {
            className,
            classNameButton,
            classNameButtonIcon,
            classNameComponent,
            id,
            inputRef,
            seleniumid,
            ...otherProps
        } = this.props;

        return (
            <div className={`PasswordField ${classNameComponent}`}>
                <label className="visuallyHidden" htmlFor="password">
                    Password:
                </label>
                <Input
                    {...otherProps}
                    ref={el => {
                        this.inputField = el;
                        inputRef(el);
                    }}
                    autoCorrect="off"
                    className={`PasswordField-Input ${className}`}
                    data-private
                    data-seleniumid={seleniumid}
                    id={id}
                    type={type}
                />
                {this.props.value && (
                    <button
                        className={`PasswordField-Button ${classNameButton}`}
                        onClick={this.toggleInputType}
                        onTouchEndCapture={this.toggleInputTypeWoBlur}
                        type="button"
                    >
                        {type === 'password' ? (
                            <SvgIcon.eyeShow
                                className={`PasswordField-ButtonIcon ${classNameButtonIcon}`}
                                height="20"
                                iconTitle="Show text"
                                width="20"
                            />
                        ) : (
                            <SvgIcon.eyeHide
                                className={`PasswordField-ButtonIcon ${classNameButtonIcon}`}
                                height="20"
                                iconTitle="Hide text"
                                width="20"
                            />
                        )}
                    </button>
                )}
            </div>
        );
    }
}

export default Password;
