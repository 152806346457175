import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { keyProps } from 'app/blocks/common/decorators';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import PageLayout from 'app/blocks/page/PageLayout';
import StepsNavigation from 'app/pages/license-signing-process/StepsNavigation/StepsNavigation';
import AlertsPanelContainer from './alerts/alerts-panel-container';
import ProfileInformation from './profile-information/profile-information';
import StepEnum from './ProfileStepsEnum';

import './profile.scss';

const steps = [
    { id: StepEnum.PROFILE, name: 'My profile', seleniumid: 'profile', url: '/profile' },
    { id: StepEnum.ALERTS, name: 'Alerts', seleniumid: 'alert', url: `/profile?section=${StepEnum.ALERTS}` },
];

@keyProps('section')
class ProfilePage extends PureComponent {
    static propTypes = {
        l: PropTypes.func.isRequired,
        section: PropTypes.string,
    };

    static defaultProps = {
        section: StepEnum.PROFILE,
    };

    state = {};

    renderStep() {
        switch (this.props.section) {
            case StepEnum.PROFILE:
                return <ProfileInformation />;
            case StepEnum.ALERTS:
                return <AlertsPanelContainer />;
            default:
                return null;
        }
    }

    render() {
        const { l } = this.props;

        return (
            <div className="ProfileMenuBlock">
                <PageLayout
                    seleniumid="profile-blockTitle"
                    title={l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_LABEL')}
                >
                    <div className="ProfileMenuBlock-MenuBlock Stacking">
                        <div>
                            <StepsNavigation activeStep={this.props.section} steps={steps} />
                        </div>
                        <div className="ProfileMenuBlock-ContentBlock Stacking">{this.renderStep()}</div>
                    </div>
                </PageLayout>
            </div>
        );
    }
}

export default compose(withConfirmLeaving, withCodes2(ID.PROFILE_ACCOUNT, ID.DASHBOARD_LEFT_SIDE))(ProfilePage);
