import cn from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import FunderPicker from 'app/blocks/funder-picker/funder-picker';
import FunderPanelContext2 from 'app/blocks/Panel/funder/FunderPanelContext2';

class ConfirmedItem extends Component {
    static propTypes = {
        l: PropTypes.func.isRequired,
    };

    onCancelClick = async e => {
        e.target.blur();
        await this.props.cancelUpdate(this.props.program._id);
    };

    onEditClick = async e => {
        e.target.blur();
        await this.props.editResearchFunder(this.props.program._id);
    };

    onFunderChange = async researchFunder => {
        const {
            cancelUpdate,
            confirmUpdate,
            program: { _id },
            updateResearchFunder,
        } = this.props;

        try {
            await updateResearchFunder(_id, researchFunder);
            await confirmUpdate(_id);
        } catch (e) {
            if (e.code === 'UPDATE_CANCELED') {
                await cancelUpdate(_id);
            } else if (e.code === 'UPDATE_SKIPPED') {
                // do nothing
            } else {
                throw e;
            }
        }
    };

    render() {
        const {
            fundersPromise,
            index,
            isEditing,
            l,
            onResearchFunderInputFocus,
            program,
            removeResearchFunder,
            validation,
        } = this.props;

        return (
            <li
                className={cn('ConfirmedInstitution', {
                    'ConfirmedIinstitution--custom': get(program, 'researchFunder.custom'),
                })}
            >
                <div className="ConfirmedInstitution-Card">
                    {isEditing ? (
                        <div className="ConfirmedInstitution-InstitutionPicker">
                            <FunderPicker
                                disabled={false}
                                error={validation.researchFunder ? l(validation.researchFunder) : ''}
                                funder={program.researchFunder}
                                fundersPromise={fundersPromise}
                                onFocus={() => onResearchFunderInputFocus(program._id)}
                                onFunderChange={this.onFunderChange}
                                seleniumid={`funderPicker-${index}`}
                            />
                        </div>
                    ) : (
                        <div className="ConfirmedInstitution-Header">
                            <h3 className="ConfirmedInstitution-Title" data-seleniumid={`funderName-${index}`}>
                                {program.researchFunder.name}
                            </h3>
                        </div>
                    )}
                    <div className="ConfirmedInstitution-Buttons">
                        {isEditing ? (
                            <LinkButton
                                className="article_affiliation__cancel"
                                data-seleniumid={`cancelFunderUpdateButton-${index}`}
                                isBlack
                                onClick={this.onCancelClick}
                            >
                                <SvgIcon.cancel iconTitle="Cancel Icon" />
                                Cancel
                            </LinkButton>
                        ) : (
                            <LinkButton
                                className="article_affiliation__edit"
                                data-seleniumid={`editFunderButton-${index}`}
                                isBlack
                                onClick={this.onEditClick}
                            >
                                <SvgIcon.edit iconTitle="Edit Icon" />
                                Edit
                            </LinkButton>
                        )}
                        <LinkButton
                            className="article_affiliation__delete"
                            data-seleniumid={`deleteFunderButton-${index}`}
                            isBlack
                            onClick={() => removeResearchFunder(program._id)}
                        >
                            <SvgIcon.delete iconTitle="Delete Icon" />
                            Delete
                        </LinkButton>
                    </div>
                </div>
            </li>
        );
    }
}

export default compose(
    FunderPanelContext2.withContext([
        'cancelUpdate',
        'confirmUpdate',
        'editResearchFunder',
        'fundersPromise',
        'onResearchFunderInputFocus',
        'removeResearchFunder',
        'updateResearchFunder',
    ]),
    withCodes2(ID.FUNDER, ID.LICENSE_SIGNING),
)(ConfirmedItem);
