import { autobind } from 'core-decorators';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { markAsChanged, markAsUnchanged } from 'app/blocks/common/decorators';
import { compose, cleanFields } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import StaticContent from 'app/blocks/static-content/static-content';
import { BackButton } from 'app/pages/license-signing-process/buttons';
import Context from 'app/pages/license-signing-process/context/Context';
import { SectionTitle } from 'app/pages/license-signing-process/styles';
import LicenseText from './LicenseText';

import './ElectronicLicenseSubmission.scss';

@autobind
class ElectronicLicenseSubmission extends React.PureComponent {
    static propTypes = {
        article: PropTypes.shape({ id: PropTypes.string }).isRequired,
        confirmElectronicLicenseSubmission: PropTypes.func.isRequired,
        getPrevStep: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        isLicenseSubmissionConfirming: PropTypes.bool.isRequired,
        journal: PropTypes.shape({}).isRequired,
        l: PropTypes.func.isRequired,
        licenseType: PropTypes.string.isRequired,
        ownership: PropTypes.shape({
            copyrightOwnership: PropTypes.string,
            copyrightOwnershipSubtype: PropTypes.string,
        }).isRequired,
        setChangesChecker: PropTypes.func.isRequired,
    };

    state = {
        authorSignature: '',
        isAgree: false,
    };

    componentDidMount() {
        this.props.setChangesChecker(() => this.isChanged);
    }

    onCancelSigning() {}

    @markAsChanged
    onChangeAgree(isAgree) {
        this.setState({ isAgree });
    }

    @markAsChanged
    onChangeSignature(authorSignature) {
        this.setState({ authorSignature });
    }

    @markAsUnchanged
    async onConfirm() {
        await cleanFields(this, ({ authorSignature }) => ({ authorSignature }));
        await this.props.confirmElectronicLicenseSubmission(this.state.authorSignature);
    }

    render() {
        const { article, getPrevStep, goBack, isLicenseSubmissionConfirming, l, licenseType, ownership } = this.props;

        const { authorSignature, isAgree } = this.state;

        const canGoNext = isAgree && validation.hasAnySymbol(authorSignature) && !isLicenseSubmissionConfirming;

        const categorySelector = ownership?.copyrightOwnershipSubtype
            ? `${ownership?.copyrightOwnership}.ITEMS.${ownership?.copyrightOwnershipSubtype}`
            : ownership?.copyrightOwnership;

        return (
            <>
                <section className="ElectronicLicenseSubmission">
                    <SectionTitle className="ElectronicLicenseSubmission-Title">
                        {l('LICENSE_SIGNING.LICENSE_SUBMISSION.TITLE.MAIN')}
                    </SectionTitle>

                    <div className="ElectronicLicenseSubmission-SubTitle">
                        {l('LICENSE_SIGNING.LICENSE_SUBMISSION.TITLE.SUBTITLE')}
                    </div>

                    <StaticContent
                        className="ElectronicLicenseSubmission-Static"
                        params={{ ownership: l(`LICENSE_SUBMISSION.${categorySelector}.TITLE`) }}
                        seleniumid="Contributing_Authors-prompt"
                        src="license__Contributing_Authors.html"
                    />

                    <LicenseText
                        article={article}
                        cancelSign={this.onCancelSigning}
                        copyrightOwnership={ownership.copyrightOwnership}
                        isAgree={isAgree}
                        licenseType={licenseType}
                        name={authorSignature}
                        onChangeAgree={this.onChangeAgree}
                        onChangeName={this.onChangeSignature}
                    />
                </section>

                <div className="licenseSigningProcessPage-backAndForth">
                    <div className="page-controls">
                        <BackButton onClick={goBack}>{getPrevStep().name}</BackButton>

                        <PrimaryButton
                            data-seleniumid="goForthButton"
                            disabled={!canGoNext}
                            isProcessing={isLicenseSubmissionConfirming}
                            onClick={this.onConfirm}
                            processingLabel={l('BUTTONS.SUBMITTING')}
                        >
                            {l('BUTTONS.SUBMIT_LICENSE_FOR_REVIEW')}
                        </PrimaryButton>
                    </div>
                </div>
            </>
        );
    }
}

export { ElectronicLicenseSubmission };

export default compose(
    withScrollToTop,
    withConfirmLeaving,
    Context.withContext(state => ({
        article: state.article,
        authorSelectedOnlineOpen: get(state, 'all.openAccessOption.authorSelectedOnlineOpen'),
        confirmElectronicLicenseSubmission: state.confirmElectronicLicenseSubmission,
        getPrevStep: state.getPrevStep,
        goBack: state.goBack,
        isLicenseSubmissionConfirming: get(state, 'isLicenseSubmissionConfirming', false),
        journal: state.journal,
        licenseType: get(state, 'all.licenseAgreementTypes.licenseChoices[0].licenseType.value'),
        ownership: get(state, 'all.copyrightOwnership.copyrightOwnerships[0]'),
    })),
    withCodes2(ID.BUTTONS, ID.LICENSE, ID.LICENSE_SIGNING, ID.LICENSE_SUBMISSION),
)(ElectronicLicenseSubmission);
