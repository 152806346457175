import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import Value from 'app/pages/orders/value';
import './index.scss';

function OrdersPanelPriceInfo({ className, isNew, isShowTax, isShowTotalAmountBeforeTax, l, labels, prices }) {
    const totalAmount = isShowTotalAmountBeforeTax ? prices.totalAmountBeforeTax : prices.totalAmount;

    return (
        <div
            className={cn('ordersPanelPriceInfo', className, {
                'ordersPanelPriceInfo--new': isNew,
                'ordersPanelPriceInfo--old': !isNew,
            })}
            data-seleniumid="order-price-panel-review"
        >
            <div>
                <div className="ordersPanelPriceInfo-caption">{l('ORDER_PANELS.PANEL_PRICE_INFO.CHARGE_TITLE')}</div>
                <div className="ordersPanelPriceInfo-value">
                    <Value currency={prices.currency} seleniumId="order-apc-review" value={prices.basePrice} />
                </div>
            </div>
            <div>
                <div className="ordersPanelPriceInfo-caption">{l('ORDER_PANELS.PANEL_PRICE_INFO.DISCOUNT')}</div>
                <div className="ordersPanelPriceInfo-value gray">
                    -
                    <Value currency={prices.currency} seleniumId="order-discountAmount" value={prices.discountAmount} />
                </div>
            </div>

            {isShowTax && (
                <div>
                    <div className="ordersPanelPriceInfo-caption">{l('ORDER_PANELS.PANEL_PRICE_INFO.TAX')}</div>
                    <div className="ordersPanelPriceInfo-value">
                        <Value currency={prices.currency} seleniumId="order-tax-review" value={prices.taxAmount} />
                    </div>
                </div>
            )}

            <div>
                <div className="ordersPanelPriceInfo-caption ordersPanelPriceInfo-caption--total">
                    {labels.amountPayable}
                </div>
                <div className="ordersPanelPriceInfo-value ordersPanelPriceInfo-value--total">
                    <Value currency={prices.currency} seleniumId="order-totalAmount" value={totalAmount} />
                </div>
            </div>
        </div>
    );
}

export default withCodes2(ID.ORDER_PANELS)(OrdersPanelPriceInfo);
