import * as middleware from 'app/blocks/middleware/middleware';
import payment from 'app/pages/orders/orders.payment';

const { CREDIT_CARD, SOCOPAY } = payment.method;

function isCardVerified(order) {
    return order.paymentDetails && order.paymentDetails.cardVerified;
}

function isCardNotVerified(order) {
    return !isCardVerified(order);
}

function isPaymentMethodNotCreditCard(order) {
    const paymentMethod = order.paymentDetails && order.paymentDetails.paymentMethod;
    return CREDIT_CARD.is(paymentMethod);
}

function isOrderExisting(order) {
    return order.existing || false;
}

function isOrderPaid(order) {
    return order.paid || false;
}

function isCreditCardShouldBeValidated(order) {
    if (!isPaymentMethodNotCreditCard(order)) {
        return false;
    }

    // TODO updateCardDetailsFlag is not used in Online Open orders
    // remove it if Open Access does not it too
    return isCardNotVerified(order) || (isOrderExisting(order) && order.paymentDetails.updateCardDetailsFlag);
}

function isPaymentMethodAlipay(order) {
    const paymentMethod = order.paymentDetails && order.paymentDetails.paymentMethod;
    return SOCOPAY.is(paymentMethod);
}

function isCreditCardPaid(order) {
    const paymentMethod = order.paymentDetails && order.paymentDetails.paymentMethod;
    return CREDIT_CARD.is(paymentMethod) && isOrderPaid(order);
}
function isCreditCardShouldBeValidatedByDraft(draft, isOrderExisting2) {
    return isCreditCardShouldBeValidated({ ...draft, existing: isOrderExisting2 });
}

function isCheckoutNeededByDraft(order, isOrderExisting2) {
    return (
        order &&
        order.pricingDetails?.totalAmount > 0 &&
        (isPaymentMethodAlipay(order) || isCreditCardShouldBeValidatedByDraft(order, isOrderExisting2))
    );
}

function isCheckoutNeededByDraftGOA(order, isOrderExisting2) {
    const price = order?.prices[0] || {};
    const amount = price.finalAPC || 0;
    return (
        order &&
        amount > 0 &&
        (isPaymentMethodAlipay(order) || isCreditCardShouldBeValidatedByDraft(order, isOrderExisting2))
    );
}

async function getVatTaxRelevanceDetails(order) {
    return await middleware.orders.getVatTaxRelevanceDetails(order.billingAddress.countryCode);
}

function isAuthorPaid(order) {
    if ('orderType' in order) {
        switch (order.orderType) {
            case 'AUTHOR_PAID':
            case 'AUTHOR_PAID_FUNDER_DISCOUNT':
                return true;
            default:
                break;
        }
    } else if ('woaFunderId' in order) {
        return order.woaFunderId === '';
    }

    return false;
}

function isFunderPaid(order) {
    if ('orderType' in order) {
        switch (order.orderType) {
            case 'FUNDER_PAID':
                return true;
            default:
                break;
        }
    } else if ('woaFunderId' in order) {
        return order.woaFunderId !== '';
    }

    return false;
}

export { isAuthorPaid, isFunderPaid };

export default {
    getBillingCountryCode(order) {
        return order.billingAddress && order.billingAddress.countryCode;
    },
    getVatTaxRelevanceDetails,
    isCheckoutNeededByDraft,
    isCheckoutNeededByDraftGOA,
    isCreditCardPaid,
    isCreditCardShouldBeValidated,
    isCreditCardShouldBeValidatedByDraft,
    isOrderPaid,
    isPaymentMethodAlipay,
};
