import moment from 'moment';
import _ from 'underscore';

const Filter = function (id, f) {
    const self = this;
    self.id = id;

    self.entries = function () {
        const arr = [];
        _.each(this, (v, i) => {
            if (!_.isFunction(v)) {
                arr.push({ name: v.name, value: i });
                self[i] = v;
            }
        });
        return arr;
    }.apply(f);
};

const noFilter = v => v;

const OAorOOFilter = function (codes) {
    return new Filter('type', {
        ALL: {
            _apply: noFilter,
            name: codes.ALL,
        },
        OO: {
            _apply(allOrders) {
                return allOrders.filter(o => o.isOO);
            },
            name: codes.OO,
        },
        // eslint-disable-next-line sort-keys
        OA: {
            _apply(allOrders) {
                return allOrders.filter(o => o.isOA);
            },
            name: codes.OA,
        },
    });
};

const TimeFilterInstance = function (options) {
    this.name = options.name;
    this.threshold = () => moment().subtract(options.threshold[0], options.threshold[1]);
};
TimeFilterInstance.prototype = {
    _apply(allOrders) {
        const self = this;
        return allOrders.filter(o => self.threshold() < moment(o.order.orderDate));
    },
};

const TimeFilter = function (codes) {
    return new Filter('time', {
        ALL: {
            _apply: noFilter,
            name: codes.ALL,
        },
        LAST_30_DAYS: new TimeFilterInstance({
            name: codes.LAST_30_DAYS,
            threshold: [30, 'days'],
        }),
        LAST_6_MONTHS: new TimeFilterInstance({
            name: codes.LAST_6_MONTHS,
            threshold: [6, 'months'],
        }),
        // eslint-disable-next-line sort-keys
        BY_YEAR: new TimeFilterInstance({
            name: codes.BY_YEAR,
            threshold: [1, 'years'],
        }),
    });
};

export { OAorOOFilter, TimeFilter };
