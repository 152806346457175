import cn from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import PageEnum from 'app/blocks/common/PageEnum';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import StaticContent from 'app/blocks/static-content/static-content';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import Context from 'app/pages/license-signing-process/context/Context';
import { SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import CopyrightOptions from './CopyrightOptions';

import './CopyrightOwnership.scss';

const CONTRIBUTOR = [{ copyrightOwnership: 'CONTRIBUTOR', copyrightOwnershipSubtype: undefined }];

const ANALYTICS_PAGES = [
    { id: 'single_author', page: PageEnum.COPYRIGHT_OWNERSHIP_SINGLE_AUTHOR },
    { id: 'copyright', page: PageEnum.COPYRIGHT_OWNERSHIP },
];

function mapBEtoUI(copyrightOwnershipList, copyrightOwnerships = []) {
    for (const ownership of copyrightOwnerships) {
        const { copyrightOwnership, copyrightOwnershipSubtype } = ownership;

        const oInList = copyrightOwnershipList.find(o => o.id === copyrightOwnership);
        if (oInList) {
            oInList.selected = true;

            if (oInList.subItems?.length) {
                const osInList = oInList.subItems.find(o => o.id === copyrightOwnershipSubtype);
                if (osInList) {
                    osInList.selected = true;
                }
            }
        }
    }

    return copyrightOwnershipList;
}

function mapUItoBE(copyrightOwnershipList) {
    const copyrightOwnerships = [];

    for (const ownership of copyrightOwnershipList) {
        if (ownership.selected && ownership.subItems?.length) {
            for (const subownership of ownership.subItems) {
                if (subownership.selected) {
                    copyrightOwnerships.push({
                        copyrightOwnership: ownership.id,
                        copyrightOwnershipSubtype: subownership.id,
                    });
                }
            }
        } else if (ownership.selected) {
            copyrightOwnerships.push({
                copyrightOwnership: ownership.id,
                copyrightOwnershipSubtype: undefined,
            });
        }
    }

    return copyrightOwnerships;
}

class CopyrightOwnership extends React.Component {
    static propTypes = {
        confirmCopyrightOwnership: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        isCopyrightConfirming: PropTypes.bool,
        isSingleAuthor: PropTypes.bool,
        l: PropTypes.func.isRequired,
        listCopyrightOwnerships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    };

    static defaultProps = {
        isCopyrightConfirming: false,
        isSingleAuthor: false,
    };

    state = {
        copyrightOwnershipList: [],
        isProcessing: true,
        selectedOption: null,
        subStep: 0,
    };

    onLoad() {
        const { subStep } = this.state;
        const { onLoadSubstep } = this.props;

        if (typeof onLoadSubstep === 'function') {
            onLoadSubstep(ANALYTICS_PAGES[subStep]?.id, ANALYTICS_PAGES[subStep].page);
        }
    }

    @processing
    @tryCatch.showPopUpAndGoToDashboard
    async componentDidMount() {
        const { copyrightOwnerships = [], listCopyrightOwnerships = [] } = this.props;

        let selectedOption = null;
        if (copyrightOwnerships?.length === 1 && copyrightOwnerships[0].copyrightOwnership === 'CONTRIBUTOR') {
            selectedOption = 'YES';
        } else if (copyrightOwnerships?.length > 0) {
            selectedOption = 'NO';
        }

        this.setState({
            copyrightOwnershipList: mapBEtoUI(JSON.parse(JSON.stringify(listCopyrightOwnerships)), copyrightOwnerships),
            selectedOption,
            subStep: 0,
        });

        this.onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.subStep !== prevState.subStep) {
            this.onLoad();
        }
    }

    onCopyrightStepConfirm = () => {
        const { confirmCopyrightOwnership, isSingleAuthor, l } = this.props;
        const { copyrightOwnershipList } = this.state;

        const copyrightOwnerships = mapUItoBE(copyrightOwnershipList);
        if (
            !isSingleAuthor &&
            copyrightOwnerships?.length === 1 &&
            copyrightOwnerships[0].copyrightOwnership === 'CONTRIBUTOR'
        ) {
            showDialog.confirmation({
                cancelBtnLabel: l('LICENSE_SIGNING.COPYRIGHT.DIALOG_CANCEL_BUTTON'),
                confirmBtnLabel: l('LICENSE_SIGNING.COPYRIGHT.DIALOG_CONFIRM_BUTTON'),
                message: l('LICENSE_SIGNING.COPYRIGHT.DIALOG_MESSAGE'),
                onApprove: () => confirmCopyrightOwnership(CONTRIBUTOR),
            });
        } else {
            confirmCopyrightOwnership(copyrightOwnerships);
        }
    };

    canConfirm = () => {
        const { selectedOption, subStep } = this.state;

        if (subStep === 0) {
            return selectedOption !== null;
        }

        return !!this.state.copyrightOwnershipList.find(
            o =>
                (o.selected && !o.subItems?.length) ||
                (o.selected && o.subItems?.length && !!o.subItems.find(so => so.selected)),
        );
    };

    getInfoMessagePrompt() {
        const { l } = this.props;
        return (
            <div className="CopyrightOwnership-InfoBlock" data-seleniumid="copyright-ownership-banner">
                <SvgIcon.info className="InfoIcon" />
                <div className="InfoBox">
                    <div className="InfoBox-Title">{l('LICENSE_SIGNING.COPYRIGHT.BANNER_TITLE')}</div>
                    <div
                        className="InfoBox-Message"
                        dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.COPYRIGHT.BANNER_TEXT') }}
                    />
                </div>
            </div>
        );
    }

    onChangeOption = option => {
        this.setState({ selectedOption: option });
    };

    onChangeCopyrightOptions = option => {
        const { isSingleAuthor } = this.props;
        const { copyrightOwnershipList } = this.state;

        if (isSingleAuthor) {
            copyrightOwnershipList.forEach(opt => {
                // eslint-disable-next-line no-param-reassign
                opt.selected = opt === option;

                if (opt.subItems?.length) {
                    opt.subItems.forEach(subOption => {
                        // eslint-disable-next-line no-param-reassign
                        subOption.selected = subOption === option;
                        // eslint-disable-next-line no-param-reassign
                        if (subOption === option) opt.selected = true;
                    });
                }
            });
        } else {
            copyrightOwnershipList.forEach(opt => {
                if (opt === option) {
                    // eslint-disable-next-line no-param-reassign
                    opt.selected = !opt.selected;
                }
                if (opt.subItems?.length) {
                    opt.subItems.forEach(subOption => {
                        if (subOption === option) {
                            // eslint-disable-next-line no-param-reassign
                            subOption.selected = !subOption.selected;
                        }
                    });
                }
            });
        }
        this.setState({ copyrightOwnershipList });
    };

    setFirstStep = () => {
        this.setState({ subStep: 0 });
    };

    setSecondStep = () => {
        if (this.state.selectedOption === 'YES') {
            this.props.confirmCopyrightOwnership(CONTRIBUTOR);
        } else {
            this.setState({ subStep: 1 });
        }
    };

    switchExample = () => {
        const { showExample } = this.state;
        this.setState({
            showExample: !showExample,
        });
    };

    render() {
        const { getPrevStep, goBack, isCopyrightConfirming, isSingleAuthor, l } = this.props;
        const { copyrightOwnershipList, copyrightStep, isProcessing, selectedOption, showExample, subStep } =
            this.state;

        if (isProcessing) {
            return <Layout isLoading />;
        }

        const sectionText = isSingleAuthor ? 'SINGLE_AUTHOR' : 'MULTI_AUTHOR';

        return (
            <>
                <section className="CopyrightOwnership">
                    <SectionTitle data-seleniumid="copyright-page-title">
                        {l('LICENSE_SIGNING.COPYRIGHT.TITLE')}
                    </SectionTitle>
                    <SectionPrompt>{l('LICENSE_SIGNING.COPYRIGHT.PROMPT')}</SectionPrompt>

                    {subStep === 0 && (
                        <>
                            {this.getInfoMessagePrompt()}

                            <SectionTitle
                                className="CopyrightOwnership-OwnershipTitle"
                                data-seleniumid="copyright-page-first-step-title"
                            >
                                {l(`LICENSE_SIGNING.COPYRIGHT.${sectionText}.OWNERSHIP_FIRST_TITLE`)}
                            </SectionTitle>
                            <SectionPrompt>
                                {l(`LICENSE_SIGNING.COPYRIGHT.${sectionText}.OWNERSHIP_FIRST_PROMPT`)}
                            </SectionPrompt>
                        </>
                    )}

                    {subStep !== 0 && (
                        <>
                            <SectionTitle
                                className="CopyrightOwnership-OwnershipTitle"
                                data-seleniumid="copyright-page-second-step-title"
                            >
                                {l(`LICENSE_SIGNING.COPYRIGHT.${sectionText}.OWNERSHIP_SECOND_TITLE`)}
                            </SectionTitle>
                            <SectionPrompt>
                                {l(`LICENSE_SIGNING.COPYRIGHT.${sectionText}.OWNERSHIP_SECOND_PROMPT`)}
                            </SectionPrompt>
                            {!isSingleAuthor && (
                                <>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                    <div
                                        className="Example"
                                        data-seleniumid="show-hide-button"
                                        onClick={this.switchExample}
                                    >
                                        <SvgIcon.chevron
                                            className={cn({ Selected: !showExample })}
                                            iconTitle="Chevron icon"
                                        />
                                        <div className="Example-Label">
                                            {l(
                                                `LICENSE_SIGNING.COPYRIGHT.MULTI_AUTHOR.EXAMPLE.${
                                                    showExample ? 'HIDE' : 'SHOW'
                                                }`,
                                            )}
                                        </div>
                                    </div>

                                    {showExample && (
                                        <div className="Example-Content">
                                            <StaticContent
                                                seleniumid="example-prompt"
                                                src="MULTI_AUTHORS_EXAMPLE.html"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    <div className="Copyright">
                        <CopyrightOptions
                            copyrightOwnershipList={copyrightOwnershipList}
                            isSingleAuthor={isSingleAuthor}
                            onChangeCopyrightOptions={this.onChangeCopyrightOptions}
                            onChangeOption={this.onChangeOption}
                            selectedOption={selectedOption}
                            subStep={subStep}
                        />
                    </div>
                </section>
                <div key={copyrightStep} className="licenseSigningProcessPage-backAndForth">
                    <BackAndForthButtons
                        buttonCancelLabel={
                            subStep === 0 ? getPrevStep().name : l('LICENSE_SIGNING.COPYRIGHT.BUTTON_BACK')
                        }
                        buttonConfirmingLabel={l('BUTTONS.CONFIRMING')}
                        buttonConfirmLabel={l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                        canCancel={!isCopyrightConfirming}
                        canConfirm={this.canConfirm()}
                        isConfirming={isCopyrightConfirming}
                        onCancel={subStep !== 0 ? this.setFirstStep : goBack}
                        onConfirm={subStep === 0 ? this.setSecondStep : this.onCopyrightStepConfirm}
                    />
                </div>
            </>
        );
    }
}

export default compose(
    withScrollToTop,
    Context.withContext(state => ({
        confirmCopyrightOwnership: state.confirmCopyrightOwnership,
        copyrightOwnerships: get(state, 'all.copyrightOwnership.copyrightOwnerships'),
        getPrevStep: state.getPrevStep,
        goBack: state.goBack,
        isCopyrightConfirming: get(state, 'isCopyrightConfirming', false),
        isSingleAuthor: get(state, 'all.copyrightOwnership.singleAuthor'),
        listCopyrightOwnerships: get(state, 'listCopyrightOwnerships'),
    })),
    withCodes2(ID.LICENSE_SIGNING, ID.LICENSE_SUBMISSION),
)(CopyrightOwnership);
