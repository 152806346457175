import React, { Component } from 'react';
import _ from 'underscore';
import { ID, withCodes } from 'app/blocks/common/codes';
import Layout from 'app/blocks/Layout/Layout';
import { getResearchPrograms, ResearchProgramPayload } from 'app/blocks/middleware/article-funders';

type State = {
    isLoading: boolean;
    researchPrograms: ResearchProgramPayload['researchPrograms'] | undefined;
    exceptionError: Error | undefined;
};

export class OrdersPanelFunderReview extends Component<
    {
        DataItemAndAuxInfo: { OrdersDataItem: React.VFC<{ innerInfo: Array<string>[] }> };
        l: l;
        order: { article: { id: string } };
    },
    State
> {
    state: State = {
        isLoading: true,
        researchPrograms: undefined,
        exceptionError: undefined,
    };

    async componentDidMount() {
        const { order } = this.props;
        try {
            const { researchPrograms } = await getResearchPrograms(order.article.id);

            this.setState({
                researchPrograms,
            });
        } catch (error) {
            this.setState({ exceptionError: error });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { DataItemAndAuxInfo, l } = this.props;
        const { exceptionError, isLoading, researchPrograms } = this.state;

        return (
            <div className="row order-btm-border" data-seleniumid="order-funder-panel-review">
                <div className="col-md-10 col-12 nopadding m-btm-md">
                    <div className="m-btm_md">
                        <h5>{l('ORDER_PANELS.PANEL_FUNDER_REVIEW.TITLE')}</h5>
                    </div>
                    <DataItemAndAuxInfo.OrdersDataItem innerInfo={[['woaFunderName']]} />
                    <Layout className="research-programs" error={exceptionError} isLoading={isLoading}>
                        {(researchPrograms || []).map((researchProgram, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="research-programs__list-item">
                                <div data-seleniumid="order-review-funder-researchFundersNames">
                                    {researchProgram.researchFunder.name}
                                </div>
                                <div data-seleniumid="order-review-funder-grantNumbers">
                                    {researchProgram.grantNumbers.join(', ')}
                                </div>
                                {!_.isEmpty(researchProgram.authors[0]) && (
                                    <div data-seleniumid="order-review-funder-authors">
                                        {researchProgram.authors.map(author => author.name).join(', ')}
                                    </div>
                                )}
                            </div>
                        ))}
                    </Layout>
                </div>
            </div>
        );
    }
}

export default withCodes(OrdersPanelFunderReview, ID.ORDER_PANELS, ID.BUTTONS);
