import isEmpty from 'lodash.isempty';
import React from 'react';
import { WhiteBlock } from 'app/blocks/blocks';
import CheckboxButton from 'app/ui/form/CheckboxButton';

const SearchFilter: React.VFC<{
    facet: Search.Payload['facets'];
    filterConfig: {
        title: string;
        options: { [key: string]: { id: string; name: string } };
    };
    onFilter: (id: string) => void;
    selectedFilters: Set<string>;
}> = ({ facet, filterConfig, onFilter, selectedFilters }) => {
    if (isEmpty(facet)) {
        return null;
    }

    return (
        <div className="col-xl-3">
            <div className="m-btm_md">
                <WhiteBlock className="page">
                    <div className="search_filter_name">{filterConfig.title}</div>
                    {Object.keys(facet).map(key => {
                        const { id = key, name } = filterConfig.options[key] || {};
                        if (!name) {
                            console.warn(`Unknown filter key ${key}`);
                            return null;
                        }

                        return (
                            <div key={key} className="p-top_mini">
                                <CheckboxButton
                                    checked={selectedFilters.has(id)}
                                    data-seleniumid={`search-results-filter-checkbox-${key}`}
                                    disabled={Object.keys(facet).length === 1}
                                    id={`search-results-filter-checkbox-${key}`}
                                    label={`${name} (${facet[key]})`}
                                    onChange={() => onFilter(id)}
                                />
                            </div>
                        );
                    })}
                </WhiteBlock>
            </div>
        </div>
    );
};

export default SearchFilter;
