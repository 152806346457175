import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import InstitutionPicker from 'app/blocks/institution-picker/institution-picker';

type Props = {
    blacklist: string[];
    getTextForNotConfirmedItem: (type?: string) => string;
    institutionsIdFromProfile: string[];
    l: l;
    onSelectInstitution: (value: { id: string; name: string }) => void;
    seleniumid: string;
    selectedItem?: { id: string; name: string };
};

function AffiliationSelector({
    blacklist,
    getTextForNotConfirmedItem,
    institutionsIdFromProfile,
    l,
    onSelectInstitution,
    selectedItem,
    seleniumid,
}: Props) {
    return (
        <>
            <p
                className="NotConfirmedInstitution-Text"
                dangerouslySetInnerHTML={{ __html: getTextForNotConfirmedItem('NOT_FOUND') }}
                data-seleniumid={`${seleniumid}-suggestions-hint`}
            />
            <div className="NotConfirmedInstitution-Text">
                <strong>Search for a different affiliation</strong>
            </div>
            <div className="NotConfirmedInstitution-InstitutionPicker">
                <InstitutionPicker
                    blacklist={blacklist}
                    changeHandler={institution => onSelectInstitution(institution)}
                    institutionsIdFromProfile={institutionsIdFromProfile}
                    placeholder={l('ARTICLE_AFFILIATION_EDITOR.PICKER_PLACEHOLDERS.SEARCH')}
                    selectedItem={selectedItem.id ?? selectedItem}
                    seleniumid={`${seleniumid}-institution-suggestion-other`}
                />
            </div>
        </>
    );
}

export default withCodes(AffiliationSelector, ID.ARTICLE_AFFILIATION_EDITOR);
