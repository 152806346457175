import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { TypeBadge } from 'app/pages/search/_item/badges';

const ResultStatic: React.VFC<{
    item: Search.Static;
    l: l;
}> = ({ item, l }) => {
    const text = Array.isArray(item.doc.content)
        ? `${item.doc.content.reduce((s, v2) => `${s}...${v2}`, '')}...`
        : `${item.doc.content}...`;

    return (
        <div className="search_Item">
            <div className="text_bold text_big">
                <a dangerouslySetInnerHTML={{ __html: item.doc.title }} href={item.id} />
            </div>

            <div className="search_Item__text mt-base" dangerouslySetInnerHTML={{ __html: text }} />

            <TypeBadge>{l('SEARCH_CODES.RESOURCE_LABEL')}</TypeBadge>
        </div>
    );
};

export default withCodes(ResultStatic, ID.SEARCH_CODES);
