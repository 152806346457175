/* eslint-disable no-return-assign */
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Layout from 'app/blocks/Layout/Layout';
import * as middlewareLicense from 'app/blocks/middleware/license';
import StaticContent from 'app/blocks/static-content/static-content';
import LicenseTypeEnum from 'app/pages/license/LicenseTypeEnum';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import { Input2 } from 'app/ui/form/inputs';

import './ElectronicLicenseSubmission.scss';

const copyrightOwnershipMapping = {
    CONTRIBUTOR: 'cow',
    US_GOVT: 'usgw',
};

class LicenseText extends React.PureComponent {
    static propTypes = {
        article: PropTypes.shape({ id: PropTypes.string }).isRequired,
        cancelSign: PropTypes.func.isRequired,
        copyrightOwnership: PropTypes.string.isRequired,
        isAgree: PropTypes.bool.isRequired,
        l: PropTypes.func.isRequired,
        licenseType: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onChangeAgree: PropTypes.func.isRequired,
        onChangeName: PropTypes.func.isRequired,
    };

    state = { isLoading: true };

    async componentDidMount() {
        const { article, cancelSign } = this.props;

        try {
            const licenseText = await this.pullLicensePreview(article.id);
            this.setState({ isLoading: false }, () => this.setContentToFrame(licenseText));
        } catch (error) {
            showDialog.error(error, {
                onClose: cancelSign,
            });
        }
    }

    async setContentToFrame(content) {
        await new Promise(resolve => {
            // FIXME phantomjs hack
            const timer = setInterval(() => {
                if (this.license__frame.contentWindow) {
                    clearInterval(timer);
                    resolve();
                }
            }, 300);
        });

        const doc = this.license__frame.contentWindow.document;

        doc.open();
        doc.write(content);
        doc.close();
    }

    async pullLicensePreview(articleId) {
        const licenseText = await middlewareLicense.electronicLicenseText(articleId);

        const nbsp = licenseText.indexOf('[&nbsp;&nbsp;&nbsp;') > 0 ? '&nbsp;&nbsp;&nbsp;' : '&nbsp;&nbsp;';

        // todo: this contains very heuristic logic - probably we should rework it together with backend some days
        // this will run in iframe - we will HAVE NO transpilers and should use ES5
        const licenseTextScript = `
            <script type="text/javascript">
                var copyrightOwnership = '${copyrightOwnershipMapping[this.props.copyrightOwnership]}';
                
                try {
                    var form = document.querySelector('form');
                    
                    // remove controls with its parents (up to <form>)
                    ['license_agree', 'license_signature', 'license_date'].forEach(function (i) {
                        var input = document.querySelector('input[name="' + i + '"]');
                        
                        var MAX_DEEP = 2;
                        var iteration = 0;
                        while (iteration < MAX_DEEP && input.parentNode !== form) {
                            input = input.parentNode;
                            iteration += 1;
                        }
                        
                        input.parentNode.removeChild(input);
                    });
                    
                    // replace radio's with text marker
                    document.querySelectorAll('input[name="license_contributor"]').forEach(function (input) {
                        var parent = input.parentNode;
                        var checked = (input.value === copyrightOwnership);
                        var marker = document.createElement('span');
                        marker.innerHTML = checked ? '[&nbsp;x&nbsp;]' : '[${nbsp}]';
                        
                        parent.insertBefore(marker, input);
                        parent.removeChild(input);
                    });

                    // remove unnecessary markup
                    while (form.firstElementChild && (form.firstElementChild.tagName === 'HR' || form.firstElementChild.tagName === 'BR')) {
                        form.removeChild(form.firstElementChild);
                    }
                } catch (error) {
                    console.log('License text injection error');
                }
            </script>
        `;

        const n = licenseText.lastIndexOf('</body>');
        return licenseText.slice(0, n) + licenseTextScript + licenseText.slice(n);
    }

    render() {
        const { isAgree, l, licenseType, name, onChangeAgree, onChangeName } = this.props;
        const { isLoading } = this.state;

        const agreementTemplate = `license__agreement__${
            Object.values(LicenseTypeEnum).includes(licenseType) ? licenseType : 'default'
        }`;

        return (
            <div className="LicenseText">
                <Layout isLoading={isLoading}>
                    <iframe
                        ref={d => (this.license__frame = d)}
                        align="left"
                        className="LicenseText-Iframe"
                        src=""
                        title="License text"
                    >
                        Your browser does not support iframes!
                    </iframe>
                </Layout>

                {!isLoading && (
                    <>
                        <CheckboxButton
                            checked={isAgree}
                            className="LicenseText-AgreementCheckbox"
                            data-seleniumid="license-agreement-term-input"
                            id="agree"
                            label={<StaticContent key={agreementTemplate} src={`${agreementTemplate}.html`} />}
                            onChange={e => onChangeAgree(e.target.checked)}
                        />
                        <span className="LicenseText-ContributorText">
                            {l('FULL_LICENSE.SIGNING.CONTRIBUTORS_NAME_SINGLE_AUTHOR')}
                        </span>
                        <Input2
                            className="LicenseText-ContributorInput"
                            data-seleniumid="contributor-name-input"
                            onChange={onChangeName}
                            placeholder="Type name here"
                            type="text"
                            value={name}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default withCodes2(ID.FULL_LICENSE)(LicenseText);
