import isEmpty from 'lodash.isempty';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import ArticleAffiliationsMiddleware from 'app/blocks/article-affiliations-editor/middleware';
import { l } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import Modal from 'app/blocks/Modal/Modal';
import './NotifyAffiliationsPopup.scss';
import OrdersRow from 'app/blocks/Row/Row';
import { BackButton, ForwardButtonWithOkIcon } from 'app/pages/license-signing-process/buttons';

export default function NotifyAffiliationsPopup(props) {
    const [selectedAffiliation, setSelectedAffiliation] = useState({});
    const [selectedIssueType, setSelectedIssueType] = useState({});
    const [descriptionOfIssue, setDescriptionOfIssue] = useState('');
    const [isError, setIsError] = useState(false);

    const affiliationsOptions = props?.affiliations.map(x => ({
        label: x.institutionName || x.name,
        value: x.id,
        ...x,
    }));

    const issueTypeOptions = [
        {
            label: 'Affiliation is spelt incorrectly',
            value: 'AFFILIATION_IS_SPELT_INCORRECTLY',
        },
        {
            label: 'Location is incorrect',
            value: 'LOCATION_IS_INCORRECT',
        },
        {
            label: 'I could not find my affiliation',
            value: 'I_COULD_NOT_FIND_MY_AFFILIATION',
        },
        {
            label: 'My search returns multiple results and I do not know which to select',
            value: 'MY_SEARCH_RETURNS_MULTIPLE_RESULTS_AND_I_DO_NOT_KNOW_WHICH_TO_SELCT',
        },
        {
            label: 'Other',
            value: 'OTHER',
        },
    ];

    const getAffiliationLocation = (city = '', stateCode = '', country = '', countryCode = '', stateName = '') => {
        if (stateCode || stateName) {
            return `${city || ''}, ${stateCode || stateName || ''}, ${country || countryCode || ''}`;
        }
        return `${city || ''}, ${country || countryCode || ''}`;
    };

    const onSubmitHandler = async () => {
        if (Object.keys(selectedIssueType)?.length) {
            const payload = {
                isAffiliationVerified: false,
                issueType: selectedIssueType?.label,
                ...(Object.keys(selectedIssueType)?.length && {
                    ...(Object.keys(selectedAffiliation)?.length
                        ? {
                              affiliationName: selectedAffiliation?.institutionName || '',
                              isAffiliationVerified: !selectedAffiliation?.custom,
                              ...(selectedAffiliation?.custom
                                  ? {}
                                  : {
                                        affiliationLocation: getAffiliationLocation(
                                            selectedAffiliation?.city,
                                            selectedAffiliation?.stateCode,
                                            selectedAffiliation?.country,
                                            selectedAffiliation?.countryCode,
                                            selectedAffiliation?.stateName,
                                        ),
                                    }),
                          }
                        : {}),
                }),
                ...(descriptionOfIssue && { commentFromUser: descriptionOfIssue }),
            };

            try {
                await ArticleAffiliationsMiddleware?.notifyAffiliation(props?.articleId, payload);
                props?.onClick();
            } catch (error) {
                setIsError(true);
                props?.onClose();
                showDialog.modal(closeAnd => ({
                    closeBtnLabel: 'close',
                    extendedMessage: String(error?.extendedMessage),
                    html: l(`ERROR.${error?.code}`),
                    onClose: closeAnd(() => {
                        setIsError(false);
                    }),
                    type: 'error',
                }));
            }
        }
    };
    return (
        <Modal
            className="Dialog"
            ignoreBackdropClicks
            modalHeading={props?.modalHeading}
            onClose={props?.onClose}
            type="custom"
        >
            <form>
                <div
                    className="modal-body Dialog-Body"
                    data-seleniumid="affiliation-modal-body"
                    style={{ paddingBottom: '30px', textAlign: 'left' }}
                >
                    <div
                        className="Dialog-RefId"
                        data-seleniumid="affiliation-dialog-refId"
                        style={{ border: '1px solid #CCCCCC', padding: '30px' }}
                    >
                        <div>
                            <p className="notify-msg" data-seleniumid="affiliation-thankyou-message">
                                {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.THANK_YOU_MSG')}{' '}
                            </p>
                            <p className="notify-msg1 text_italic" data-seleniumid="affiliation-form-message">
                                {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.FORM_MSG')}
                            </p>
                        </div>
                        <div className="affiliationDetailsMain">
                            {props?.affiliations && (
                                <div className="affiliationDetails" data-seleniumid="affiliation-field">
                                    <label className="affiliationDetailslabel" data-seleniumid="affiliation-label">
                                        {l('LICENSE_SIGNING.NOTIFYAFFILIATION.LABEL.AFFILIATION')}{' '}
                                        <span>{l('LICENSE_SIGNING.NOTIFYAFFILIATION.LABEL.POSTFIX_APPLICABLE')}</span>
                                    </label>
                                    <div data-seleniumid="affiliation-dropdown">
                                        <ReactSelect
                                            className="affiliationrecipients"
                                            classNamePrefix="affiliationrecipients"
                                            isSearchable
                                            onChange={value => setSelectedAffiliation(value)}
                                            options={affiliationsOptions}
                                            placeholder=""
                                            value={selectedAffiliation}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="affiliationDetails" data-seleniumid="affiliation-issue-type- field">
                                <label
                                    className="affiliationDetailslabel"
                                    data-seleniumid="affiliation-issue-type-label"
                                >
                                    {l('LICENSE_SIGNING.NOTIFYAFFILIATION.LABEL.ISSUE_TYPE')}
                                    <span className="text_red">
                                        {l('LICENSE_SIGNING.FUNDERS.LABEL.POSTFIX_REQUIRED')}
                                    </span>
                                </label>
                                <div data-seleniumid="affiliation-issue-type-dropdown">
                                    <ReactSelect
                                        className="affiliationrecipients"
                                        classNamePrefix="affiliationrecipients"
                                        isSearchable
                                        onChange={value => setSelectedIssueType(value)}
                                        options={issueTypeOptions}
                                        placeholder=""
                                        value={selectedIssueType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="IncorrectList-Reasons" data-seleniumid="affiliation-descripion-issue">
                            <OrdersRow
                                className="IncorrectList-TextArea"
                                formSeleniumid="description-of-issue"
                                isNewComponent
                                label={l('LICENSE_SIGNING.NOTIFYAFFILIATION.LABEL.DESC_OF_ISSUE')}
                                maxLength={501}
                                multiline
                                name="affiliation-issue"
                                onChange={value => setDescriptionOfIssue(value)}
                                value={descriptionOfIssue}
                            />
                            {descriptionOfIssue?.length > 500 && (
                                <ErrorLabel
                                    data-seleniumid="validation-error-Description-of-issue"
                                    text={l('LICENSE_SIGNING.NOTIFYAFFILIATION.ERROR.MESSAGE')}
                                />
                            )}
                        </div>
                        <div className="notify-detailMain">
                            <p
                                className="notify-detail notify-msg1 text_italic"
                                data-seleniumid="affiliation-correction-message"
                            >
                                {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.FORM_DETAIL')}
                            </p>
                        </div>
                    </div>
                    <div className="Dialog-BodyActions Dialog-BodyActions-Row">
                        <BackButton data-seleniumid="notify-affiliation-cancel-button" onClick={() => props?.onClose()}>
                            {l('LICENSE_SIGNING.NOTIFYAFFILIATION.BUTTON.CANCEL')}
                        </BackButton>

                        <ForwardButtonWithOkIcon
                            data-seleniumid="notify-affiliation-submit-button"
                            disabled={isEmpty(selectedIssueType) || descriptionOfIssue?.length > 500 || isError}
                            onClick={onSubmitHandler}
                        >
                            {l('LICENSE_SIGNING.NOTIFYAFFILIATION.BUTTON.SUBMIT')}
                        </ForwardButtonWithOkIcon>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
