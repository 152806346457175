import React, { Component } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'underscore';
import { pageEvents, processLogin, processLoginPageLoad, trackDTM } from 'app/blocks/analytics/analytics';
import { addRegistrationValue, removeLoginValue } from 'app/blocks/analytics/storage';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import ASInfo from 'app/blocks/common/as-info';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import { processing } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import * as Utils from 'app/blocks/common/utils';
import { getQueryParamSafe } from 'app/blocks/common/utils';
import commonValidation, { isEmailValid } from 'app/blocks/common/validation';
import { suppressUnauthListeners } from 'app/blocks/common/withUnauthRequiredPage';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import * as middlewareAuthentication from 'app/blocks/middleware/authentication';
import { getUrlParam, invalidateUrlParam } from 'app/blocks/middleware/url-params';
import Password from 'app/blocks/Password/Password';
import ServicesMessage from 'app/blocks/services-message';
import { MainTitle } from 'app/blocks/titles/titles';
import LoginUtils from 'app/pages/login/utils/login-utils';
import routes from 'app/pages/routes';
import { navigate } from 'app/route/history';
import { Input } from 'app/ui/form';
import './login-page.scss';

class LoginPage extends Component<
    {
        queryParams: { [key: string]: string };
        l: l;
        location: Location;
        skipNotify?: boolean;
    },
    {
        fields: { [key: string]: any };
        submitting: boolean;
        validation: { [key: string]: string };
    }
> {
    static defaultProps = {
        skipNotify: false,
    };

    state = {
        fields: {
            email: this.props.queryParams.email,
            password: '',
        },
        submitting: false,
        validation: {
            email: '',
            password: '',
        },
    };

    emailInput = null;

    passwordInput = null;

    async componentDidMount() {
        const returnUrl = getUrlParam('returnUrl');
        if (returnUrl !== null) {
            if (this.props.queryParams.email) {
                this.passwordInput.focus();
            } else {
                this.emailInput.focus();
            }
            this.sendAnalyticsOnPageLoad();
        } else {
            const queryParams = getQueryParamSafe(window.location.href);
            // we need to check if user just returns from Connect to prevent possible loop
            if (!(queryParams?.code && queryParams?.id_token)) {
                this.goToLogin();
            }
        }
    }

    async goToLogin() {
        try {
            const url = await middlewareAuthentication.getUiConnectUrl();
            // fixme: do we need to have 'securityKey()' here?
            Utils.windowLocationReplace(url);
        } catch (error) {
            showDialog.confirmation({
                confirmBtnLabel: this.props.l('BUTTONS.RELOAD_PAGE'),
                message: this.props.l('ERROR.OTHER_ERROR'),
                onApprove: () => this.goToLogin(),
            });
        }
    }

    sendAnalyticsOnPageLoad() {
        const region = removeLoginValue();
        if (region) {
            processLogin({ region, step: 'login initiate' }, pageEvents.LOGIN_INITIATE);
        }
        processLoginPageLoad();
    }

    onInputFocus = e => this.hideError(e.target.name);

    onInputChange = e => this.setFieldValue(e.target.name, e.target.value.trim());

    onSubmit = async e => {
        e.preventDefault();

        const { l, location } = this.props;
        const returnUrl = getUrlParam('returnUrl');

        const {
            fields: { email, password },
        } = this.state;

        if (!this.validate(email, password)) {
            this.setFieldValue('password', '');
            return;
        }

        try {
            suppressUnauthListeners(this.props.skipNotify);
            await this.doLogin(email, password, !!returnUrl);

            processLogin({ step: 'login success' }, pageEvents.LOGIN_SUCCESS);

            trackDTM('loginsuccess');

            if (returnUrl) {
                Alerts.renderSpinner();
                invalidateUrlParam('returnUrl');
                Utils.windowLocationReplace(returnUrl);
            } else {
                const toArticle = getUrlParam('invitationArticleId');
                if (toArticle) {
                    invalidateUrlParam('invitationArticleId');

                    routes.toArticlePage(toArticle);
                    return;
                }

                const pathname = location.pathname.replace('checkAuthAndRedirect?link=/', '');
                if (/^\/login/.test(pathname)) {
                    navigate('dashboard');
                } else {
                    navigate(pathname);
                }
            }
        } catch (error) {
            if (error.code === 'AUTH_FAILURE_NOT_ACTIVATED') {
                showDialog.confirmation(error, {
                    cancelBtnLabel: l('BUTTONS.CANCEL'),
                    confirmBtnLabel: l('LOGIN.uiLabelMessages.VERIFY_EMAIL_BUTTON_LABEL'),
                    message: l(`LOGIN.errorMessages.${error.code}`),
                    onApprove: () => LoginUtils.resendEmailRequest(email),
                });
            } else {
                this.setFieldValue('password', '');
                showDialog.error(error, {
                    message: error.message || (await ContentUtils.getServerErrorMessage(ID.LOGIN, error)),
                });
            }
        } finally {
            suppressUnauthListeners(false);
        }
    };

    setFieldValue(fieldName, value) {
        this.setState(state => ({
            fields: {
                ...state.fields,
                [fieldName]: value,
            },
        }));
    }

    goToRegistration = () => {
        addRegistrationValue('registration-btn');
        navigate('register');
    };

    hideError = fieldName => {
        this.setState(state => ({
            validation: {
                ...state.validation,
                [fieldName]: '',
            },
        }));
    };

    @processing('submitting')
    doLogin(username, password, externalLogin) {
        return middlewareAuthentication.login({
            externalLogin,
            password,
            username: username?.toLowerCase(),
        });
    }

    validate(email, password) {
        const { l } = this.props;

        const validateResult = {
            email: commonValidation.validate(email?.toLowerCase(), [
                [commonValidation.notEmpty, 'LOGIN.errorMessages.LOGIN_EMAIL_ADDR_MISSING_ERR_TEXT'],
                [isEmailValid, 'LOGIN.errorMessages.LOGIN_EMAIL_ADDR_INVALID_ERR_TEXT'],
            ]),
            password: commonValidation.validate(password, [
                [commonValidation.notEmpty, 'LOGIN.errorMessages.LOGIN_PWD_MISSING_ERR_TEXT'],
            ]),
        };

        const errors = {};
        for (const key in validateResult) {
            errors[key] = validateResult[key].length ? l(validateResult[key]) : '';
        }

        this.setState({ validation: errors });

        const isValid = Object.values(errors).every(x => _.isEmpty(x));

        if (!isValid) {
            processLogin({ step: 'login error', validateResult }, pageEvents.LOGIN_ERROR);
        }

        return isValid;
    }

    render() {
        const { l } = this.props;
        const { fields, submitting, validation } = this.state;
        const pageTitle = l('LOGIN.uiLabelMessages.LOGIN_LOGIN_LABEL');
        const returnUrl = getUrlParam('returnUrl');
        return returnUrl === null ? (
            <div data-seleniumid="login-forward-to-connect-page" />
        ) : (
            <div className="LoginPage container">
                <div className="Content">
                    <div className="Content-Login">
                        <MainTitle data-seleniumid="login-blockTitle">{pageTitle}</MainTitle>
                        <ServicesMessage />
                        <div className="LoginBlock" data-seleniumid="login-block">
                            <form
                                className="LoginBlock-Form"
                                data-seleniumid="login-form"
                                id="loginForm"
                                onSubmit={this.onSubmit}
                            >
                                <div className="LoginRow">
                                    <label className="LoginRow-Label" htmlFor="login-inputEmail">
                                        {l('LOGIN.uiLabelMessages.LOGIN_PRIMARY_EMAIL_ADDR_LABEL')}
                                        <span className="Required">*</span>
                                    </label>
                                    <div className="LoginRow-Input" id="loginEmailDiv">
                                        <Input
                                            ref={x => {
                                                this.emailInput = x;
                                            }}
                                            className="login-input_focus"
                                            data-seleniumid="login-inputEmail"
                                            id="login-inputEmail"
                                            isError={!!validation.email}
                                            maxLength={100}
                                            name="email"
                                            onChange={this.onInputChange}
                                            onFocus={this.onInputFocus}
                                            placeholder={l('LOGIN.uiLabelMessages.LOGIN_PRIMARY_EMAIL_PLACEHOLDER')}
                                            type="email"
                                            value={fields.email}
                                        />
                                        {validation.email && <ErrorLabel text={validation.email} />}
                                    </div>
                                </div>
                                <div className="LoginRow">
                                    <label className="LoginRow-Label" htmlFor="login-inputPassword">
                                        {l('LOGIN.uiLabelMessages.LOGIN_PASSWORD_LABEL')}
                                        <span className="Required">*</span>
                                    </label>
                                    <div className="LoginRow-Input" id="loginPasswordDiv">
                                        <Password
                                            className="login-input_focus"
                                            id="login-inputPassword"
                                            inputRef={x => {
                                                this.passwordInput = x;
                                            }}
                                            isError={!!validation.password}
                                            maxLength={100}
                                            name="password"
                                            onChange={this.onInputChange}
                                            onFocus={this.onInputFocus}
                                            placeholder={l('LOGIN.uiLabelMessages.LOGIN_PWD_PLACEHOLDER')}
                                            seleniumid="login-inputPassword"
                                            value={fields.password}
                                        />
                                        {validation.password && <ErrorLabel text={validation.password} />}
                                    </div>
                                    <div className="LoginRow-RestorePassword">
                                        <small>
                                            <Link
                                                data-seleniumid="login-forgotten-pwd-link"
                                                id="forgotten-pwd-link"
                                                to="/recover"
                                            >
                                                {l('LOGIN.uiLabelMessages.LOGIN_FORGOT_PWD_LABEL')}
                                            </Link>
                                        </small>
                                    </div>
                                </div>
                                <div className="LoginRow LoginRow-Actions">
                                    <PrimaryButton
                                        data-seleniumid="login-btn"
                                        disabled={submitting}
                                        formNoValidate
                                        id="login-btn"
                                        type="submit"
                                    >
                                        {submitting
                                            ? l('BUTTONS.LOADING')
                                            : l('LOGIN.uiLabelMessages.LOGIN_LOGIN_BUTTON_LABEL')}
                                    </PrimaryButton>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="Content-Register">
                        <MainTitle className="Header" data-seleniumid="newOnWiley-blockTitle">
                            {l('LOGIN.uiLabelMessages.LOGIN_NEW_TO_WILEY_LABEL')}
                        </MainTitle>
                        <div className="RegisterBlock" data-seleniumid="register-block">
                            <div className="RegisterRow">
                                <label className="RegisterRow-Label">
                                    {l('LOGIN.uiLabelMessages.LOGIN_REGISTER_OPTION_LIST_HEADER')}
                                </label>
                                <div
                                    className="RegisterRow-List"
                                    dangerouslySetInnerHTML={{
                                        __html: l('LOGIN.uiLabelMessages.LOGIN_OPTION_LIST_CONTENT'),
                                    }}
                                />
                            </div>
                            <div className="RegisterRow RegisterRow-Actions">
                                <PrimaryButton
                                    data-seleniumid="register-btn"
                                    id="register-btn"
                                    name="button"
                                    onClick={this.goToRegistration}
                                >
                                    {l('LOGIN.uiLabelMessages.LOGIN_REGISTER_BUTTON_LABEL')}
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
                <ASInfo page={pageTitle} />
            </div>
        );
    }
}

function LoginPageWrapped(props) {
    return <LoginPage {...props} location={useLocation()} />;
}

export default withCodes2(ID.LOGIN, ID.BUTTONS)(LoginPageWrapped);
