/* eslint-disable eqeqeq */
import { autobind } from 'core-decorators';
import React from 'react';
import { LinkButton, Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import * as Utils from 'app/blocks/common/utils';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';
import payment from 'app/pages/orders/orders.payment';

@autobind
class PaymentReviewPanel extends React.Component {
    state = { isEditing: false };

    @processing('isEditing')
    async onRequestEditOrder() {
        await this.props.onRequestEditOrder(PaymentPanelEnum.PAYMENT);
    }

    requestReceipt = url => {
        window.open(url, '_blank');
    };

    render() {
        const {
            DataItemAndAuxInfo,
            isEditable,
            isPaidAlipayOrder,
            isPaidCCReceiptPending,
            isPaidOAAlipayOrder,
            isPaidOOAlipayOrder,
            l,
            orderStatusString,
        } = this.props;
        const { isEditing } = this.state;

        return (
            <div className="row order-btm-border" data-seleniumid="order-payment-panel-review">
                <div className="col-md-10 col-12 nopadding m-btm_md">
                    <div>
                        <div className="m-btm_md">
                            <h5>{l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.TITLE')}</h5>
                        </div>
                        <div className="m-btm_sm">
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[
                                    [
                                        'paymentDetails.paymentMethod',
                                        '',
                                        '',
                                        function (value) {
                                            return payment.getMethodByKey(value).text;
                                        },
                                    ],
                                ]}
                                seleniumid="order-review-payment-method"
                            />
                            {isPaidAlipayOrder && (
                                <div>
                                    <span data-seleniumid="alipay-order-id">
                                        {' '}
                                        {l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.SOCOPAY_FOR_FAPIAO_ORDER_NO')}
                                        {
                                            DataItemAndAuxInfo.auxInfo.order.chinaPayPricingDetails.socopayOrderNumber
                                        }{' '}
                                    </span>
                                    <br />
                                    <span data-seleniumid="alipay-as-order-id">
                                        {' '}
                                        {l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.SOCOPAY_FOR_AS_ORDER_NO')}
                                        {DataItemAndAuxInfo.auxInfo.order.paymentDetails.asSocopayOrderNumber}{' '}
                                    </span>
                                    <br />
                                    {isPaidOAAlipayOrder && (
                                        <span data-seleniumid="alipay-paid-on-date">
                                            Paid on {Utils.date(DataItemAndAuxInfo.auxInfo.order.orderPaymentDate)}
                                            {orderStatusString === 'Payment Processing' && (
                                                <b> (Payment Processing) </b>
                                            )}
                                        </span>
                                    )}
                                    {isPaidOOAlipayOrder && (
                                        <span data-seleniumid="alipay-paid-on-date">
                                            Paid on {Utils.date(DataItemAndAuxInfo.auxInfo.order.orderPaymentDate)}
                                        </span>
                                    )}
                                    <br />
                                    <Button
                                        data-seleniumid="socopay-order-request-button"
                                        onClick={() =>
                                            this.requestReceipt(
                                                DataItemAndAuxInfo.auxInfo.order.chinaPayPricingDetails.fapiaoUrl,
                                            )
                                        }
                                    >
                                        {l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.REQUEST_RECEIPT_BTN')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        hidden={
                            payment.method.CREDIT_CARD == DataItemAndAuxInfo.auxInfo.order.paymentDetails.paymentMethod
                        }
                    >
                        <DataItemAndAuxInfo.OrdersDataItem
                            innerInfo={[['paymentDetails.poNumber']]}
                            seleniumid="order-review-payment-poNumber"
                            titleCls="order-article"
                            titleTxt="Purchase order number "
                        />
                    </div>
                    <div
                        hidden={
                            payment.method.CREDIT_CARD != DataItemAndAuxInfo.auxInfo.order.paymentDetails.paymentMethod
                        }
                    >
                        <DataItemAndAuxInfo.OrdersDataItem
                            innerInfo={[['paymentDetails.displayedCreditCardNumber']]}
                            seleniumid="order-review-payment-maskedCardNumber"
                            titleCls="order-article"
                            titleTxt="Credit card number "
                        />
                        <DataItemAndAuxInfo.OrdersDataItem
                            innerInfo={[['paymentDetails.displayedCreditCardExpiry']]}
                            seleniumid="order-review-payment-cardExpire"
                            titleCls="order-article"
                            titleTxt="Expiration date "
                        />
                        {isPaidCCReceiptPending && (
                            <div>
                                <span data-seleniumid="credit-card-in-progress-id">
                                    <b> {l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.CREDIT_CARD_RECEIPT_IN_PROGRESS')} </b>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-2 col-12 m-btm_mini text-right nopadding">
                    <div hidden={!isEditable}>
                        <LinkButton
                            data-seleniumid="order-review-payment-edit-button"
                            disabled={isEditing}
                            isBlack
                            onClick={this.onRequestEditOrder}
                        >
                            <SvgIcon.edit iconTitle="Edit" />
                            {isEditing ? l('BUTTONS.LOADING') : l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.EDIT_BTN')}
                        </LinkButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes2(ID.ORDER_PANELS, ID.BUTTONS)(PaymentReviewPanel);
