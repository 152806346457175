import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './StatusPopup.scss';

const StatusPopupTypeEnum = {
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
} as const;

type Props = {
    children: string;
    onCloseStatusPopup: () => void;
    seleniumid: string;
    type: Values<typeof StatusPopupTypeEnum>;
    isCloseButton?: boolean;
    isOkayIcon?: boolean;
};

const StatusPopup = React.forwardRef<HTMLDivElement, Props>(
    ({ children, isCloseButton = true, isOkayIcon = false, onCloseStatusPopup, seleniumid, type }, ref) => {
        if (children) {
            return (
                <div ref={ref} className={`StatusPopup StatusPopup--${type}`}>
                    {type === StatusPopupTypeEnum.ERROR && (
                        <SvgIcon.error className="m-r_mini" iconTitle="Alert icon" />
                    )}
                    {type === StatusPopupTypeEnum.INFO && isOkayIcon && (
                        <SvgIcon.ok className="m-r_mini" iconTitle="ok icon" />
                    )}
                    <p
                        className="StatusPopup-Text"
                        dangerouslySetInnerHTML={{ __html: children }}
                        data-seleniumid={`${seleniumid}-text`}
                    />
                    {isCloseButton && (
                        <button
                            className="StatusPopup-Button"
                            data-seleniumid={`${seleniumid}-close`}
                            onClick={onCloseStatusPopup}
                            type="button"
                        >
                            <SvgIcon.cancel className="m-r_mini svg-icon--close" iconTitle="Cancel icon" />
                        </button>
                    )}
                </div>
            );
        }
        return null;
    },
);

export { StatusPopupTypeEnum };
export default StatusPopup;
