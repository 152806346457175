import { autobind } from 'core-decorators';
import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { ArticlePayload } from 'app/blocks/middleware/dashboard';
import checkChanges from 'app/blocks/middleware/http/polling';
import DashboardFilter from './DashboardFilter';
import EmptyDashboard from './EmptyDashboard';
import EmptySearch from './EmptySearch';
import Item from './Item';
import { isMatchedByWords } from './utils';
import { getSearchWords, isMatchedByDoi } from './utils-regex';

const PAGE_SIZE = 10;
const SEARCH_DEBOUNCE = 300;

function articlesText(cnt) {
    return cnt > 1 ? 'articles' : 'article';
}

function showNextText(current, length) {
    const left = Math.min(length - current, PAGE_SIZE);
    const articlesTxt = articlesText(left);

    return `Show next ${left} ${articlesTxt}`;
}

type Props = {
    response: { payload: ArticlePayload[] };
    code?: string;
    error?: string;
    focusOnArticle?: string;
    l: l;
    refId?: string;
};

type State = {
    items: ArticlePayload[];
    showCnt: number;
    showFilteredCnt: number;

    filtered: ArticlePayload[] | null;
    foundByDoi: Record<string, boolean>;
    searchValue?: string;
    lastSearchValue?: string;
    searchWords: [];
};

@autobind
class DashboardPage extends Component<Props, State> {
    static defaultProps = {
        code: null,
        error: null,
        focusOnArticle: null,
        refId: null,
    };

    state: State = {
        filtered: null,
        foundByDoi: {},
        items: [],
        lastSearchValue: '',
        searchValue: '',
        searchWords: [],
        showCnt: 0,
        showFilteredCnt: 0,
    };

    polling;

    search;

    constructor(props) {
        super(props);

        this.search = debounce(this.searchItems, SEARCH_DEBOUNCE);
    }

    componentDidMount() {
        const { response } = this.props;

        this.reload(response?.payload);

        this.polling = checkChanges(response);
        this.polling.watch(payload => {
            this.reload(payload);
            this.searchItems(this.state.searchValue);
        });
    }

    componentWillUnmount() {
        if (this.polling) {
            this.polling.stop();
        }
    }

    reload(payload) {
        const { code, error, focusOnArticle, l, refId } = this.props;

        const message = (code && l(`ERROR.${code}`)) || error;
        if (message) {
            showDialog.error({ message, refId });
        }
        const items = payload?.items || [];
        this.setState(
            {
                items,
                showCnt: focusOnArticle ? items.length : Math.min(items.length, PAGE_SIZE),
            },
            () => {
                if (focusOnArticle) {
                    setTimeout(() => {
                        const el = document.getElementById(focusOnArticle);

                        if (el) {
                            el.scrollIntoView(true);
                        } else {
                            showDialog.error({
                                message: this.props.l('ERROR.ERROR_ARTICLE_IS_NOT_ASSIGNED', { id: focusOnArticle }),
                                onClose: () => window.history.back(),
                            });
                        }
                    }, 1);
                }
            },
        );
    }

    searchItems(value = '') {
        this.setState({ lastSearchValue: value });

        if (!value) {
            this.setState({
                filtered: null,
                foundByDoi: {},
                searchWords: [],
                showFilteredCnt: 0,
            });

            return;
        }

        const { items } = this.state;
        const result = [];

        const words = getSearchWords(value);

        if (!words.length) {
            this.setState({
                filtered: [],
                foundByDoi: {},
                searchWords: [],
                showFilteredCnt: 0,
            });

            return;
        }

        const foundByDoi = {};

        items.forEach(item => {
            const { article, journal } = item;

            const doiFound = isMatchedByDoi(article.doi, value);
            const wordsFound = isMatchedByWords(article.name, journal.name, words);

            if (wordsFound || doiFound) {
                result.push(item);
            }

            if (doiFound) {
                foundByDoi[article.id] = true;
            }
        });

        this.setState({
            filtered: result,
            foundByDoi,
            searchWords: words,
            showFilteredCnt: Math.min(result.length, PAGE_SIZE),
        });
    }

    startSearch(searchValue) {
        this.setState({ searchValue }, () => this.search(searchValue));
    }

    clearSearch() {
        this.setState({
            filtered: null,
            foundByDoi: {},
            searchValue: '',
            searchWords: [],
            showFilteredCnt: 0,
        });
    }

    showNextFiltered() {
        let firstNext = null;
        this.setState(
            ({ filtered, showFilteredCnt }) => {
                firstNext = filtered[showFilteredCnt]?.article?.id;
                return {
                    showFilteredCnt: Math.min(showFilteredCnt + PAGE_SIZE, filtered.length),
                };
            },
            () => firstNext && setTimeout(() => document.getElementById(firstNext)?.scrollIntoView?.(true)),
        );
    }

    showNext() {
        let firstNext = null;
        this.setState(
            ({ items, showCnt }) => {
                firstNext = items[showCnt]?.article?.id;
                return {
                    showCnt: Math.min(showCnt + PAGE_SIZE, items.length),
                };
            },
            () => firstNext && setTimeout(() => document.getElementById(firstNext)?.scrollIntoView?.(true)),
        );
    }

    render() {
        const { filtered, foundByDoi, items, lastSearchValue, searchValue, searchWords, showCnt, showFilteredCnt } =
            this.state;

        let dashboardItems: ArticlePayload[];
        let nextButton;

        if (filtered !== null) {
            dashboardItems = filtered.slice(0, showFilteredCnt);
            nextButton =
                filtered.length > showFilteredCnt ? (
                    <Button data-seleniumid="show-next" onClick={this.showNextFiltered}>
                        {showNextText(showFilteredCnt, filtered.length)}
                    </Button>
                ) : null;
        } else {
            dashboardItems = items.slice(0, showCnt);
            nextButton =
                items.length > showCnt ? (
                    <Button data-seleniumid="show-next" onClick={this.showNext}>
                        {showNextText(showCnt, items.length)}
                    </Button>
                ) : null;
        }

        return (
            <>
                {!!items.length && (
                    <DashboardFilter
                        articlesTxt={articlesText(items.length)}
                        clearSearch={this.clearSearch}
                        filtered={filtered}
                        items={items}
                        searchValue={searchValue}
                        startSearch={this.startSearch}
                    />
                )}

                <div className="Dashboard">
                    {dashboardItems.map(item => {
                        return (
                            <Item
                                key={item.article.id}
                                isFoundByDoi={foundByDoi[item.article.id]}
                                item={item}
                                searchWords={searchWords}
                            />
                        );
                    })}

                    {!!nextButton && <div className="NextButton">{nextButton}</div>}

                    {!!items.length && filtered && !filtered.length && (
                        <EmptySearch lastSearchValue={lastSearchValue} searchWords={searchWords} />
                    )}

                    {!items.length && <EmptyDashboard />}
                </div>
            </>
        );
    }
}

export default withCodes(DashboardPage, ID.ERROR);
