import React, { Component } from 'react';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import { processing } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import validation, { checkPassStrength } from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import Layout from 'app/blocks/Layout/Layout';
import { resetUsingCode, verifyResetLink } from 'app/blocks/middleware/password';
import Password from 'app/blocks/Password/Password';
import PasswordStrength from 'app/blocks/password-strength';
import routes from 'app/pages/routes';

type Props = {
    uuid: string;
    l: l;
};

type State = {
    isLoading: boolean;
    isProcessing: boolean;
    errorLoading?: Error;

    email: string;

    password: string;
    passwordError?: string;
    passwordStrength?: Awaited<ReturnType<typeof checkPassStrength>>;

    passwordConfirm: string;
    passwordConfirmError?: string;
};

class ResetPassword extends Component<Props, State> {
    state: State = {
        email: '',
        errorLoading: null,
        isLoading: true,

        isProcessing: false,

        password: '',
        passwordConfirm: '',
        passwordConfirmError: '',

        passwordError: '',
        passwordStrength: null,
    };

    @processing('isLoading')
    async componentDidMount() {
        const { l, uuid } = this.props;

        if (uuid) {
            try {
                const payload = await verifyResetLink(uuid);

                this.setState({ email: payload.emailId });
            } catch (error) {
                showDialog.error(error, {
                    message:
                        l(`RESET_PASSWORD.errorMessages.${error.code}`, null, null) ||
                        (await ContentUtils.getServerErrorMessage(ID.RESET_PASSWORD, error)),
                    onClose: () => routes.toLogin(),
                });
                this.setState({ errorLoading: error });
            }
        }
    }

    onChangePassword = async event => {
        const v = event.target.value;

        this.setState({
            password: v,
            passwordError: '',
        });

        // separate setState required to not block inputting
        this.setState({ passwordStrength: await checkPassStrength(v) });
    };

    onChangePasswordConfirm = event => {
        this.setState({
            passwordConfirm: event.target.value,
            passwordConfirmError: '',
        });
    };

    validate() {
        const { l } = this.props;
        const { password, passwordConfirm } = this.state;

        const passwordError = validation.validate(password, [
            [validation.notEmpty, l('RESET_PASSWORD.errorMessages.RESET_PASSWORD_MISSING_NEW_PWD_ERR_TEXT')],
            [validation.isPasswordValid, l('RESET_PASSWORD.errorMessages.RESET_STRICT_PASSWORD_PWD_INVALID_ERR_TEXT')],
        ]);

        const passwordConfirmError = validation.validate(passwordConfirm, [
            [validation.notEmpty, l('RESET_PASSWORD.errorMessages.RESET_PASSWORD_MISSING_CONFIRM_PWD_ERR_TEXT')],
            [
                () => password === passwordConfirm,
                l('RESET_PASSWORD.errorMessages.RESET_PASSWORD_INVALID_CONFIRM_PWD_ERR_TEXT'),
            ],
        ]);

        this.setState({
            passwordConfirmError,
            passwordError,
        });

        return !(passwordError || passwordConfirmError);
    }

    next = async () => {
        if (!this.validate()) {
            return;
        }

        const { l, uuid } = this.props;

        const { email, password } = this.state;

        try {
            Alerts.renderSpinner();
            this.setState({ isProcessing: true });
            if (uuid) {
                await resetUsingCode(email, password, uuid);
            }

            showDialog.success({
                message: l('RESET_PASSWORD.confirmationMessages.RESET_PASSWORD_PWD_CHANGED_SUCCESS_POST_CONF_TEXT'),
                onClose: () => routes.toLogin(),
            });
        } catch (error) {
            showDialog.error(error, {
                message: await ContentUtils.getServerErrorMessage(ID.RESET_PASSWORD, error),
            });
        }
        Alerts.removeSpinner();
        this.setState({ isProcessing: false });
    };

    cancel = () => {
        routes.home(true);
    };

    render() {
        const { l } = this.props;

        const {
            errorLoading,
            isLoading,
            isProcessing,

            password,
            passwordConfirm,
            passwordConfirmError,

            passwordError,
            passwordStrength,
        } = this.state;

        if (isLoading || errorLoading) {
            return <Layout error={errorLoading} isLoading={isLoading} />;
        }

        return (
            <>
                <div className="SuccessMessage">
                    {l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_ID_VERIFY_LABEL')}
                </div>

                <div className="RecoveryPasswordBlock">
                    <div className="RecoveryPasswordRow">
                        <label className="RecoveryPasswordRow-Label" htmlFor="reset-password-new-input">
                            {l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_NEW_PASSWORD_LABEL')}
                            <span className="Required">*</span>
                        </label>
                        <div className="RecoveryPasswordRow-Input">
                            <Password
                                className="RecoveryPassword-New"
                                id="reset-password-new-input"
                                isError={!!passwordError}
                                onChange={this.onChangePassword}
                                placeholder={l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_PWD_PLACEHOLDER')}
                                seleniumid="reset-password-new-input"
                                value={password}
                            />
                            {passwordError && <ErrorLabel text={passwordError} />}
                            {password && passwordStrength && <PasswordStrength {...passwordStrength} />}
                        </div>
                        <div className="RecoveryPasswordRow-SubText">
                            {l('RESET_PASSWORD.errorMessages.RESET_STRICT_PASSWORD_PWD_INVALID_ERR_TEXT')}
                        </div>
                    </div>

                    <div className="RecoveryPasswordRow">
                        <label className="RecoveryPasswordRow-Label" htmlFor="reset-password-confirm-input">
                            {l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_CONFIRM_PASSWORD_LABEL')}
                            <span className="Required">*</span>
                        </label>
                        <div className="RecoveryPasswordRow-Input">
                            <Password
                                className="RecoveryPassword-Confirm"
                                id="reset-password-confirm-input"
                                isError={!!passwordConfirmError}
                                onChange={this.onChangePasswordConfirm}
                                placeholder={l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_CONFIRM_PWD_PLACEHOLDER')}
                                seleniumid="reset-password-confirm-input"
                                value={passwordConfirm}
                            />
                            {passwordConfirmError && <ErrorLabel text={passwordConfirmError} />}
                        </div>
                    </div>

                    <div className="RecoveryPasswordRow RecoveryPasswordRow-Actions">
                        <Button
                            data-seleniumid="reset-password-cancel-button"
                            id="reset-password-cancel-button"
                            onClick={this.cancel}
                        >
                            {l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_CANCEL_BUTTON_LABEL')}
                        </Button>
                        <PrimaryButton
                            data-seleniumid="reset-password-next-button"
                            disabled={isProcessing}
                            id="reset-password-next-button"
                            onClick={this.next}
                        >
                            {l('RESET_PASSWORD.uiLabelMessages.RESET_PASSWORD_NEXT_BUTTON_LABEL')}
                        </PrimaryButton>
                    </div>
                </div>
            </>
        );
    }
}

export { ResetPassword };
export default withCodes(ResetPassword, ID.RESET_PASSWORD);
