import cn from 'classnames';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { SubTitle } from 'app/blocks/titles/titles';
import hasInstitutionalDiscount from 'app/pages/license-signing-process/institutionalDiscount';
import OrderCurrencyInfo from './OrderCurrencyInfo';
import { DiscountedPriceList, PriceList } from './PriceLists';
import './pricesSection.scss';

type Props = {
    className?: string;
    isVertical?: boolean;
    isTransparent?: boolean;
    l: l;
    prices: Array<{
        amount: number;
        base?: number;
        name: string;
        symbol: string;
    }>;
};

function PricesVertical({
    className,
    isDiscounted,
    isTransparent,
    l,
    prices,
}: { isDiscounted?: boolean } & Omit<Props, 'isVertical'>) {
    const List = isDiscounted ? DiscountedPriceList : PriceList;
    const cns = cn('orderPrices orderPrices--vertical', { 'orderPrices--transparent': isTransparent }, className);
    return (
        <section className={cns} data-seleniumid="order__section_prices">
            <SubTitle className="headingText" data-seleniumid="open-access-article-publication-charge-title">
                {l(`${ID.ORDERS}.PUBLICATION_CHARGE`)}
            </SubTitle>
            <List l={l} prices={prices} />
        </section>
    );
}

function PricesHorizontal({ className, l, prices }: Pick<Props, 'className' | 'l' | 'prices'>) {
    return (
        <section
            className={cn('orderPrices orderPrices--horizontal', className)}
            data-seleniumid="order__section_prices"
        >
            <SubTitle className="headingText" data-seleniumid="open-access-article-publication-charge-title">
                {l(`${ID.ORDERS}.PUBLICATION_CHARGE`)}
            </SubTitle>

            <ul className="ul-drop-list-styles orderPrices-items" data-seleniumid="order__prices">
                {prices.map(price => (
                    <li key={price.name} className="orderPrices-item">
                        <span className="orderPrices-name">{price.name}</span>
                        {price.symbol}
                        <span data-seleniumid={`order__price_${price.name}`}>{price.amount}</span>
                    </li>
                ))}
            </ul>

            <OrderCurrencyInfo />
        </section>
    );
}

function PricesSection({ className, isTransparent, isVertical = false, l, prices }: Props) {
    if (hasInstitutionalDiscount(prices)) {
        return (
            <PricesVertical className={className} isDiscounted isTransparent={isTransparent} l={l} prices={prices} />
        );
    }

    if (isVertical) {
        return <PricesVertical className={className} l={l} prices={prices} />;
    }

    return <PricesHorizontal className={className} l={l} prices={prices} />;
}

export { PricesHorizontal, PricesVertical };
export default withCodes(PricesSection, ID.LICENSE_SIGNING, ID.ORDERS);
