import cn from 'classnames';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Action from 'app/blocks/Panel/actions/buttons/Button';
import ArticlePageContext from './ArticlePage.context';

// [ Button ] view
function DashboardButton({ button, children, disabled, seleniumid, ...props }): React.ReactElement {
    const item = React.useContext(ArticlePageContext);

    return (
        <a
            {...props}
            className={cn('btn', {
                'btn-blue btn-right-icon': button.primary,
                'btn-white': !button.primary,
                disabled,
            })}
            data-seleniumid={`${item.id}${item.journal.openAccess ? '-OA' : ''}-${seleniumid || button.code}`}
        >
            {children}
            {button.primary && (
                <SvgIcon.next iconTitle="Forward Icon" seleniumid="forward-icon" style={{ marginRight: '-4px' }} />
            )}
        </a>
    );
}

// <link> view
function DashboardLinkButton(props) {
    return <LinkButton isLinkTag {...props} />;
}

// classical [ Button ]
export function defaultAction(button) {
    return !button ? null : <Action key={button.code} Control={DashboardButton} item={button} />;
}

// hacked [ Button ]
export function CustomAction({ children, onClick, seleniumid }) {
    return (
        <Action
            Control={DashboardButton}
            item={{ code: seleniumid, title: children }} // FIXME remove item workaround
            onClick={onClick}
            seleniumid={seleniumid}
        >
            {children}
        </Action>
    );
}

// classical <link>
export function linkAction(button) {
    return !button ? null : <Action Control={DashboardLinkButton} data-seleniumid={button.code} item={button} />;
}

// list of <links>
export function linkActions(buttons = []) {
    return buttons.map(b => linkAction(b));
}

// hacked <link>
export function CustomLink({ button, children, ...props }) {
    return !button && !props.onClick && !props.href ? null : (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <Action Control={p => <a {...p} />} item={button} {...props}>
            {children}
        </Action>
    );
}

function splitActions(actions) {
    const required = actions.filter(a => a.primary);
    const notRequired = actions.filter(a => !a.primary);

    return [notRequired, required];
}

// list of [ Buttons ]
function DefaultActions({ buttons }) {
    const sa = splitActions(buttons);

    if (buttons.length === 1) {
        return defaultAction(buttons[0]);
    }
    if (buttons.length > 1) {
        return (
            <>
                {sa[0].map(a => defaultAction(a))}
                {sa[1].map(a => defaultAction(a))}
            </>
        );
    }

    return null;
}

export default DefaultActions;

/* Action buttons policy: (R - required, N - non-required)

1R
        [           ]

1N
(                   )

2R
            [       ]
        [           ]

1R + 1N
[                   ]
(                   )

2N
(       ) (         )

3R
            [       ]
        [           ]
            [       ]

2R + 1N
[                   ]
[                   ]
(                   )

1R + 2N
[                   ]
(       ) (         )

3N
(                   )
(                   )
(                   )

4R
            [       ]
        [           ]
          [         ]
      [             ]

3R + 1N
[                   ]
[                   ]
[                   ]
(                   )

2R + 2N
[                   ]
[                   ]
(       ) (         )

1R + 3N
[                   ]
(                   )
(                   )
(                   )

4N
(       )  (        )
(         ) (       )

*/
