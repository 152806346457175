export const Errors = {
    FUNDER_DUPLICATED: 'ERROR.FUNDER_DUPLICATED',
    FUNDER_EMPTY: 'ERROR.FUNDER_EMPTY',
    GRANT_NUMBER_EMPTY: 'ERROR.GRANT_NUMBER_EMPTY',
};

function validateResearchFunder(researchFunder, blacklist) {
    if (researchFunder) {
        if (!researchFunder.custom && blacklist.filter(x => !x.custom).some(x => x.id === researchFunder.id)) {
            return [{ researchFunder: Errors.FUNDER_DUPLICATED }];
        }

        return [];
    }

    return [{ researchFunder: Errors.FUNDER_EMPTY }];
}

function validateResearchProgram(targetProgram, selectedPrograms) {
    const errors = validateResearchFunder(
        targetProgram.researchFunder,
        selectedPrograms.filter(x => x._id !== targetProgram._id).map(x => x.researchFunder),
    );

    const haveNonEmptyGrantNumber = targetProgram.grantNumbers.some(number => !!number);
    if (!haveNonEmptyGrantNumber) {
        errors.push({ grantNumbers: Errors.GRANT_NUMBER_EMPTY });
    }

    if (errors.length > 0) {
        return Promise.reject(Object.assign(new Error('Validation failed'), ...errors));
    }

    return Promise.resolve();
}

export { validateResearchFunder, validateResearchProgram };
export default function (state) {
    if (!state.editingFunder || state.noResearchPrograms) {
        return Promise.resolve();
    }

    return validateResearchProgram(state.editingFunder, state.researchPrograms);
}
