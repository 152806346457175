import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

type Props = {
    l: l;
    user: Registration.ExistedUser;
};

function UserExistsDialogUser({
    l,
    user: {
        firstName,
        institution,
        lastName,
        middleName,
        orcidId,
        primaryEmailAddr,
        publishedInJournal,
        suffix,
        title,
    },
}: Props): React.ReactElement {
    const renderDataRow = (label, text) => (
        <div key={label} className="userExists-selectedUserDataRow">
            <div className="userExists-selectedUserDataLabel">
                <p>{label}</p>
            </div>
            <div className="userExists-selectedUserDataText">
                <p>{text}</p>
            </div>
        </div>
    );

    const renderPersonalLines = list => list.map(text => text && <span>{text} </span>);

    return (
        <div className="userExists-selectedUser" id="registerUserInfo">
            {renderDataRow(
                <SvgIcon.person className="register-person-icon" iconDescr="Person Icon" iconTitle="Person Icon" />,
                renderPersonalLines([title, firstName, middleName, lastName, suffix]),
            )}
            {orcidId && renderDataRow(l('REGISTRATION.uiLabelMessages.REGISTRATION_ORCID_ID_LABEL'), orcidId)}
            {renderDataRow(l('REGISTRATION.uiLabelMessages.REGISTRATION_USER_ID_LABEL'), primaryEmailAddr)}
            {renderDataRow(l('REGISTRATION.uiLabelMessages.REGISTRATION_PUBLISHED_IN_JOURN_LABEL'), publishedInJournal)}
            {renderDataRow(l('REGISTRATION.uiLabelMessages.REGISTRATION_INST_LABEL'), institution)}
        </div>
    );
}

export default withCodes(UserExistsDialogUser, ID.REGISTRATION);
