import React from 'react';
import { Button, LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { BackButton, ForwardButton } from 'app/pages/license-signing-process/buttons';
import LicenseSigningContext from 'app/pages/license-signing-process/context/Context';
import Context from 'app/pages/license-signing-process/Payment/GOA/Context';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import PaymentPanel from './PaymentPanel';

class ReviewFinalChargesStep extends React.PureComponent {
    render() {
        const {
            deleteDraft,
            goBack,
            isConfirming,
            isDraftBeingDeleted,
            isDraftDeletionAllowed,
            isOrderExisting,
            isSaveAndPreviewAllowed,
            isSavingForLater,
            l,
            onReviewConfirm,
            order,
            saveForLater,
        } = this.props;

        return (
            <>
                <section className="GOAPayment GOAPayment--review">
                    <PaymentPanel order={order} />
                </section>
                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--payment">
                    <div className="page-controls">
                        <BackButton onClick={goBack}>
                            {l('LICENSE_SIGNING.BUTTON.BACK_TO_DISCOUNT_AND_BILLING')}
                        </BackButton>

                        {isDraftDeletionAllowed && (
                            <LinkButton
                                data-seleniumid="deleteDraftButton"
                                isDanger
                                isProcessing={isDraftBeingDeleted}
                                isUnderline
                                onClick={deleteDraft}
                            >
                                <SvgIcon.delete iconTitle="Delete Icon" />
                                {l('LICENSE_SIGNING.BUTTON.DELETE_DRAFT')}
                            </LinkButton>
                        )}
                        {!isOrderExisting && (
                            <Button
                                data-seleniumid="saveForLaterButton"
                                isProcessing={isSavingForLater}
                                onClick={saveForLater}
                                processingLabel={l('BUTTONS.SAVING')}
                            >
                                {l('LICENSE_SIGNING.BUTTON.SAVE_FOR_LATER')}
                            </Button>
                        )}
                        <ForwardButton
                            data-seleniumid="goForthButton"
                            disabled={!isSaveAndPreviewAllowed}
                            isProcessing={isConfirming}
                            onClick={onReviewConfirm}
                            processingLabel={l('BUTTONS.PROCESSING')}
                        >
                            {l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                        </ForwardButton>
                    </div>
                </div>
            </>
        );
    }
}

export { ReviewFinalChargesStep };
export default compose(
    withScrollToTop,
    LicenseSigningContext.withContext(state => ({
        hasLicenseSubmissionStep: !!state.steps.find(x => x.id === StepEnum.LICENSE_SUBMISSION),
        goBack: state.goBack,
    })),
    Context.withContext(state => ({
        isConfirming: state.initiate.isConfirming,
        onReviewConfirm: state.onReviewFinalChargesConfirm,
        canConfirm: !!state.initiate?.payment?.paymentDetails?.paymentMethod,
        order: state.order,
        isSaveAndPreviewAllowed: state.initiate.isSaveAndPreviewAllowed,
        isSavingForLater: state.initiate.isSavingForLater,
        saveForLater: state.saveForLater,
        isDraftBeingDeleted: state.initiate.isDraftBeingDeleted,
        deleteDraft: state.deleteDraft,
        isDraftDeletionAllowed: state.canDeleteDraft,
        isOrderExisting: state.isOrderExisting,
    })),
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(ReviewFinalChargesStep);
