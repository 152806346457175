import { autobind } from 'core-decorators';
import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';
import PaymentAmountBreakdown from 'app/pages/license-signing-process/Payment/Review/PaymentAmountBreakdown';
import flow from 'app/pages/orders/orders.flow';
import OrdersPanelPriceInfo from 'app/pages/orders/panel/price-info';

@autobind
class OrdersOOAmountPayable extends React.Component {
    state = { isEditing: false };

    @processing('isEditing')
    async onRequestEditOrder() {
        await this.props.onRequestEditOrder(PaymentPanelEnum.VAT_TAX);
    }

    render() {
        const { DataItemAndAuxInfo, isEditable, isPaidOOAlipayOrder, isShowTax, l } = this.props;
        const { isEditing } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 m-btm_md">
                                <div className="m-btm_md">
                                    <h5 data-seleniumid={isPaidOOAlipayOrder ? 'amount-paid' : 'amount-payable'}>
                                        {isPaidOOAlipayOrder
                                            ? l('ORDER_PANELS.OO_AMOUNT_PAYABLE.AMOUNT_PAID')
                                            : l('ORDER_PANELS.OO_AMOUNT_PAYABLE.TITLE')}
                                    </h5>
                                </div>
                                {flow.isPaymentMethodAlipay(DataItemAndAuxInfo.auxInfo.order) && isPaidOOAlipayOrder ? (
                                    <PaymentAmountBreakdown
                                        chinaPayDetails={DataItemAndAuxInfo.auxInfo.order.chinaPayPricingDetails}
                                        isOrderHistory
                                        order={DataItemAndAuxInfo.auxInfo.order}
                                    />
                                ) : (
                                    <OrdersPanelPriceInfo
                                        isShowTax={
                                            flow.isPaymentMethodAlipay(DataItemAndAuxInfo.auxInfo.order)
                                                ? !isShowTax
                                                : isShowTax
                                        }
                                        labels={{ amountPayable: l('ORDER_PANELS.OO_AMOUNT_PAYABLE.AMOUNT_PAYABLE') }}
                                        prices={DataItemAndAuxInfo.auxInfo.order.pricingDetails}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2 col-4 text-right">
                        <div hidden={!isEditable}>
                            <Button
                                className="edit"
                                data-seleniumid="order-review-pricing-edit-button"
                                disabled={isEditing}
                                onClick={this.onRequestEditOrder}
                            >
                                {isEditing ? l('BUTTONS.LOADING') : l('ORDER_PANELS.OO_AMOUNT_PAYABLE.EDIT_BTN')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes2(ID.ORDER_PANELS, ID.BUTTONS)(OrdersOOAmountPayable);
