import { TimeoutCodes } from 'app/blocks/common/http-statuses';
import { httpGET, httpPOST } from '../utils';

export const POLLING_CONFIG = {
    DIRTY_CHECK_INTERVAL: 30 * 1000,
    DIRTY_CHECK_INTERVAL_MAX: 5 * 60 * 1000,
    DIRTY_CHECK_INTERVAL_STEP: 10 * 1000,
    DIRTY_ERRORS_LIMIT: 3,

    FLOOD_WITH_UPDATES: {
        DELAY: 60 * 1000,
        INTERPRET_AS_INCORRECT: 10,
        INTERPRET_AS_STOP_WORKING: 50,
        LIMIT: 5,
    },
    INTERPRET_AS_FLOOD: 10 * 1000,

    POLLING_ERRORS_LIMIT: 5,
    STOP_AFTER: 3 * 60 * 60 * 1000,
    SWITCH_TO_DIRTY_AFTER: 60 * 60 * 1000,
};

const FakePolling = { start: () => {}, stop: () => {}, watch: () => {} };

export default function checkChanges(response, config = POLLING_CONFIG) {
    if (!response || !response.url || response.statusCode >= 400 || !response.getHeader) return FakePolling;

    let ETag = response.getHeader('etag');
    if (!ETag) return FakePolling;

    let startTime = performance.now();

    // add '/check/status' suffix to URL (agreement with BE)
    let pollingUrl = response.url.replace(/(\/)?(\?|#|$)/, '/check/status$2');

    let watchMode = false;
    let onChanges = () => {};

    const abortController = new AbortController();
    let isStopped = false;
    let isDirty = false;
    function stop() {
        isStopped = true;
        abortController.abort();
    }

    let unknownSituations = 0;
    function raiseUnknown() {
        unknownSituations += 1;
        if (unknownSituations > config.POLLING_ERRORS_LIMIT) {
            isDirty = true;
        }
    }

    let oldPayloadJSON = JSON.stringify(response.payload);
    let dirtyErrors = 0;
    let dirtyCheckInterval = config.DIRTY_CHECK_INTERVAL;
    async function dirtyCheck() {
        let payload;
        try {
            payload = await httpGET(response.url, undefined, {
                ajaxParams: { doNotTrack: true },
                urlParams: { 'auto-refresh': true },
            });
            dirtyErrors = 0;
        } catch (error) {
            dirtyErrors += 1;
            if (dirtyErrors > config.DIRTY_ERRORS_LIMIT) {
                isStopped = true;
            } else {
                payload = null;
            }
        }

        const nowTime = performance.now();
        if (nowTime - startTime > config.STOP_AFTER) {
            // time exceed
            isStopped = true;
        }

        if (!isStopped) {
            if (payload) {
                const payloadJSON = JSON.stringify(payload);
                if (payloadJSON !== oldPayloadJSON) {
                    oldPayloadJSON = payloadJSON;

                    if (watchMode) {
                        startTime = performance.now();
                        dirtyCheckInterval = config.DIRTY_CHECK_INTERVAL;
                        onChanges(payload);
                    } else {
                        isStopped = true;
                        onChanges();
                        return;
                    }
                }
            }

            setTimeout(dirtyCheck, dirtyCheckInterval);

            dirtyCheckInterval = Math.min(
                dirtyCheckInterval + config.DIRTY_CHECK_INTERVAL_STEP,
                config.DIRTY_CHECK_INTERVAL_MAX,
            );
        }
    }

    let recentUpdatesCnt = 0;
    let falsePositiveLimit = config.FLOOD_WITH_UPDATES.INTERPRET_AS_INCORRECT;
    async function check() {
        const requestStartTime = performance.now();

        let payload;
        try {
            payload = await httpPOST(
                pollingUrl,
                { ifNoneMatch: ETag },
                {
                    ajaxParams: { doNotTrack: true },
                    signal: abortController.signal,
                    urlParams: { 'auto-refresh': true },
                },
            );
        } catch (error) {
            if (TimeoutCodes.includes(error.code)) {
                payload = { hasUpdates: false };
            } else {
                payload = {};
            }
        }

        if (isStopped) {
            return;
        }

        if (payload?.hasUpdates === true) {
            let newResponse;
            try {
                newResponse = await httpGET(response.url, undefined, {
                    ajaxParams: { doNotTrack: true },
                    detailed: true,
                    urlParams: { 'auto-refresh': true },
                });
            } catch (error) {
                newResponse = null;
            }

            if (newResponse) {
                ETag = newResponse.getHeader('etag');
                if (!ETag) {
                    // BE stops providing ETag - switch to regular requests
                    isDirty = true;
                }

                const payloadJSON = JSON.stringify(newResponse.payload);
                if (payloadJSON !== oldPayloadJSON) {
                    oldPayloadJSON = payloadJSON;

                    if (watchMode) {
                        startTime = performance.now();
                        onChanges(newResponse.payload);
                    } else {
                        isStopped = true;
                        onChanges();
                        return;
                    }
                }
            }
        }

        if (payload?.hasUpdates === false) {
            recentUpdatesCnt = 0;
            falsePositiveLimit = config.FLOOD_WITH_UPDATES.INTERPRET_AS_STOP_WORKING;
        }

        const nowTime = performance.now();
        let delay = 500;

        if (payload?.hasUpdates === undefined) {
            // strange response
            raiseUnknown();
            delay = config.INTERPRET_AS_FLOOD;
        } else if (nowTime - requestStartTime < config.INTERPRET_AS_FLOOD) {
            if (payload.hasUpdates) {
                recentUpdatesCnt += 1;
                // to many updates in short time - postpone a bit
                if (recentUpdatesCnt > config.FLOOD_WITH_UPDATES.LIMIT) {
                    delay = config.INTERPRET_AS_FLOOD;
                }
                // if exceed - treat as 'incorrect implementation' (always hasUpdates=true)
                if (recentUpdatesCnt > falsePositiveLimit) {
                    delay = config.FLOOD_WITH_UPDATES.DELAY;
                    raiseUnknown();
                }
            }

            // Incorrect implementation - responses too fast
            if (!payload.hasUpdates) {
                raiseUnknown();
                delay = config.INTERPRET_AS_FLOOD;
            }
        } else {
            // correct response in time - treat as 'polling was restored'
            unknownSituations = 0;
        }

        if (nowTime - startTime > config.SWITCH_TO_DIRTY_AFTER) {
            // time exceed
            isDirty = true;
        }

        if (!isStopped) {
            if (isDirty) {
                setTimeout(dirtyCheck, dirtyCheckInterval);
            } else {
                setTimeout(check, delay);
            }
        }
    }

    let isRunning = false;
    async function start(listener, pollUrl) {
        if (process.env.NODE_ENV === 'development') {
            return stop;
        }

        onChanges = listener;
        if (pollUrl) {
            pollingUrl = pollUrl;
        }

        if (!isRunning) {
            isRunning = true;
            check();
        }

        return stop;
    }

    return {
        start,
        stop,
        watch(...args) {
            watchMode = true;
            return start(...args);
        },
    };
}
