import React from 'react';
import { l } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import discountType, { byDiscountVisibility } from 'app/pages/license-signing-process/DiscountType';
import { composeAppliedDiscountText } from './Labels';

const DiscountRow = ({ discount, discountCountryName, showNoPaymentRequired }) => (
    <div className="appliedDiscount" data-seleniumid="discounts-applied">
        <SvgIcon.ok iconTitle="applied disctount" />
        <div className="appliedDiscount-message">
            <span key={discount?.discountDescription}>
                <span dangerouslySetInnerHTML={{ __html: composeAppliedDiscountText(discount, discountCountryName) }} />
                {showNoPaymentRequired && (
                    <span className="appliedDiscount-noPaymentRequired">
                        {' '}
                        {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.NO_PAYMENT_REQUIRED')}
                    </span>
                )}
            </span>
        </div>
    </div>
);

const checkArticleTypeWaiver = (appliedDiscounts: { discountType: discountType; percentageDiscount: number }[]) =>
    appliedDiscounts.some(
        (discount: { discountType: discountType; percentageDiscount: number }) =>
            discount.discountType === discountType.ArticleType && discount.percentageDiscount === 100,
    );

const AppliedDiscount = ({ appliedDiscounts, discountCountryName, showNoPaymentRequired }) => {
    const filteredDiscounts = checkArticleTypeWaiver(appliedDiscounts)
        ? appliedDiscounts.filter(discount => discount.discountType === discountType.ArticleType)
        : appliedDiscounts.filter(byDiscountVisibility);

    return (
        <>
            {filteredDiscounts.map(discount => (
                <DiscountRow
                    discount={discount}
                    discountCountryName={discountCountryName}
                    showNoPaymentRequired={showNoPaymentRequired}
                />
            ))}
        </>
    );
};

export default AppliedDiscount;
