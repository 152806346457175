import cn from 'classnames';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { ItemTitle } from 'app/blocks/titles/titles';

import './EligibilityMessage.scss';

type Props = {
    className?: string;
    message: React.ReactNode;
    title: string;
    isGray?: boolean;
    icon?: string;
    withHeader?: boolean;
};

export default function ({ className, icon = 'hand', isGray = false, message, title, withHeader = false }: Props) {
    const Icon = SvgIcon[icon];

    return (
        <div className={cn('EligibilityMessage', { isGray, withHeader }, className)} data-seleniumid="eligibility">
            <Icon className="EligibilityMessage-Icon" iconTitle={title} />

            <div className="EligibilityMessage-Body">
                <ItemTitle className="EligibilityMessage-Title" data-seleniumid="eligibility-title">
                    {title}
                </ItemTitle>

                {typeof message === 'string' ? (
                    <div
                        className="EligibilityMessage-Message"
                        dangerouslySetInnerHTML={{ __html: message }}
                        data-seleniumid="eligibility-message"
                    />
                ) : (
                    <div className="EligibilityMessage-Message" data-seleniumid="eligibility-message">
                        {message}
                    </div>
                )}
            </div>
        </div>
    );
}
