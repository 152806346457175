import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Image from 'app/blocks/image/image';
import { ArticlePayload } from 'app/blocks/middleware/dashboard';
import { SubTitle } from 'app/blocks/titles/titles';
import { highlight } from './utils';
import './details.scss';

type UiProps = {
    articleName: string;
    coverImageUrl: string;
    doi: string;
    eeoCaFullName: string;
    funderStatement: string;
    isFoundByDoi: boolean;
    isOnlineOpen: boolean;
    isOpenAccess: boolean;
    isPublished: boolean;
    isWithdrawn: boolean;
    l: l;
    journalName: string;
    statusCode: string;
};

export const UiArticleDetails = withCodes2(ID.DASHBOARD)(
    ({
        articleName,
        coverImageUrl,
        doi,
        eeoCaFullName,
        funderStatement,
        isFoundByDoi,
        isOnlineOpen,
        isOpenAccess,
        isPublished,
        isWithdrawn,
        journalName,
        l,
        statusCode,
    }: UiProps) => {
        let statusPrompt = '';

        if (statusCode === 'WAITING_CA_ACTION') {
            statusPrompt = l('DASHBOARD.ARTICLE_CARD.CA_PROMPT', { name: eeoCaFullName });
        } else if (statusCode === 'AUTHOR_LIST_IS_INCORRECT') {
            statusPrompt = l('DASHBOARD.ARTICLE_CARD.AUTHOR_LIST_IS_INCORRECT_PROMPT');
        }

        return (
            <div className="ArticleBlock-Content">
                <div className="Image" data-seleniumid="journal-image">
                    <Image className="full-width" src={coverImageUrl} type={Image.TYPES.JOURNAL} />
                </div>
                <div className="Info">
                    <div className="JournalTitle" data-seleniumid="journal-title">
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: journalName,
                                }}
                            />
                            {isOpenAccess && (
                                <SvgIcon.openAccess
                                    className="JournalTitle-Icon"
                                    iconDescr="A symbol of a lock"
                                    iconTitle="Open Access journal"
                                    seleniumid="journal-OpenAccess-icon"
                                />
                            )}
                        </span>
                    </div>
                    <div className="ArticleTitle">
                        <SubTitle
                            dangerouslySetInnerHTML={{
                                __html: articleName,
                            }}
                            data-seleniumid="article-title"
                        />
                    </div>
                    {(isOnlineOpen || isOpenAccess) && (
                        <div className="ArticleOA" data-seleniumid="open-access">
                            <div className="ArticleOA-Container">
                                <SvgIcon.openAccess
                                    className="ArticleOA-Icon"
                                    iconDescr="A symbol of a lock"
                                    iconTitle="Open Access article"
                                    seleniumid="open-access-icon"
                                />
                                <span className="ArticleOA-Text" data-seleniumid="open-access-text">
                                    {l('DASHBOARD.ARTICLE_CARD.OPEN_ACCESS')}
                                </span>
                            </div>
                        </div>
                    )}
                    {isPublished && !!funderStatement && (
                        <div className="ArticleFunderStatement" data-seleniumid="funder-statement-block">
                            <div className="Container">
                                <SvgIcon.hand
                                    className="Icon"
                                    iconDescr="A symbol of a lock"
                                    iconTitle="Open Access article"
                                    seleniumid="funder-statement-icon"
                                />
                                <span className="Text" data-seleniumid="funder-statement-text">
                                    {funderStatement}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="ArticleDOI" data-seleniumid="article-doi">
                        <strong>{l('DASHBOARD.ARTICLE_CARD.DOI')}</strong>
                        {isPublished && !isWithdrawn ? (
                            <a
                                data-seleniumid="article-doi-link"
                                href={`https://doi.org/${doi}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                https://doi.org/
                                <span className={cn({ highlight: isFoundByDoi })}>{doi}</span>
                            </a>
                        ) : (
                            <span className={cn({ highlight: isFoundByDoi })} data-seleniumid="article-doi-text">
                                {doi}
                            </span>
                        )}
                    </div>
                    <div className="ArticleStatus" data-seleniumid="article-status">
                        <strong>{l('DASHBOARD.ARTICLE_CARD.STATUS')}</strong>
                        <span data-seleniumid="article-status-text">
                            {l(`DASHBOARD.PUBLICATION_STATUSES.${statusCode}`)}
                        </span>
                    </div>

                    {statusPrompt && (
                        <div className="ArticleStatusPrompt" dangerouslySetInnerHTML={{ __html: statusPrompt }} />
                    )}
                </div>
            </div>
        );
    },
);

type Props = {
    isFoundByDoi?: boolean;
    item: ArticlePayload;
    statusCode: string;
    searchWords?: string[];
};

export default function ArticleDetails({ isFoundByDoi, item, searchWords = [], statusCode }: Props) {
    const { article, journal, rcaInfo, status } = item;

    return (
        <UiArticleDetails
            articleName={highlight(article.name, searchWords)}
            coverImageUrl={journal.coverImageUrl}
            doi={article.doi}
            eeoCaFullName={rcaInfo.eeoCaFullName}
            funderStatement={article.funderStatement}
            isFoundByDoi={isFoundByDoi}
            isOnlineOpen={status.onlineOpen}
            isOpenAccess={journal.openAccess}
            isPublished={status.published}
            isWithdrawn={status.withdrawn}
            journalName={highlight(journal.name, searchWords)}
            statusCode={statusCode}
        />
    );
}
