import get from 'lodash.get';
import { template } from 'app/blocks/common/utils';
import PaymentStepEnum from 'app/pages/license-signing-process/Payment/PaymentStepEnum';
import StepEnum from 'app/pages/license-signing-process/StepEnum';

export const STEPS = [
    {
        id: StepEnum.CONFIRM_AUTHORS,
        name: 'LICENSE_SIGNING.STEP.CONFIRM_AUTHOR_ROLES',
        seleniumid: 'licenseStep-confirm-authors',
        substeps: {
            confirmEmails: 'LICENSE_SIGNING.STEP.CONFIRM_AUTHOR_ROLES',
            confirmRCA: 'LICENSE_SIGNING.STEP.CONFIRM_AUTHORS',
            confirmRCAChange: 'LICENSE_SIGNING.STEP.CONFIRM_AUTHORS',
            continue: 'LICENSE_SIGNING.STEP.CONFIRM_AUTHOR_ROLES',
            stop: 'LICENSE_SIGNING.STEP.CONFIRM_AUTHOR_ROLES',
            'wrong-list': 'LICENSE_SIGNING.STEP.CONFIRM_AUTHOR_ROLES',
        },
        url: `/license-signing/$articleId/${StepEnum.CONFIRM_AUTHORS}`,
    },
    {
        id: StepEnum.FUNDERS,
        name: 'LICENSE_SIGNING.STEP.FUNDERS',
        seleniumid: 'licenseStep--funders',
        url: `/license-signing/$articleId/${StepEnum.FUNDERS}`,
    },
    {
        id: StepEnum.AFFILIATIONS,
        name: 'LICENSE_SIGNING.STEP.AFFILIATIONS',
        seleniumid: 'licenseStep--affiliations',
        url: `/license-signing/$articleId/${StepEnum.AFFILIATIONS}`,
    },
    {
        id: StepEnum.COPYRIGHT,
        name: 'LICENSE_SIGNING.STEP.COPYRIGHT',
        seleniumid: 'licenseStep--copyright',
        url: `/license-signing/$articleId/${StepEnum.COPYRIGHT}`,
    },
    {
        id: StepEnum.OPEN_ACCESS_OPTIONS,
        name: 'LICENSE_SIGNING.STEP.OPEN_ACCESS_OPTIONS',
        seleniumid: 'licenseStep--open-access-options',
        url: `/license-signing/$articleId/${StepEnum.OPEN_ACCESS_OPTIONS}`,
    },
    {
        id: StepEnum.OPEN_ACCESS_COVERAGE,
        name: 'LICENSE_SIGNING.STEP.OPEN_ACCESS_COVERAGE',
        seleniumid: 'licenseStep--open-access-coverage',
        url: `/license-signing/$articleId/${StepEnum.OPEN_ACCESS_COVERAGE}`,
    },
    {
        id: StepEnum.LICENSE_TYPES,
        name: 'LICENSE_SIGNING.STEP.LICENSE_TYPES',
        seleniumid: 'licenseStep--license-types',
        url: `/license-signing/$articleId/${StepEnum.LICENSE_TYPES}`,
    },
    {
        id: StepEnum.DISCOUNT_AND_BILLING,
        name: 'LICENSE_SIGNING.STEP.DISCOUNT_AND_BILLING',
        seleniumid: 'licenseStep--discountAndBillingDetails',
        url: `/license-signing/$articleId/${StepEnum.DISCOUNT_AND_BILLING}`,
    },
    {
        id: StepEnum.PAYMENT,
        name: 'LICENSE_SIGNING.STEP.PAYMENT',
        seleniumid: 'licenseStep--payment',
        url: `/license-signing/$articleId/${StepEnum.PAYMENT}/${PaymentStepEnum.REVIEW_FINAL_CHARGES}`,
    },
    {
        id: StepEnum.LICENSE_SUBMISSION,
        name: 'LICENSE_SIGNING.STEP.LICENSE_SUBMISSION',
        seleniumid: 'licenseStep--license-submission',
        url: `/license-signing/$articleId/${StepEnum.LICENSE_SUBMISSION}`,
    },
    {
        id: StepEnum.CONFIRMATION,
        name: 'LICENSE_SIGNING.STEP.CONFIRMATION',
        seleniumid: 'licenseStep--confirmation',
        url: `/license-signing/$articleId/${StepEnum.CONFIRMATION}`,
    },
];

const WAIVER_TYPES = ['GeographicalDiscount', 'EditorialDiscount', 'ArticleType', 'Custom'];

export const LICENSE_STEPS = [
    { folder: 'INITIATE', id: 'signingLicenseStep', selectionType: 'Signing license' },
    { folder: 'OBTAIN', id: 'obtainOrDownloadLicenseStep', selectionType: 'Obtain/Download license' },
    { folder: 'INSTRUCTIONS', id: 'signingInstructionsStep', selectionType: 'Signing instructions' },
    { folder: 'UPLOAD' },
];

export function isLicenseChoiceSkipped(step) {
    return step?.licenseChoices?.every(c => c?.licenseChoice?.length === 1);
}

export function updateWithSelection(step, licenseType) {
    step?.licenseChoices?.forEach(c => {
        // eslint-disable-next-line no-param-reassign
        c.licenseType = c?.licenseChoice?.length === 1 ? c.licenseChoice[0] : licenseType;
    });
}

function isLicenseTypesStepVisible(step) {
    return get(step, 'show') && !(get(step, 'confirmed') && isLicenseChoiceSkipped(step));
}

export const isWaiver = order => {
    try {
        return order.prices[0].appliedDiscounts.some(
            appliedDiscount =>
                WAIVER_TYPES.includes(appliedDiscount.discountType) && appliedDiscount.percentageDiscount === 100,
        );
    } catch (e) {
        return false;
    }
};

export function getVisibleSteps(steps, all) {
    return steps.filter(x => {
        if (x.id === StepEnum.LICENSE_TYPES) {
            return isLicenseTypesStepVisible(all[x.id]);
        }

        return get(all, `${x.id}.show`, false);
    });
}

function getFilledSubSteps(substeps, l) {
    if (!substeps) {
        return undefined;
    }

    const out = {};

    Object.keys(substeps).forEach(key => {
        out[key] = l(substeps[key]);
    });

    return out;
}

export function getFilledSteps(steps, all, articleId, l) {
    const completed = steps.map(x => x.id).filter(x => get(all[x], 'confirmed', false));
    const disabled = steps
        .map(x => x.id)
        .filter(x => !completed.includes(x))
        .slice(1);

    return steps.map(x => ({
        ...x,
        completed: completed.includes(x.id),
        disabled: disabled.includes(x.id),
        name: l(x.name),
        substeps: getFilledSubSteps(x.substeps, l),
        url: template(x.url, { articleId }),
    }));
}

export function calculateSteps(descriptors, all, articleId, l) {
    let updatedDescriptors = descriptors;
    if (all.discountAndBillingDetails?.order) {
        updatedDescriptors = STEPS.map(step => {
            if (step.id === StepEnum.DISCOUNT_AND_BILLING) {
                return {
                    ...step,
                    name: isWaiver(all.discountAndBillingDetails.order)
                        ? 'LICENSE_SIGNING.STEP.DISCOUNT_AND_WAIVER'
                        : 'LICENSE_SIGNING.STEP.DISCOUNT_AND_BILLING',
                };
            }
            return step;
        });
    }

    const steps = getVisibleSteps(updatedDescriptors, all);

    return getFilledSteps(steps, all, articleId, l);
}

export function getLastLicenseConfirmedStep(stepTracker) {
    let idx = -1;

    LICENSE_STEPS.forEach((s, i) => {
        if (stepTracker[s.id]?.confirmed) idx = i;
    });

    return idx + 1;
}
