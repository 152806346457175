import React from 'react';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';

function DeleteDraftModal({ l, onApprove, onReject }) {
    return (
        <Modal className="Dialog" id="deleteDraftModal" ignoreBackdropClicks onClose={onReject} type="confirmation">
            <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                <DialogMessage html={l('LICENSE_SIGNING.PAYMENT.DELETE_DRAFT_POPUP_TEXT')} type="confirmation" />
                <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                    <PrimaryButton data-seleniumid="confirmation-dialog-confirm-button" isDanger onClick={onApprove}>
                        {l('BUTTONS.DELETE')}
                    </PrimaryButton>
                    <Button data-seleniumid="confirmation-dialog-cancel-button" onClick={onReject}>
                        {l('BUTTONS.CANCEL')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING)(DeleteDraftModal);
export { DeleteDraftModal };
