import { autobind } from 'core-decorators';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import FunderPicker from 'app/blocks/funder-picker/funder-picker';
import FunderPanelContext2 from 'app/blocks/Panel/funder/FunderPanelContext2';

@autobind
class NewItem extends React.PureComponent {
    async onCancelClick() {
        await this.props.cancelUpdate(this.props.program._id);
    }

    async onFunderChange(funder) {
        const {
            cancelUpdate,
            confirmUpdate,
            program: { _id },
            updateResearchFunder,
        } = this.props;

        try {
            await updateResearchFunder(_id, funder);
            await confirmUpdate(_id);
        } catch (e) {
            if (e.code === 'UPDATE_CANCELED') {
                await cancelUpdate(_id);
            } else if (e.code === 'UPDATE_SKIPPED') {
                // do nothing
            } else {
                throw e;
            }
        }
    }

    render() {
        const { fundersPromise, index, l, onResearchFunderInputFocus, program, validation } = this.props;

        return (
            <li className="NewInstitution">
                <div className="NewInstitution-InstitutionPicker">
                    <FunderPicker
                        disabled={false}
                        error={validation.researchFunder ? l(validation.researchFunder) : ''}
                        funder={program.researchFunder}
                        fundersPromise={fundersPromise}
                        onFocus={() => onResearchFunderInputFocus(program._id)}
                        onFunderChange={this.onFunderChange}
                        seleniumid={`funderPicker-${index}`}
                    />
                </div>

                <div className="NewInstitution-Buttons">
                    <LinkButton
                        data-seleniumid={`cancelFunderUpdateButton-${index}`}
                        isBlack
                        onClick={this.onCancelClick}
                    >
                        <SvgIcon.cancel iconTitle="Cancel Icon" />
                        Cancel
                    </LinkButton>
                </div>
            </li>
        );
    }
}

export default compose(
    FunderPanelContext2.withContext([
        'cancelUpdate',
        'confirmUpdate',
        'fundersPromise',
        'onResearchFunderInputFocus',
        'updateResearchFunder',
    ]),
    withCodes2(ID.FUNDER, ID.LICENSE_SIGNING),
)(NewItem);
