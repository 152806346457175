import cn from 'classnames';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

export function withLabel(CheckboxRadio) {
    return React.forwardRef(function CheckboxRadioButton(
        {
            checkboxClassName,
            className,
            id,
            isError,
            label,
            labelClassName,
            labelStyle,
            radioClassName,
            style,
            ...props
        },
        ref,
    ) {
        const seleniumId = props['data-seleniumid'];

        return (
            <label
                className={cn('selection', className, {
                    'selection--checked': props.checked,
                    'selection--error': isError,
                })}
                htmlFor={id}
                style={style}
            >
                <CheckboxRadio
                    ref={ref}
                    {...props}
                    className={cn('selection-ctrl', radioClassName, checkboxClassName)}
                    id={id}
                    isError={isError}
                />
                {typeof label === 'string' ? (
                    <div
                        className={cn('selection-label', labelClassName, { text_bold: props.checked })}
                        dangerouslySetInnerHTML={{ __html: label }}
                        data-seleniumid={`${seleniumId}-label`}
                        style={labelStyle}
                    />
                ) : (
                    <div
                        className={cn('selection-label', labelClassName, { text_bold: props.checked })}
                        data-seleniumid={`${seleniumId}-label`}
                        style={labelStyle}
                    >
                        {label}
                    </div>
                )}
                {props.logo === 'ENABLE' && props.value === 'CREDIT_CARD' && (
                    <div className={cn('selection-logo')}>
                        <SvgIcon.masterCardLogo
                            iconTitle="MasterCard"
                            seleniumid="master-card-payment-logo"
                            style={{ height: '30px', width: '48px' }}
                        />

                        <SvgIcon.visaLogo
                            iconTitle="Visa"
                            seleniumid="visa-payment-logo"
                            style={{ height: '30px', width: '48px' }}
                        />

                        <SvgIcon.americanExpressLogo
                            iconTitle="American Express"
                            seleniumid="amex-payment-logo"
                            style={{ height: '30px', width: '48px' }}
                        />

                        <SvgIcon.discoverLogo
                            iconTitle="Discover"
                            seleniumid="discover-payment-logo"
                            style={{ height: '30px', width: '48px' }}
                        />
                    </div>
                )}
                {props.logo === 'ENABLE' && props.value === 'SOCOPAY' && (
                    <div className={cn('selection-logo')}>
                        <SvgIcon.alipayLogo
                            seleniumid="alipay-payment-logo"
                            style={{ height: '30px', width: '48px' }}
                        />
                    </div>
                )}
            </label>
        );
    });
}

export default { withLabel };
