/* eslint-disable no-param-reassign, no-return-assign */
import { l } from 'app/blocks/common/codes'; // PANEL_STATUS should be preloaded!
import { ArticlePayload } from 'app/blocks/middleware/dashboard';
import {
    ActionButton,
    getActionsButtons,
    getStatusDescr,
    handleClick,
} from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';
import { ContextInterface } from 'app/pages/article/ArticlePage.context';
import { canDoRequiredActions } from 'app/pages/dashboard/utils';
import DashboardActionCodes from 'app/types/dashboard-action-codes';

function helpers(item) {
    const { banner = {}, flow = {}, options = {} } = item;

    function getPanel(section) {
        return flow[section];
    }

    function hasPanel(section) {
        return !!flow[section]?.statusCode;
    }

    function getStatusConfig(section) {
        return l(`PANEL_STATUS.${section}.statuses.${flow[section]?.statusCode}`, {}, '') || {};
    }

    function hasImportant(section) {
        // @ts-ignore
        return !!getStatusConfig(section).important;
    }

    function getRequired(section) {
        return canDoRequiredActions(item) && (flow[section]?.actions || []).find(a => a.required);
    }

    function hasRequired(section) {
        return !!getRequired(section);
    }

    function hasOption(option) {
        return !!options[option];
    }

    function hasAction(section, action, onlyRequired = false) {
        if (!action) {
            return false;
        }

        const found = (flow[section]?.actions || []).find(a => {
            if (typeof action === 'string') {
                return a.code === action;
            }
            if (action.includes) {
                return action.includes(a);
            }
            return false;
        });

        return !!(onlyRequired ? found?.required : found);
    }

    function getParameters(section) {
        return flow[section]?.params || {};
    }

    function getBannerParameters() {
        if (banner?.params?.length) {
            return banner.params.reduce((obj: { [x: string]: any }, param: { key: string; value: string }) => {
                obj[param.key] = param.value;
                return obj;
            }, {});
        }
        return {};
    }

    function getStatus(section) {
        if (section === 'banner') {
            return getStatusDescr(l('PANEL_STATUS'), section, banner?.statusCode, getBannerParameters());
        }
        return getStatusDescr(l('PANEL_STATUS'), section, flow[section]?.statusCode, getParameters(section));
    }

    function getActions(section, additionalInfo): Record<string, ActionButton> {
        const buttons = getActionsButtons(
            l('PANEL_STATUS'),
            section,
            flow[section]?.actions || [],
            getParameters(section),
            additionalInfo,
        );

        function afterAction() {
            // console.log('TODO: Notify Page!!!');
        }

        const actions = {};

        buttons.forEach(button => {
            actions[button.code] = {
                ...button,
                onClick: e => handleClick(e, button, afterAction),
            };
        });

        return actions;
    }

    function getAllActions(section, labels = {}, additionalInfo = null): ActionButton[] {
        return Object.values(getActions(section, additionalInfo)).map(
            (
                v = {
                    code: '',
                    title: '',
                },
            ) => ({
                ...v,
                title: labels[v.code] || v.title,
            }),
        );
    }

    function getAllRequiredActions() {
        const panels = Object.keys(flow);
        const result = {};

        panels.forEach(panel => {
            const action = getRequired(panel);
            if (action) result[panel] = { action: action.code, status: getPanel(panel).statusCode };
        });

        return result;
    }

    function getTitle(section, defaultTitle) {
        let title = defaultTitle || l(`PANEL_STATUS.${section}.title`);

        const statuses = l(`PANEL_STATUS.${section}.statuses`) || {};

        let statusCode = '';
        if (section === 'banner') {
            statusCode = banner?.statusCode;
        } else {
            statusCode = flow[section]?.statusCode;
        }

        if (statuses[statusCode]?.title) {
            title = l(`PANEL_STATUS.${section}.statuses.${statusCode}.title`);
        }

        return title;
    }

    function getIcon(section, defaultIcon = '') {
        // @ts-ignore
        return getStatusConfig(section).icon || defaultIcon;
    }

    function isEditable() {
        const order = flow.OOOrder || flow.OAOrder;
        const updateActions = [
            DashboardActionCodes.EDIT_OO_ORDER,
            DashboardActionCodes.EDIT_OA_ORDER,
            DashboardActionCodes.EDIT_GOA_ORDER,
        ];

        if (order) {
            return (order.actions || []).some(action => updateActions.includes(action.code));
        }
        return false;
    }

    function hasCitations() {
        return !!item.article?.citations?.total;
    }

    function hasMultipleAuthors() {
        return item.article?.authors?.length > 1;
    }

    return {
        getActions,
        getAllActions,
        getAllRequiredActions,
        getIcon,
        getPanel,
        getParameters,
        getStatus,
        getTitle,
        hasAction,
        hasCitations,
        hasImportant,
        hasMultipleAuthors,
        hasOption,
        hasPanel,
        hasRequired,
        isEditable,
    };
}

function handleBanners(item = {}) {
    // @ts-ignore
    item.banner = {};

    // @ts-ignore
    Object.keys(item.flow).forEach(widget => {
        // @ts-ignore
        const w = item.flow[widget];
        const showBanner: any[] = w?.params?.filter(p => p.key === 'SHOULD_SHOW_BANNER');
        if (showBanner?.length && showBanner[0].value === 'TRUE') {
            // @ts-ignore
            item.banner = {
                params: w.params,
                statusCode: w.statusCode,
            };
        }
    });
    return item;
}

function handleErrors(item = {}) {
    // @ts-ignore
    item.errors = [];

    function statusIsError(s) {
        return String(s).toLowerCase().indexOf('error') !== -1;
    }

    ['flow', 'options'].forEach(section => {
        Object.keys(item[section]).forEach(widget => {
            const w = item[section][widget];

            // null in option or panel(generic error)
            if (w === null) {
                // @ts-ignore
                item.errors.push(`WIDGETS.ERRORS.${widget}`);
            }

            // error in flow panel status
            if (statusIsError(w?.statusCode)) {
                // @ts-ignore
                item.errors.push(`PANEL_STATUS.${widget}.statuses.${w?.statusCode}.description`);
                if (!w?.actions?.length) {
                    // hide widget only if there is no actions
                    item[section][widget] = {};
                }
            }
        });
    });

    // eligibility error
    // @ts-ignore
    if (item.eligibility === null) {
        // @ts-ignore
        item.errors.push('WIDGETS.ERRORS.eligibility');
    }

    return item;
}

export default function convertItem(item: ArticlePayload): ContextInterface {
    // @ts-ignore
    item.id = item.article?.id;

    item.flow = item.flow || {};
    item.options = item.options || {};
    // fixme: wtf?
    // @ts-ignore
    item.flow.publication = item.publication;

    // we should handle 'null's before conversion since section with 'null' will be removed
    handleErrors(item);

    // handling any banners before conversion
    handleBanners(item);

    for (const s in item.flow) {
        if (item.flow[s]) {
            const section = item.flow[s];
            item[s] = section; // fixme: wtf? remove it!

            const parsed = (section.params || []).reduce((params, p) => ({ ...params, [p.key]: p.value }), {});
            // @ts-ignore
            parsed.ARTICLE_ID = item.id;

            if (s === 'OOOrder' || s === 'OAOrder') {
                parsed.ORDER_UNIQUE_ID = section.id;
            }

            section.params = { ...section, ...parsed };
        } else {
            delete item.flow[s];
        }
    }

    // @ts-ignore
    item.helpers = helpers(item);

    // @ts-ignore
    return item;
}
