import cn from 'classnames';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';

import './PriceTable.scss';

type Props = {
    hideEmpty?: boolean;
    inRow?: boolean;
    l: l;
    prices: Price[];
};

function PriceTable({ hideEmpty = false, inRow = false, l, prices }: Props) {
    const pricesAvailable = !!prices?.length;

    return (
        <div className="PricesContent">
            {!hideEmpty && !pricesAvailable && (
                <div className="PricesEmpty" data-seleniumid="prices-empty-message">
                    {l('WIDGETS.PRICES.EMPTY_TEXT')}
                </div>
            )}

            {pricesAvailable && (
                <>
                    <div className="PricesTitle" data-seleniumid="prices-title">
                        {l('WIDGETS.PRICES.TITLE')}
                    </div>
                    <div className={cn('Prices', { Row: inRow })} data-seleniumid="prices-content">
                        <table className="PricesTable" data-seleniumid="prices-table">
                            <tr>
                                {prices.map(p => (
                                    <th data-seleniumid={`price-currency-${p.currency}`}>{p.currency}</th>
                                ))}
                            </tr>
                            <tr>
                                {prices.map(p => (
                                    <td data-seleniumid={`price-amount-${p.currency}`}>{p.amount}</td>
                                ))}
                            </tr>
                        </table>
                        <div className="PricesText" data-seleniumid="prices-text">
                            {l('WIDGETS.PRICES.TEXT')}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default withCodes(PriceTable, ID.WIDGETS);
