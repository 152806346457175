import { autobind } from 'core-decorators';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';

@autobind
class DiscountReviewPanel extends React.Component {
    state = { isEditing: false };

    @processing('isEditing')
    async onRequestEditOrder() {
        await this.props.onRequestEditOrder(PaymentPanelEnum.DISCOUNT);
    }

    render() {
        const { DataItemAndAuxInfo, isEditable, l } = this.props;
        const { isEditing } = this.state;

        return (
            <div className="row order-btm-border" data-seleniumid="order-discount-panel-review">
                <div className="col-md-10 col-12 nopadding">
                    <div className="m-btm_md">
                        <h5>{l('ORDER_PANELS.PANEL_DISCOUNT_REVIEW.TITLE')}</h5>
                    </div>
                    <DataItemAndAuxInfo.OrdersDataItem
                        innerInfo={[['discountDetails.societyPromoCode', ' (', ')']]}
                        seleniumid="order-review-discount-societyNameAndPromoCode"
                        titleCls="order-article"
                        titleTxt="Society Member Discount"
                    />
                    <DataItemAndAuxInfo.OrdersDataItem
                        innerInfo={[['discountDetails.institutionName']]}
                        seleniumid="order-review-discount-institutionName"
                        titleCls="order-article"
                        titleTxt="Institution Discount"
                    />
                    <DataItemAndAuxInfo.OrdersDataItem
                        innerInfo={[['discountDetails.wileyPromoCode', ' (', ')']]}
                        seleniumid="order-review-discount-otherDiscounts"
                        titleCls="order-article"
                        titleTxt="Other Discounts"
                    />
                </div>
                <div className="col-md-2 col-12 m-btm_mini text-right nopadding">
                    <div hidden={!isEditable}>
                        <LinkButton
                            className="edit"
                            data-seleniumid="order-review-discount-edit-button"
                            isProcessing={isEditing}
                            onClick={this.onRequestEditOrder}
                            processingLabel={l('BUTTONS.LOADING')}
                        >
                            <SvgIcon.edit iconTitle="Edit" />
                            {l('ORDER_PANELS.PANEL_DISCOUNT_REVIEW.EDIT_BTN')}
                        </LinkButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes2(ID.ORDER_PANELS, ID.BUTTONS)(DiscountReviewPanel);
