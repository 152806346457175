import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Modal from 'app/blocks/Modal/Modal';
import StaticContent from 'app/blocks/static-content/static-content';

export default function ({ funderName, onApprove, onReject }) {
    return (
        <Modal className="Dialog" onClose={onReject} type="confirmation">
            <form>
                <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                    <div className="licenseFunderChange-message">
                        <div className="licenseFunderChange-messageIcon">
                            <SvgIcon.error className="modal__alert-triangle-icon" iconTitle="Alert triangle icon" />
                        </div>
                        <div>
                            <StaticContent
                                key="license__change-funders-with-funder-name"
                                params={{ funderName }}
                                src="license__change-funders-with-funder-name.html"
                            />
                        </div>
                    </div>
                    <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                        <Button data-seleniumid="funder-add-research-funder-button" onClick={onReject}>
                            Cancel
                        </Button>
                        <Button data-seleniumid="funder-i-will-pay-button" onClick={onApprove}>
                            Delete
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
