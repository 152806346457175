import cn from 'classnames';
import React from 'react';
import { LinkButton, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import withResponsive from 'app/blocks/common/responsive-decorator';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose, strToBool } from 'app/blocks/common/utils';
import StaticContent from 'app/blocks/static-content/static-content';
import { ItemTitle } from 'app/blocks/titles/titles';
import LicenseTypeEnum from 'app/pages/license/LicenseTypeEnum';
import RadioButton from 'app/ui/form/RadioButton';
import LicenseSelectionModal from './LicenseSelectionModal';
import { LICENSE_ICONS } from './utils';

import './LicenseSelectionGrid.scss';

function DisallowIcon() {
    return <div className="DisallowIcon" />;
}

type LicenseType = Values<typeof LicenseTypeEnum>;

type Config = {
    columnOrder: { [key in LicenseType]: string };
    items: {
        [key: string]: {
            description: string;
            permissions: { [key in LicenseType]: 'true' | 'false' };
        };
    };
};
type Props = {
    className: string;
    config: Config;
    l: l;
    licenseTypes: Array<{ value: LicenseType }>;
    onLicenseTypeChange: (value: LicenseType) => void;
    selectedLicenseType: LicenseType;
    isDesktop: boolean;
    isLargeDesktop: boolean;
    isConflicted: boolean;
};

class LicenseSelectionGrid extends React.PureComponent<Props> {
    showLicenseSelectionDialog = (
        shortNameValue: string,
        headAndIconHeader: React.JSX.Element,
        onLicenseTypeChange,
        config: Config,
    ) => {
        showDialog.modal(closeAnd => ({
            config,
            headAndIconHeader,
            modalHeading: '',
            onClose: closeAnd(),
            onLicenseTypeChange,
            shortNameValue,
            type: LicenseSelectionModal,
        }));
    };

    renderIconsList(value, shortName) {
        const icons = LICENSE_ICONS[value];

        return (
            icons && (
                <div>
                    {icons.map((Icon, index) => (
                        /* eslint-disable react/no-array-index-key */
                        <Icon
                            key={index}
                            className={cn({ 'ml-one-thirds': index !== 0 })}
                            data-seleniumid={`table-icon-${shortName?.replace(/ /g, '_')}`}
                            iconTitle={value}
                        />
                    ))}
                </div>
            )
        );
    }

    renderHeaderFooterCell({ value }, licenseType, isHeader) {
        const { l, onLicenseTypeChange } = this.props;
        const shortName = l(`LICENSE.${value}.shortName`);

        const Component = props => (isHeader ? <th {...props} /> : <td {...props} />);
        const isLicenseTypeSelected = value === licenseType;
        const headTitle = (
            <div className="licenseHeader" data-seleniumid={`table-head-${shortName}`}>
                {shortName}
            </div>
        );
        const headIcons = this.renderIconsList(value, shortName);

        return (
            <Component key={value} className={cn({ 'LicenseSelectionGrid--selectedCell': isLicenseTypeSelected })}>
                {isHeader ? (
                    <div className="cellFormat cellFormat-center cellFormat-gap">
                        {headTitle}
                        {headIcons}
                        <LinkButton
                            className="learnMore"
                            data-seleniumid={`goToReadMorePopup_${shortName.replace(/ /g, '_')}`}
                            onClick={() =>
                                this.showLicenseSelectionDialog(
                                    value,
                                    <>
                                        {headTitle}
                                        {headIcons}
                                    </>,
                                    () => onLicenseTypeChange(value),
                                    this.props.config,
                                )
                            }
                        >
                            <u>{l('BUTTONS.LEARN_MORE')}</u>
                        </LinkButton>
                    </div>
                ) : (
                    <div className="table-footer">
                        {isLicenseTypeSelected ? (
                            <div className="license-selected-text">
                                Selected
                                <br />
                                {shortName}
                            </div>
                        ) : (
                            <PrimaryButton
                                data-seleniumid={`selectCCLicenseType_${shortName.replace(/ /g, '_')}`}
                                onClick={() => onLicenseTypeChange(value)}
                                style={{ padding: '10px' }}
                            >
                                Select
                                <br />
                                {shortName}
                            </PrimaryButton>
                        )}
                        <div
                            className="noAdditionalCoseText"
                            data-seleniumid={`selectCCLicenseType_${shortName.replace(/ /g, '_')}`}
                        >
                            {l('LICENSE_SIGNING.LICENSE_TYPES.NO_ADDITIONAL_COST_TEXT')}
                        </div>
                    </div>
                )}
            </Component>
        );
    }

    renderBodyRow(item, index, licenseType, licenseTypes) {
        const { isConflicted, l } = this.props;

        const printAsteriskOnDisAllowBtn = value => {
            const asteriskByConflict = isConflicted ? '**' : '*';
            return index === 3 && ['CC-BY-NC-ND', 'CC-BY-NC'].includes(value) ? asteriskByConflict : '';
        };

        const printAsteriskOnRowTitle = () => (isConflicted ? '*' : '');

        return (
            <tr key={item.description}>
                <td
                    className="cellFormat"
                    dangerouslySetInnerHTML={{
                        __html: l(`LICENSE_SELECTION_GRID.items.${index}.description`, {
                            asterisk: printAsteriskOnRowTitle(),
                        }),
                    }}
                />
                {licenseTypes.map(({ value }) => {
                    return (
                        <td
                            key={value}
                            className={cn({ ' LicenseSelectionGrid--selectedCell': value === licenseType })}
                        >
                            {strToBool(item.permissions[value]) ? (
                                <SvgIcon.ok iconTitle="allowed" />
                            ) : (
                                <div style={{ position: 'relative' }}>
                                    <DisallowIcon />
                                    <sup className="sup-asterisk">{printAsteriskOnDisAllowBtn(value)}</sup>
                                </div>
                            )}
                        </td>
                    );
                })}
            </tr>
        );
    }

    render() {
        const { className, config, isDesktop, isLargeDesktop, licenseTypes, selectedLicenseType } = this.props;
        const sortedLicenseTypes = [...licenseTypes].sort(
            (a, b) =>
                Number.parseInt(config.columnOrder[a.value], 10) - Number.parseInt(config.columnOrder[b.value], 10),
        );
        return isDesktop || isLargeDesktop ? (
            <table className={cn(' LicenseSelectionGrid', className)}>
                <colgroup>
                    <col style={{ width: licenseTypes.length > 2 ? '225px' : '295px' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <span className="visuallyHidden">Description / License Type</span>
                        </th>
                        {sortedLicenseTypes.map(item => this.renderHeaderFooterCell(item, selectedLicenseType, true))}
                    </tr>
                </thead>
                <tbody>
                    {Object.values(config.items).map((item, index) =>
                        this.renderBodyRow(item, index, selectedLicenseType, sortedLicenseTypes),
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td />
                        {sortedLicenseTypes.map(item => this.renderHeaderFooterCell(item, selectedLicenseType, false))}
                    </tr>
                </tfoot>
            </table>
        ) : (
            <div className="LicenseType">
                {licenseTypes.map(({ value }) => {
                    const radioLabel = (
                        <ItemTitle
                            className={cn({ text_bold: value === selectedLicenseType })}
                            style={{ alignItems: 'center', display: 'inline-flex' }}
                        >
                            {this.props.l(`LICENSE.${value}.name`)}
                            <img
                                alt={value}
                                src={`/static/media/${value}.png`}
                                style={{ height: '23px', marginLeft: '5px' }}
                            />
                        </ItemTitle>
                    );

                    return (
                        <label
                            key={value}
                            className={cn('LicenseType-Option')}
                            data-seleniumid={`licenseType-${value}`}
                            htmlFor={value}
                        >
                            <RadioButton
                                checked={value === selectedLicenseType}
                                data-seleniumid={`radioButton-${value}`}
                                id={value}
                                label={radioLabel}
                                onChange={() => this.props.onLicenseTypeChange(value)}
                                value={value}
                            />
                            <StaticContent
                                className="LicenseTypes-valuePrompt"
                                seleniumid={`${value}-prompt`}
                                src={`license__${value}.html`}
                            />
                        </label>
                    );
                })}
            </div>
        );
    }
}

export const LicenseSelectionGridWrapped = compose(
    withCodes2(ID.LICENSE, ID.LICENSE_SELECTION_GRID),
    Component => props => <Component {...props} config={props.codes[ID.LICENSE_SELECTION_GRID]} />,
)(LicenseSelectionGrid);

export default compose(withResponsive)(LicenseSelectionGridWrapped);
