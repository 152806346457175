import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { Checkbox } from 'app/ui/form';

function AlertsPanel(props) {
    const {
        alerts: { alerts, notificationEmail },
        l,
        saveAlerts,
        toggleAlert,
    } = props;
    const [isDisable, setIsDisable] = useState(true);
    const ALERTS_NAME = {
        CITATION_UPDATES: 'There is a citation update',
        EARLY_VIEW: 'My article is published in Early View',
        ISSUE_PUB_ONLINE: 'My article is published in an online issue',
    };

    const enableSaveButton = () => {
        setIsDisable(false);
    };
    return (
        <div className="AlertPage" data-seleniumid="alerts-block" id="alerts-panel-body">
            <div className="AlertsBlock-Headers">
                <div className="AlertHeader-SubHeader" data-seleniumid="alerts-content">
                    {l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_ALERTS_LABEL_TEXT')},
                    <p className="AlertHeader-Email" data-seleniumid="alerts-content-email">
                        {' '}
                        {notificationEmail}
                    </p>
                    .{l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_ALERTS_LABEL_AFT_TEXT')}
                    <Link data-seleniumid="myprofile-link" id="myprofile-link" to="/profile">
                        {l('PROFILE_ACCOUNT.uiLabelMessages.ALERTS_PROFILE_LINK_LABEL')}
                    </Link>
                </div>
            </div>
            <div className="AlertsBlock-Headers" data-seleniumid="myprofile-sub-content">
                <div className="AlertHeader-SubContent">
                    {l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_ALERTS_LABEL_SUB_HEADER')}
                </div>
            </div>
            <div>
                {!_.isEmpty(alerts) ? (
                    alerts.map(alert =>
                        alert.onScreen ? (
                            <div key={alert.alertId} className="AlertHeader-AlertRow" data-seleniumid={alert.alertId}>
                                <div className="Alert-Action">
                                    <Checkbox
                                        checked={alert.email}
                                        data-seleniumid={`${alert.alertId}_CHECKBOX`}
                                        id={alert.alertId}
                                        onChange={e => {
                                            toggleAlert(alert.alertId, e.target.checked);
                                            enableSaveButton();
                                        }}
                                        value={alert.alertId}
                                    />
                                </div>
                                <div className="AlertHeader-Text" data-seleniumid={`${alert.alertId}_TEXT`}>
                                    {ALERTS_NAME[alert.alertId]}
                                </div>
                            </div>
                        ) : (
                            ''
                        ),
                    )
                ) : (
                    <div className="AlertsBlock-EmptyRow">
                        <p className="AlertsBlock-EmptyRow-Text">
                            {l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_NOT_AVAILABLE_LABEL')}
                        </p>
                    </div>
                )}
            </div>
            <div className="AlertHeader-Actions">
                <PrimaryButton
                    className="Action"
                    data-seleniumid="alert-save-button"
                    disabled={isDisable}
                    id="saveAlertsInfo"
                    onClick={saveAlerts}
                >
                    {l('PROFILE_ACCOUNT.uiLabelMessages.PROFILE_ACCNT_SAVE_CHANGES_BUTTON_LABEL')}
                </PrimaryButton>
            </div>
        </div>
    );
}

AlertsPanel.propTypes = {
    alerts: PropTypes.shape({
        alerts: PropTypes.arrayOf(
            PropTypes.shape({
                alertId: PropTypes.string,
                alertName: PropTypes.string,
                email: PropTypes.boolean,
                onScreen: PropTypes.boolean,
            }),
        ),
        emails: PropTypes.arrayOf(PropTypes.string),
        notificationEmail: PropTypes.string,
    }).isRequired,
    l: PropTypes.func.isRequired,
    saveAlerts: PropTypes.func.isRequired,
};

export default AlertsPanel;
