import { AxiosProgressEvent } from 'axios';
import { REST } from './utils';

const { GET, PUT } = REST('/licenseVerification');
const { GET: GET2, POST: POST2 } = REST('/licenseUploading');

function validateConfig(config) {
    if (!config.supportedMimeTypes?.length) {
        return Promise.reject(new Error('Sorry, license can not be uploaded: incorrect file types'));
    }
    if (!config.maxTotalFilesSize) {
        return Promise.reject(new Error('Sorry, license can not be verified: incorrect total size'));
    }
    if (!config.maxFilesNumber) {
        return Promise.reject(new Error('Sorry, license can not be verified: incorrect files count'));
    }

    return Promise.resolve(config);
}

export default {
    getUploadReviewMetadata: (
        token: string,
    ): Promise<{
        article: Product.Article;
        availableRejectionReasons: Array<{ id: string; name: string }>;
        copyrightRequired: boolean;
        journal: Product.Journal;
        licenseCopyPdfUrl: string;
    }> => GET('', { token }),

    getValidationData: async (
        articleId: string,
    ): Promise<{
        maxFilesNumber: number;
        maxTotalFilesSize: number;
        maxTotalLicenseFilesSize: number;
        supportedMimeTypes: string[];
    }> => validateConfig(await GET2('/start', { articleId })),

    submitReview: (
        token: string,
        licenseInfo: {
            licenseVerified: boolean;
            selectedRejectionReasons: string[];
            copyright: string;
        },
    ): Promise<void> => PUT('', licenseInfo, { urlParams: { token } }),

    uploadFile: (fileItem: { file: File }, onUploadProgress: (event: AxiosProgressEvent) => void): Promise<string> => {
        const data = new FormData();
        data.append('file', fileItem.file);

        return POST2('/uploadFile', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress,
        });
    },
};
