import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { getFormattedAmount } from 'app/blocks/common/getCurrencySymbol';
import { SubTitle } from 'app/blocks/titles/titles';
import { byDiscountVisibility } from 'app/pages/license-signing-process/DiscountType';
import payment from 'app/pages/orders/orders.payment';
import PaymentAmountInfo from './PaymentAmountInfo';

function PaymentBreakdown({ l, order, panelName = '', paymentMethod }) {
    const { articleTypeDisplayName, prices } = order;
    const price = prices[0] || {};
    const { appliedDiscounts, calculatedTax, currency, factoredAPC, finalAPC, preTaxAPC } = price;
    const discount = appliedDiscounts.filter(byDiscountVisibility)[0] || {};
    const discountAmount = discount?.absoluteDiscount;

    return (
        <section className={cn('payment-panel', panelName)} data-seleniumid="order-price-panel-review">
            <SubTitle>{l('ORDER_PANELS.OO_AMOUNT_PAYABLE.REVIEW_CHARGES_TITLE')}</SubTitle>
            <div className={cn('PaymentAmountItems', 'PaymentAmountInfo')}>
                <PaymentAmountInfo
                    amount={getFormattedAmount(currency, factoredAPC)}
                    isAccented
                    seleniumId="orders__value-value-order-apc-review"
                    subHeader={`${l('ORDER_PANELS.PANEL_PRICE_INFO.ARTICLE_TYPE')}: ${articleTypeDisplayName}`}
                    title={`${l('ORDER_PANELS.PANEL_PRICE_INFO.CHARGE_TITLE')}, ${currency}`}
                />
                {discountAmount && (
                    <PaymentAmountInfo
                        amount={`- ${getFormattedAmount(currency, discountAmount)}`}
                        seleniumId="orders__value-percent-order-discountAmount-basePrice"
                        title={l('ORDER_PANELS.PANEL_PRICE_INFO.DISCOUNT')}
                    />
                )}

                {paymentMethod !== payment.method.SOCOPAY.key && (
                    <PaymentAmountInfo
                        amount={getFormattedAmount(currency, calculatedTax)}
                        seleniumId="orders__value-value-order-tax-review"
                        title={l('ORDER_PANELS.PANEL_PRICE_INFO.TAX')}
                    />
                )}
                <PaymentAmountInfo
                    amount={getFormattedAmount(
                        currency,
                        paymentMethod !== payment.method.SOCOPAY.key ? finalAPC : preTaxAPC,
                    )}
                    isAccented
                    isGray
                    seleniumId="orders__value-value-order-totalAmount"
                    title={`${l('ORDER_PANELS.PANEL_PRICE_INFO.FINAL_COST')}, ${currency}`}
                />
            </div>
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS)(PaymentBreakdown);
