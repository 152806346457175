import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { processSearchResultsPage as sendAnalytics } from 'app/blocks/analytics/analytics';
import { Layout, Title } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import { onEnterKeyPress } from 'app/blocks/common/key-utils';
import * as middlewareSearch from 'app/blocks/middleware/search';
import PageSeeMore from 'app/blocks/page/__see-more/page__see-more';
import NoArticleResults from 'app/pages/search/no-article-results/NoArticleResults';
import ResultItems from 'app/pages/search/search_result';
import searchUtils from 'app/pages/search/search_utils';
import { navigate } from 'app/route/history';
import SearchFilter from './search-filter';
import './content_search.scss';

export class ContentSearch extends Component {
    static defaultProps = {
        text: '',
        l: () => '',
        codes: { [ID.SEARCH_CODES]: {} },
    };

    static propTypes = {
        codes: PropTypes.shape({}),
        l: PropTypes.func,
        text: PropTypes.string,
    };

    state = searchUtils.getInitialState();

    componentDidMount() {
        searchUtils.onFirstLoad.bind(this)();
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isLoading) {
            const {
                data: { total: totalResult },
                filter,
            } = this.state;

            const { text: queryText } = this.props;

            sendAnalytics({
                filters: filter?.size ? Array.from(filter).join(': ') : undefined,
                queryText,
                totalResult,
            });
        }
        if (this.props.text !== prevProps.text) {
            this.setState({ isLoading: true });
            searchUtils.onFirstLoad.bind(this)();
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    search = page => middlewareSearch.search(this.props.text, page, [...this.state.filter]);

    seeMore = () => searchUtils.seeMore.bind(this)();

    onAdvancedSearch = () => navigate('advancedsearch');

    getFilterName = () => 'type';

    filter = type => searchUtils.filter.bind(this)(type);

    getContext = () => ({
        data: this.state.data,
        query: this.props.text,
        pageIndex: this.state.pageIndex,
        filter: this.state.filter,
        facet: this.state.facet,
    });

    render() {
        const { codes, l, text } = this.props;

        const { data, facet, isLoading, isLoadingMore } = this.state;

        const hasResult = data && data.total > 0;
        const totalMessage = l('SEARCH_CODES.text.about_total_found', {
            TOTAL_FOUND: data.total,
            QUERY: text,
        });

        return (
            <div className="container-lg">
                {hasResult && (
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3">
                            <Title>{l('SEARCH_CODES.RESULT_TITLE')}</Title>

                            <div className="clearfix">
                                <div className="pull-left" data-seleniumid="search-results-total-amount">
                                    {!isLoading && totalMessage}
                                </div>
                                <div className="pull-right">
                                    <a
                                        data-seleniumid="search-results-advanced-search-link"
                                        onClick={this.onAdvancedSearch}
                                        onKeyPress={onEnterKeyPress(this.onAdvancedSearch)}
                                        role="button"
                                        tabIndex="0"
                                    >
                                        <span className="bold_text">{l('SEARCH_CODES.ADVANCED_SEARCH_BTN')}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row mt-2x">
                    {hasResult && (
                        <SearchFilter
                            facet={facet}
                            filterConfig={codes[ID.SEARCH_CODES].filters[this.getFilterName()]}
                            onFilter={this.filter}
                            selectedFilters={this.state.filter}
                        />
                    )}
                    <div className="col-12">
                        <Layout isLoading={isLoading}>
                            <ResultItems getContext={this.getContext} items={data.items} />
                            {data.hasMore && (
                                <Layout isLoading={isLoadingMore}>
                                    <PageSeeMore
                                        data-seleniumid="search-results"
                                        onClick={this.seeMore}
                                        text="See more"
                                    />
                                </Layout>
                            )}
                            {!hasResult && (
                                <div className="page p-btm_big">
                                    <NoArticleResults />
                                </div>
                            )}
                        </Layout>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes(ContentSearch, ID.SEARCH_CODES);
