import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import Article from 'app/blocks/article/article';
import OrdersDataItem from 'app/pages/orders/data-item';
import OrdersOOAmountPayable from 'app/pages/orders/oo/amount-payable';
import OrdersPanelContactReview from 'app/pages/orders/panel/contact/review';
import OrdersPanelDiscountReview from 'app/pages/orders/panel/discount/review';
import OrdersPanelFunderReview from 'app/pages/orders/panel/funder/review';
import OrdersPanelPaymentReview from 'app/pages/orders/panel/payment/review';
import OrdersPanelVatTaxReview from 'app/pages/orders/panel/vat-tax/review';

function OrdersSingleOO(props) {
    const {
        className,
        dontAllowEdit,
        initOrder,
        isAuthorPaidFlow,
        isFunderPaidFlow,
        isPaidAlipayOrder,
        isPaidCCReceiptPending,
        isPaidOOAlipayOrder,
        isZeroPrice,
        onRequestEditOrder,
        order,
        seleniumid,
    } = props;

    const isDisplayDiscount = 'discountDetails' in order;
    const isDisplayPaymentDetails = 'paymentDetails' in order;

    const DataItemAndAuxInfo = React.useMemo(() => OrdersDataItem.create(order, initOrder), [order, initOrder]);

    DataItemAndAuxInfo.auxInfo = _.extend(DataItemAndAuxInfo.auxInfo, {
        taxPercents:
            order.pricingDetails &&
            ((order.pricingDetails.taxAmount / order.pricingDetails.basePrice) * 100).toFixed(2),
    });

    const isEditable = !dontAllowEdit;
    return (
        <div className={className}>
            <div className="row order-btm-border">
                <div className="m-btm_md">
                    <Article article={order.article} journal={order.journal} seleniumid={seleniumid} />
                </div>
            </div>

            {!(isFunderPaidFlow || isZeroPrice) && (
                <OrdersPanelContactReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={isEditable}
                    onRequestEditOrder={onRequestEditOrder}
                    showBillingAddress={isAuthorPaidFlow}
                    showContactAddress={false}
                />
            )}

            {(isFunderPaidFlow || isZeroPrice) && (
                <OrdersPanelFunderReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    onRequestEditOrder={onRequestEditOrder}
                    order={order}
                />
            )}

            {isDisplayDiscount && (
                <OrdersPanelDiscountReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={isEditable}
                    onRequestEditOrder={onRequestEditOrder}
                />
            )}

            {isDisplayPaymentDetails && !isZeroPrice && (
                <OrdersPanelPaymentReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={isEditable}
                    isPaidAlipayOrder={isPaidAlipayOrder}
                    isPaidCCReceiptPending={isPaidCCReceiptPending}
                    isPaidOOAlipayOrder={isPaidOOAlipayOrder}
                    onRequestEditOrder={onRequestEditOrder}
                />
            )}

            {isAuthorPaidFlow && !isZeroPrice && (
                <OrdersPanelVatTaxReview
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={isEditable}
                    onRequestEditOrder={onRequestEditOrder}
                />
            )}

            {isAuthorPaidFlow && !isZeroPrice && (
                <OrdersOOAmountPayable
                    currencyName={order.pricingDetails.currency}
                    DataItemAndAuxInfo={DataItemAndAuxInfo}
                    isEditable={false}
                    isPaidOOAlipayOrder={isPaidOOAlipayOrder}
                    isShowTax
                    onRequestEditOrder={onRequestEditOrder}
                />
            )}
        </div>
    );
}

OrdersSingleOO.defaultProps = {
    className: '',
    dontAllowEdit: false,
    initOrder: {},
    isAuthorPaidFlow: false,
    isFunderPaidFlow: false,
    isPaidCCReceiptPending: false,
    isPaidOOAlipayOrder: false,
};

OrdersSingleOO.propTypes = {
    className: PropTypes.string,
    dontAllowEdit: PropTypes.bool,
    initOrder: PropTypes.shape({}),
    isAuthorPaidFlow: PropTypes.bool,
    isFunderPaidFlow: PropTypes.bool,
    isPaidCCReceiptPending: PropTypes.bool,
    isPaidOOAlipayOrder: PropTypes.bool,
    onRequestEditOrder: PropTypes.func.isRequired,
    order: PropTypes.shape({}).isRequired,
    seleniumid: PropTypes.string.isRequired,
};

export default OrdersSingleOO;
