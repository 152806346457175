import axios from 'axios';
import render from 'app/app';
import { ajaxAppConfig, initApplication } from 'app/blocks/ajax/config/ajax-config';
import { injectDTM } from 'app/blocks/analytics/analytics';
import { l } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { isEnabled } from 'app/blocks/common/storage';
import { showCriticalError } from 'app/blocks/error-boundary';
import { logout } from 'app/blocks/middleware/authentication';
import { isMock } from 'app/blocks/middleware/config';
import { toggleCodes, toggleFiles } from 'app/blocks/middleware/static';
import injectCRM from 'app/blocks/SupportWidget/external';

let isShowingExpiredDialog = false;
let isShowingOutdatedDialog = false;
let isShowingLoginDialog = false;
let isShowMaintenanceDialog = false;

ajaxAppConfig();

function thenReload(action) {
    return () => {
        action();
        window.location.reload();
    };
}
function showExpiredDialog() {
    if (!isShowingExpiredDialog) {
        showDialog.modal(closeAnd => ({
            closeBtnLabel: 'Login',
            message: l('ERROR.SESSION_HAS_BEEN_EXPIRED'),
            onClose() {
                logout();
                isShowingExpiredDialog = false;
            },
            onIgnoreButtons: closeAnd(() => {
                logout();
                isShowingExpiredDialog = false;
            }),
            pageid: 'Session Expired',
            type: 'error',
        }));

        logout(true);

        isShowingExpiredDialog = true;
    }
}

function showOutdatedDialog() {
    if (!isShowingOutdatedDialog) {
        showDialog.modal(closeAnd => ({
            closeBtnLabel: 'Reload',
            message: l('ERROR.OUTDATED_CLIENT_SOFTWARE'),
            onClose() {
                window.location.reload();
            },
            onIgnoreButtons: closeAnd(() => {
                isShowingOutdatedDialog = false;
            }),
            type: 'error',
        }));

        isShowingOutdatedDialog = true;
    }
}

function showLoginDialog() {
    if (!isShowingLoginDialog) {
        showDialog.error({
            closeBtnLabel: 'Login',
            message: l('ERROR.AUTHSVC_SESSION_EXPIRED'),
            onClose() {
                logout(true);
                isShowingLoginDialog = false;
            },
        });
        isShowingLoginDialog = true;
    }
}

function showMaintenanceDialog() {
    if (!isShowMaintenanceDialog) {
        showDialog.modal(closeAnd => ({
            cancelBtnLabel: 'Reload page',
            confirmBtnLabel: 'Go to Home page',
            message:
                'We are sorry. You caught us at a bad time. We’re making some improvements to our site, but will be back up later today. Apologies for any inconvenience.',
            onApprove: closeAnd(() => {
                window.location.href = '/';
            }),
            onIgnoreButtons: closeAnd(() => {
                isShowMaintenanceDialog = false;
            }),
            onReject: closeAnd(() => window.location.reload()),
            type: 'confirmation',
        }));

        isShowMaintenanceDialog = true;
    }
}

async function launchApp() {
    axios.interceptors.response.use(null, error => {
        if (error.status === 502 || error.status === 503) showMaintenanceDialog();

        return Promise.reject(error);
    });

    const error = await initApplication();
    if (error) {
        if (!isShowMaintenanceDialog) {
            showCriticalError(error, true);
        }
        return;
    }

    // @ts-ignore
    window.dotcmsToggleFiles = thenReload(toggleFiles);
    // @ts-ignore
    window.dotcmsToggleCodes = thenReload(toggleCodes);

    if (!isMock()) {
        if (!window._satellite) {
            injectDTM();
        }

        injectCRM();
    }

    if (!isEnabled()) {
        showDialog.modal(closeAnd => ({
            closeBtnLabel: 'Reload',
            message: l('ERROR.LOCALSTORAGE_NOT_AVAILABLE'),
            onClose: () => window.location.reload(),
            onIgnoreButtons: closeAnd(),
            type: 'error',
        }));
    }

    render();

    axios.interceptors.response.use(response => {
        if (response.data.status === 'FAILURE') {
            const errorCode = response.data.error?.code;
            const messageCode = response.data.error?.message;
            if (errorCode === 'UNAUTHORIZED' || errorCode === 'AUTH_COMMUNICATION_ERROR') {
                showExpiredDialog();
            } else if (errorCode === 'INCORRECT_CLIENT_VERSION') {
                showOutdatedDialog();
            } else if (messageCode.includes('AUTHSVC_SESSION_EXPIRED')) {
                showLoginDialog();
            }
        }

        return response;
    });
}

function prepareApp() {
    const { hash, search } = window.location;
    if (search && hash) {
        window.location.replace(
            `${window.location.pathname}${hash}${hash.includes('?') ? search.replace(/^\?/, '&') : search}`,
        );
    } else {
        launchApp();
    }
}

prepareApp();
