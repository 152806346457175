import React from 'react';
import { Layout, WhiteBlock } from 'app/blocks/blocks';
import ASInfo from 'app/blocks/common/as-info';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import * as middleware from 'app/blocks/middleware/middleware';
import { MainTitle } from 'app/blocks/titles/titles';
import './version.scss';

function ServiceComponentRenderer({ component, name }) {
    return (
        <tbody>
            {component.status === 'ONLINE' ? (
                <tr>
                    <td>{name}</td>
                    <td>{component.version.buildVersion}</td>
                    <td>{component.version.buildTime}</td>
                </tr>
            ) : (
                <tr className="unavailable">
                    <td>{name}</td>
                    <td>Service is not available</td>
                    <td>Service is not available</td>
                </tr>
            )}
        </tbody>
    );
}

type State = {
    isLoading: boolean;
    components: Awaited<ReturnType<typeof middleware.version>>;
};

class Version extends React.PureComponent<undefined, State> {
    state: State = {
        components: undefined,
        isLoading: false,
    };

    @processing('isLoading')
    @tryCatch
    async componentDidMount() {
        this.setState({
            components: await middleware.version(),
        });
    }

    render() {
        const { components, isLoading } = this.state;

        if (isLoading) {
            return <Layout isLoading />;
        }

        return (
            <div className="container-lg">
                <MainTitle>Version</MainTitle>

                <WhiteBlock>
                    <div className="row">
                        <div className="col-lg-8">
                            <table className="Version_table">
                                <thead>
                                    <tr>
                                        <td>Component</td>
                                        <td>Build version</td>
                                        <td>Build time</td>
                                    </tr>
                                </thead>
                                {components &&
                                    Object.entries(components).map(([key, item]) => (
                                        <ServiceComponentRenderer key={key} component={item} name={key} />
                                    ))}
                            </table>
                        </div>
                    </div>
                </WhiteBlock>
                <ASInfo page="Version" />
            </div>
        );
    }
}

export default Version;
