import React, { useCallback } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { l } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import DiscountsContext from 'app/pages/license-signing-process/DiscountAndBillingDetails/GOA/Discounts/DiscountsContext';
import { Input } from 'app/ui/form';

import './DiscountsInput.scss';

type DiscountInputProps = {
    onApply: () => void;
    onChange: (code: string) => void;
    code: string;
};
const DiscountsInput: React.FC<DiscountInputProps> = ({ code, onApply, onChange }) => {
    const handleApply = useCallback(
        event => {
            event.preventDefault();
            onApply();
        },
        [onApply],
    );

    const handleChange = useCallback(
        e => {
            onChange(e.target.value);
        },
        [onChange],
    );

    const inputId = 'discountInputField';

    return (
        <div className="discountsInput">
            <span className="discountsInput-title">
                {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.DISCOUNT_CODE_INPUT_TITLE')}
            </span>
            <form onSubmit={handleApply}>
                <div className="discountsInput-form">
                    <label className="visuallyHidden" htmlFor={inputId}>
                        Discount code
                    </label>
                    <Input id={inputId} maxLength={50} onChange={handleChange} required value={code} />
                    <PrimaryButton type="submit">
                        {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.APPLY_DISCOUNT_BUTTON_TITLE')}
                    </PrimaryButton>
                </div>
            </form>
            <span className="discountsInput-subtitle">
                {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.DISCOUNT_CODE_INPUT_SUBTITLE')}
            </span>
        </div>
    );
};

export default compose(
    DiscountsContext.withProvider,
    DiscountsContext.withContext(state => state),
)(DiscountsInput);
