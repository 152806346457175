import { autobind } from 'core-decorators';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';
import ArticleAffiliationsEditor from 'app/blocks/article-affiliations-editor';
import AffiliationsStepEnum from 'app/blocks/article-affiliations-editor/AffiliationsStepEnum';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import Context from 'app/pages/license-signing-process/context/Context';
import './Affiliations.scss';

@autobind
class Affiliations extends React.PureComponent {
    static propTypes = {
        article: PropTypes.shape({ id: PropTypes.string }).isRequired,
        articleAffiliations: PropTypes.shape({}).isRequired,
        cancelableAuthorPaidOrderExists: PropTypes.bool.isRequired,
        confirmAffiliations: PropTypes.func.isRequired,
        countryName: PropTypes.string,
        getActiveStep: PropTypes.func.isRequired,
        getPrevStep: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        isAffiliationsConfirming: PropTypes.bool.isRequired,
        journal: PropTypes.shape({}).isRequired,
        reload: PropTypes.func.isRequired,
    };

    static defaultProps = {
        countryName: undefined,
    };

    backAndForthRef = React.createRef();

    async afterCancelHandler() {
        await this.props.goBack();
    }

    async afterSaveHandler(affiliations, country) {
        await this.props.confirmAffiliations(affiliations, country);
    }

    async updateContext() {
        await this.props.reload();
    }

    getRenderBackAndForth =
        l =>
        ({ canCancel, canConfirm, isSaving, onCancel, onConfirm, subStep }) => {
            const { getActiveStep, getPrevStep, isAffiliationsConfirming } = this.props;

            return ReactDom.createPortal(
                <BackAndForthButtons
                    buttonCancelLabel={
                        subStep === AffiliationsStepEnum.AFFILIATION_LIST ? getPrevStep().name : getActiveStep().name
                    }
                    buttonConfirmingLabel={l('BUTTONS.CONFIRMING')}
                    buttonConfirmLabel={l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                    canCancel={canCancel && !isAffiliationsConfirming}
                    canConfirm={canConfirm && !isAffiliationsConfirming}
                    isConfirming={isSaving || isAffiliationsConfirming}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />,
                this.backAndForthRef.current,
            );
        };

    render() {
        const {
            article,
            articleAffiliations,
            cancelableAuthorPaidOrderExists,
            countryDropdownVisible,
            countryName,
            journal,
            l,
            onConfirmSubstep,
            onLoadSubstep,
            zeroPriceOrderExists,
        } = this.props;

        return (
            <>
                <section className="affiliations">
                    <ArticleAffiliationsEditor
                        afterCancelHandler={this.afterCancelHandler}
                        afterSaveHandler={this.afterSaveHandler}
                        article={article}
                        articleAffiliations={articleAffiliations}
                        articleId={article.id}
                        cancelableAuthorPaidOrderExists={cancelableAuthorPaidOrderExists}
                        countryDropdownVisible={countryDropdownVisible}
                        countryName={countryName}
                        journal={journal}
                        onConfirmSubstep={onConfirmSubstep}
                        onLoadSubstep={onLoadSubstep}
                        renderButtons={this.getRenderBackAndForth(l)}
                        updateContext={this.updateContext}
                        zeroPriceOrderExists={zeroPriceOrderExists}
                    />
                </section>
                <div ref={this.backAndForthRef} className="licenseSigningProcessPage-backAndForth" />
            </>
        );
    }
}

export { Affiliations };

export default compose(
    withScrollToTop,
    Context.withContext(state => ({
        article: state.article,
        articleAffiliations: state.all?.articleAffiliations,
        confirmAffiliations: state.confirmAffiliations,
        countryDropdownVisible: get(state, 'all.articleAffiliations.countryConfirmationRequired', false),
        countryName: get(state, 'all.articleAffiliations.countryName', undefined),
        getActiveStep: state.getActiveStep,
        getPrevStep: state.getPrevStep,
        goBack: state.goBack,
        isAffiliationsConfirming: !!state.isAffiliationsConfirming,
        journal: state.journal,
        onConfirmSubstep: state.onConfirmSubstep,
        onLoadSubstep: state.onLoadSubstep,
        reload: state.reload,
        zeroPriceOrderExists: get(state, 'all.articleAffiliations.zeroPriceOrderExists', false),
    })),
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(Affiliations);
