import cn from 'classnames';
import React, { useCallback } from 'react';
import { ComboboxWithChevron } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import * as middlewareRegister from 'app/blocks/middleware/register';
import './areas-of-interest.scss';

const idsMapping = (item: Registration.Area): Registration.Area & { isGroup: boolean; notSelectable: boolean } => ({
    ...item,

    isGroup: !item.id,
    notSelectable: !item.id,
});

let areasOfInterestList;

const getList = async (phrase: string, count: number, offset: number) => {
    areasOfInterestList = areasOfInterestList || (await middlewareRegister.getListOfInterests()).map(idsMapping);
    let list = areasOfInterestList;

    if (phrase) {
        list = areasOfInterestList
            .filter(({ isGroup, name = '' }) => isGroup || name.toLowerCase().indexOf(phrase.toLowerCase()) >= 0)
            .filter((el, i, all) => !el.isGroup || (all[i + 1] && !all[i + 1].isGroup));
    }

    const result = [];
    let groups = 0;
    let i = offset;

    while (result.length - groups < count && i < list.length) {
        result.push(list[i]);

        if (list[i].isGroup) {
            groups += 1;
        }

        i += 1;
    }

    return result;
};

type Props = {
    className?: string;
    l: l;
    onChange: (id: string) => void;
    onFocus?: () => void;
};

export function AreasOfInterest({ className, l, onChange, onFocus }: Props): React.ReactElement {
    const [selected, setSelected] = React.useState<Registration.Area>();

    const changeHandler = useCallback(
        item => {
            setSelected(item);
            onChange(item.id);
        },
        [onChange],
    );

    return (
        <>
            <label className="RegisterRow-Label">
                {l('REGISTRATION.uiLabelMessages.REGISTRTION_AREAS_OF_INTEREST_TITLE')}
                <span className="Required">*</span>
            </label>
            <div className="RegisterRow-Text">
                {l('REGISTRATION.uiLabelMessages.REGISTRTION_AREAS_OF_INTEREST_DESCR')}
            </div>
            <div className="RegisterRow-Input">
                <ComboboxWithChevron
                    changeHandler={changeHandler}
                    className={cn('areasOfInterest-combobox', className)}
                    endlessScroll
                    getListDataPromise={getList}
                    hideNoneValue
                    notRestorePrevious
                    onFocus={onFocus}
                    placeholder={l('REGISTRATION.uiLabelMessages.REGISTRTION_AREAS_OF_INTEREST_PLACEHOLDER')}
                    selectedItem={selected}
                    seleniumid="areas-of-interest"
                />
            </div>
        </>
    );
}

export default withCodes(AreasOfInterest, ID.REGISTRATION);
